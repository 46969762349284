import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, map, skip, Subscription } from 'rxjs';
import { LooksService } from 'src/app/service/looks.service';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';

@Component({
	selector: 'app-looks-loading-status',
	standalone: true,
	imports: [CommonModule, IconsComponent],
	templateUrl: './looks-loading-status.component.html',
	styleUrl: './looks-loading-status.component.scss',
})
export class LooksLoadingStatusComponent implements OnInit, OnDestroy {
	private readonly looksService: LooksService = inject(LooksService);
	@Input() data!: any;
	public source: Subscription;
	public percent = 0;
	public photo: string;
	combinedItems$: Subscription;
	combinedItems: any;
	progressBarStatus;
	progressBarStatus$ = this.looksService.progressBarStatus$;
	progressPhotoItems$ = this.looksService.progressPhotoItems$;
	looksLoadingStatus$ = this.looksService.looksLoadingStatus$;

	ngOnInit() {
		this.combinedItems$ = combineLatest([this.progressBarStatus$, this.progressPhotoItems$])
			.pipe(
				skip(1),
				map(([progressBarStatus, progressPhotoItems]) => {
					const itemsToDisplay: Array<any> = [];
					const updatedPhotoItems = [...progressPhotoItems];
					progressBarStatus = progressBarStatus.filter((item) => item.percent < 100);
					progressBarStatus.forEach((progressBarItem) => {
						itemsToDisplay.push(progressBarItem);
					});

					updatedPhotoItems.forEach((photoItem, index) => {
						const exists = progressBarStatus.some((progressBarItem) => progressBarItem.id === photoItem.file.id);

						if (!exists) {
							itemsToDisplay.push(photoItem);
							updatedPhotoItems.splice(index, 1);
							this.looksService.progressPhotoItems.next(updatedPhotoItems);
						}
					});

					return itemsToDisplay.slice(0, 3);
				})
			)
			.subscribe((data) => {
				if (!data.length) {
					this.looksService.looksLoadingStatus.next(false);
					this.close();
				}
				this.combinedItems = data;
			});
	}
	ngOnDestroy(): void {
		if (this.combinedItems$) this.combinedItems$.unsubscribe();
	}
	close!: (result?: any) => void;
	cancel() {
		this.close(false);
	}

	trackById(index: number, item: any): string {
		return item.id;
	}
}
