import { LowerCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-nickname',
  standalone: true,
  imports: [
    LowerCasePipe
  ],
  templateUrl: './profile-nickname.component.html',
  styleUrl: './profile-nickname.component.scss'
})
export class ProfileNicknameComponent {
  @Input({ required: true }) nickname: string;
}
