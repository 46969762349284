import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EUserNameSize } from 'src/app/model/messages/profile.enum';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';

@Component({
  selector: 'app-profile-display-name',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    IconsComponent,
  ],
  templateUrl: './profile-display-name.component.html',
  styleUrl: './profile-display-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDisplayNameComponent {
  readonly userNameSize = EUserNameSize;
  @Input({required: true}) displayName: string;
  @Input() emailConfirmed: boolean = false;
  @Input() size: EUserNameSize = EUserNameSize.default;
  @Input() icon: string = 'verify';
  @Input() fillColor: string = '#6A49FA';
  @Input() textColor: string = '#000000';
}
