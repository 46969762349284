import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';

import { IconsComponent } from '../../../uikit/icons/icons.component';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewUserPost, PostAccess, PostStateType } from 'src/app/model/post';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { ContentSelectorComponent } from '../../../modules/content/components/content-selector/content-selector.component';
import { AutoGrowDirective } from 'src/app/directives/auto-grow.directive';
import { ToastrService } from 'ngx-toastr';
import { DefaultMessageResponse } from '../../../api/ws.service';
import { IHubFileResponse, IHubListFileCreatorResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { IHubPhotoUploadResponse } from 'desiren-core-lib/lib/types/hub/upload/photo.upload.response.hub.interface';
import { IHubVideoUploadResponse } from 'desiren-core-lib/lib/types/hub/upload/video.upload.response.hub.interface';
import { IMessagesPhotoDefaultMessageResponse } from 'desiren-core-lib/lib/types/messages/photo.default-message.response.messages.interface';
import { EMessagesMessageTypes } from 'desiren-core-lib/lib/enums/messages/message-types.messages.enum';
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import moment, { Moment } from 'moment/esm';
import { IDelayMessageResponse } from 'desiren-core-lib/lib/types/messages/delay-message/_.delay-message.interface';
import { VoiceRecorderComponent } from '../../voice-recorder/voice-recorder.component';
import { VoiceRecorderService } from '../../voice-recorder/voice-recorder.service';
import { PostsService } from 'src/app/service/posts.service';
import { AudioMessageComponent } from '../../audio-message/audio-message.component';
import { TagComponent } from '../../tag/tag.component';
import { NoSpacesDirective } from 'src/app/directives/no-spaces.directive';
import { DefaultIntl } from '../creators-date.owl';
import { TranslationsService } from '../../../service/translations/translations.service';
import { EKeysTransloco } from '../../../service/translations/translations.enum';
import { LooksService } from 'src/app/service/looks.service';
import { SmilesComponent } from '../../smiles/smiles.component';

enum PriceHint {
	newPost = EKeysTransloco.CREATORS_CREATORS_POST_SPAN_NEW_POST_MINIMUM_3_USD_OR_FREE,
	editPost = EKeysTransloco.CREATORS_CREATORS_POST_SPAN_NEW_POST_MINIMUM_3_USD_OR_FREE,
}
@Component({
	selector: 'app-creators-post',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IconsComponent,
		NgxMaskDirective,
		ContentSelectorComponent,
		AutoGrowDirective,
		NgOptimizedImage,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		VoiceRecorderComponent,
		AudioMessageComponent,
		TagComponent,
		NoSpacesDirective,
		SmilesComponent,
	],
	providers: [
		provideNgxMask(),
		{
			provide: OwlDateTimeIntl,
			useClass: DefaultIntl,
		},
	],
	templateUrl: './creators-post.component.html',
	styleUrl: './creators-post.component.scss',
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatorsPostComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	private ngUnsubscribe = new Subject<void>();
	@Input('resetForm') set resetFormSetter(value: Subject<boolean> | null) {
		value?.pipe(takeUntil(this.ngUnsubscribe)).subscribe((reset) => {
			if (reset) {
				this.resetFormFields();
			}
		});
	}
	@Input('externalSubmit') set externalSubmit(value: Subject<boolean> | null) {
		value?.pipe(takeUntil(this.ngUnsubscribe)).subscribe((submit) => {
			if (submit) {
				this._submitForm();
			}
		});
	}
	@Input('message') message?: DefaultMessageResponse | null | undefined;
	@Input() hideControls: boolean = false;
	@Input() hasPrice: boolean = true;
	@Input() hasPriceFree: boolean = true;
	@Input() post?: any;
	@Input() looks?: any;
	@Input() placeholderText?: string;
	@Input() isUpdate?: boolean = false;
	@Input() forComment: boolean = false;
	@Input() forTemplate: boolean = false;
	@Input() forLooks: boolean = false;
	@Input() forMessage: boolean = false;
	@Input() hasDelayDate: boolean = false;
	@Input() delayDateRequired: boolean = false;
	@Input() hasVoice: boolean = true;
	@Input() hasVideo: boolean = true;
	@Output() postData: EventEmitter<NewUserPost> = new EventEmitter<NewUserPost>();
	@Output() onTyping: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('textAreaElement', { static: true }) textAreaElement!: ElementRef<HTMLTextAreaElement>;
	@ViewChild('selectFile') selectFile: ContentSelectorComponent | null = null;
	@ViewChild('datetimepicker') datetimepicker: OwlDateTimeComponent<any>;
	@ViewChildren('button') buttons!: QueryList<ElementRef<HTMLButtonElement>>;
	@ViewChild('sendBtnRef') sendBtnRef: ElementRef<HTMLButtonElement>;
	readonly postAccess = PostAccess;
	public priceHintMessage: string = PriceHint.newPost;
	public postForm!: FormGroup;
	public activePriceInput = false;
	public activeTagInput = false;
	public dateTimePickerInput = false;
	public selectedFiles: IHubFileResponse[] = [];
	public tagsList: String[] = [];
	public recommendationTagsList: String[] = [];
	private formSubscription?: Subscription;
	private formSend = new Subject<any>();
	private formSendSub$?: Subscription;
	private minPostPrice = 3;
	public maxPostTags = 25;
	public selectedDateTime: Moment | null = null;
	public audioFile: string | null = null;
	public isLoading: Observable<boolean>;
	private tagsSource: Observable<any>;

	@HostListener('keydown.enter', ['$event'])
	handleEnter(event: KeyboardEvent) {
		if (this.forMessage) {
			event.preventDefault();
			this.submitForm();
		}
	}

	constructor(
		private fb: FormBuilder,
		private toastr: ToastrService,
		private voiceServer: VoiceRecorderService,
		private postService: PostsService,
		private looksService: LooksService,
		private dateTimeAdapter: DateTimeAdapter<any>,
		@Inject(DOCUMENT) private document: Document,
		private crd: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.initForm();
		if (this.delayDateRequired) this.dateTimePickerInput = true;
		this.isLoading = this.postService.postSavingProcessing$;
		this.dateTimeAdapter.setLocale(this.translationsService.getActiveLang());
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.message) {
			if (!this.postForm) this.initForm();
			this.addPrice();
			this.editMessage();
			this.crd.detectChanges();
		}
		if (this.post) {
			if (!this.postForm) this.initForm();
			this.addPrice();
			this.editPost();
			this.tagsList = this.post.tags || [];
			this.addTagsRef();
		}
		if (this.looks) {
			if (!this.postForm) this.initForm();
			this.editLooks();
			this.tagsList = this.looks.tags || [];
			this.addTagsRef();
			this.crd.detectChanges();
		}
		if (this.post?.delayDate) {
			this.selectedDateTime = moment(this.post.delayDate);
		}
		if (this.post?.attachments) {
			let audioFile = this.post.attachments.filter((item) => item.file.file.type === 'VOICE').map((item) => item.file.file.url) || null;
			this.audioFile = audioFile.length > 0 ? audioFile : null;
		}
	}

	ngAfterViewInit() {
		if (!this.postForm) this.initForm();
		this.textAreaElement?.nativeElement.addEventListener('keydown', (e) => {
			if (e.key === 'Enter' && (e.ctrlKey || e.altKey)) {
				e.preventDefault();
				this.postForm.get('text')?.patchValue(this.postForm.get('text')?.value + '\n');
			} else if (e.key === 'Enter') {
				e.preventDefault();
				this.submitForm();
			}
		});

		this.formSubscription = this.postForm.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((form) => {
			this.onTyping.emit();
			this.postService.getTagsRecommendationList(form.tag);
			this.tagsSource = this.forLooks ? this.looksService.looksRecommendationTags(form.tag) : this.postService.getTagsRecommendationList(form.tag);

			if ((form.price || this.post?.price) && this.selectedFiles.length) {
				this.priceValueCheck();
			}
			if (form.tag) {
				this.tagsSource.pipe(take(1)).subscribe((data) => {
					this.recommendationTagsList = data.values.filter((tag) => !this.tagsList.includes(tag));
					this.crd.detectChanges();
				});
			} else if (form.tag) {
				this.recommendationTagsList = [];
			}
		});

		if (this.forLooks && !this.looks) {
			this.tagsList = [];
			this.addTagsRef();
		}
	}

	ngOnDestroy(): void {
		this.formSendSub$?.unsubscribe();
		this.formSend.complete();
		this.formSubscription?.unsubscribe();
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
	}

	private resetFormFields(): void {
		if (this.document.getElementById('textAreaElement')) {
			this.document.getElementById('textAreaElement').setAttribute('rows', '1');
			this.document.getElementById('textAreaElement').style.height = '40px';
		}
		this.postForm.reset();
		this.selectedFiles = [];
		this.activePriceInput = false;
		this.selectedDateTime = null;
		this.crd.detectChanges();
	}

	public uploadFile(): void {
		this.selectFile?.open();
	}

	public uploadPhoto(event: Event) {
		event.preventDefault();
		this.selectFile?.open();
		this.selectFile?.selectPhoto();
	}

	public uploadVideo() {
		this.selectFile?.open();
		this.selectFile?.selectVideo();
	}

	public addPrice(): void {
		const priceGroup = this.fb.control({ value: '', disabled: !this.selectedFiles.length });
		this.postForm.addControl('price', priceGroup);
		this.activePriceInput = true;
		this.message ? this.price.enable() : null;
	}

	public selectCheckbox(value: PostAccess): void {
		this.postForm.patchValue({ access: value });
	}

	public hidePriceSection(): void {
		this.activePriceInput = false;
		this.postForm.removeControl('price');
	}
	public hideDatePickerSection(): void {
		this.dateTimePickerInput = false;
		this.selectedDateTime = null;
	}

	public removeSelectedFile(index: number): void {
		this.selectedFiles.splice(index, 1);
		if (!this.selectedFiles.length && this.activePriceInput) {
			this.price.disable();
			this.price.reset();
		}
	}

	private tagInputStatus(status: boolean): void {
		status ? this.postForm.get('tag').disable() : this.postForm.get('tag').enable();
	}

	public addTagsRef(): void {
		this.activeTagInput = true;
		const tagGroup = this.fb.control({ value: '', disabled: this.tagsList.length >= this.maxPostTags });
		this.postForm.addControl('tag', tagGroup);
		this.crd.detectChanges();
	}

	public addTag(e: Event): void {
		e.preventDefault();
		if (this.tagsList.includes(this.postForm.get('tag').value)) return;
		const tagName = this.postForm.get('tag')?.value;
		if (tagName && !this.tagsList.find((i) => i === tagName)) {
			this.tagsList.push(tagName);
			this.postForm.get('tag').reset();
			this.recommendationTagsList = [];
		}
		this.tagInputStatus(this.tagsList.length === this.maxPostTags);
	}

	public addSelectedTag(tagName: string): void {
		if (this.tagsList.length >= this.maxPostTags) return;
		this.tagsList.push(tagName);
		this.postForm.get('tag').reset();
		this.recommendationTagsList = [];
		this.tagInputStatus(this.tagsList.length === this.maxPostTags);
	}

	public removeTag(index: number): void {
		this.tagsList.splice(index, 1);
		this.tagInputStatus(this.tagsList.length === this.maxPostTags);
	}

	public hideTagSection(): void {
		this.activeTagInput = false;
	}

	public addDateInputForm() {
		this.dateTimePickerInput = true;
	}

	public submitForm(): void {
		this.formSend.next(true);
		this.dateTimePickerInput = false;
	}

	private async saveVoiceMessage(): Promise<IHubListFileCreatorResponse> {
		try {
			const data = await this.voiceServer.uploadMessage();
			return data;
		} catch (error) {
			console.error('Error uploading message:', error);
			this.toastr.error('Failed to upload voice message');
			throw error;
		}
	}
	private isSelectedFilesHasVoice(): boolean {
		return !this.selectedFiles.some((item) => item.file.type === 'VOICE');
	}

	private async _submitForm(): Promise<void> {
		if (!this.text?.value?.trim().length && !this.selectedFiles.length && !this.audioFile) {
			return;
		}
		if (this.postForm.valid || this.selectedFiles.length) {
			let attachments = this.selectedFiles.map((item, index) => {
				return { hubId: item.id, position: index };
			});

			if (this.audioFile && this.isSelectedFilesHasVoice()) {
				try {
					const voiceMessageResponse = await this.saveVoiceMessage();
					voiceMessageResponse.data.map((item) => {
						attachments.push({ hubId: item.id, position: attachments.length + 1 });
					});
				} catch (error) {
					return;
				}
			}

			let postData: NewUserPost = {
				state: PostStateType.published,
				access: this.access.value,
				commentsEnabled: !this.commentsEnabled.value,
			};

			if (attachments.length) {
				postData.attachments = attachments;
			}

			if (this.text?.value?.trim().length) {
				postData.text = this.text?.value.replaceAll('\n', '<br/>');
			} else {
				postData.text = ' ';
			}

			if (this.activePriceInput && this.price?.value) {
				postData.price = {
					amount: this.price.value,
					currency: 'USD',
				};
				if (!!this.price?.value && this.price?.value < 3) {
					this.toastr.error('Price must be $3 USD or more');
					return;
				}
			}

			if (this.delayDateRequired && this.dateTimePickerInput && this.selectedDateTime == null) {
				this.toastr.error('Publishing date is required');
				return;
			}

			if (this.selectedDateTime != null) {
				if (this.selectedDateTime.isBefore(moment())) {
					this.toastr.error('Publishing date must be in future');
					return;
				}

				postData.delayDate = this.selectedDateTime;
			}
			if (this.tagsList.length) {
				postData.tags = this.tagsList;
				this.tagsList = [];
				this.activeTagInput = false;
			}

			this.postService.postSavingProcessingSubject.next(true);
			this.postData.emit(postData);
		}
	}

	public priceValueCheck(): void {
		if (!this.price?.value) return;
		if ((this.postForm.value.price > 0 || this.post?.price) && this.postForm.value.price < this.minPostPrice) {
			this.postForm.controls['price'].setErrors({ invalid: true });
			this.postForm.controls['price'].markAsTouched();
		}
	}

	public decodeUrl(file: IHubFileResponse): string {
		switch (file.file.type) {
			case EHubFileType.PHOTO:
				let photo = file.file as IHubPhotoUploadResponse;
				return photo.base + photo.w.at(7)!;
			case EHubFileType.VIDEO:
				let video = file.file as IHubVideoUploadResponse;
				// @ts-ignore
				return video.base + video.thumbnail!;
			default:
				return '';
		}
	}

	private initForm(): void {
		if (this.postForm) return;
		this.postForm = this.fb.group({
			text: [''],
			commentsEnabled: [false],
			access: [PostAccess.free],
		});
		this.formSendSub$ = this.formSend.pipe(debounceTime(250)).subscribe((_) => {
			this._submitForm();
		});
	}

	private get text(): FormControl {
		return this.postForm.get('text') as FormControl;
	}

	private get price(): FormControl {
		return this.postForm.get('price') as FormControl;
	}

	private get access(): FormControl {
		return this.postForm.get('access') as FormControl;
	}

	private get commentsEnabled(): FormControl {
		return this.postForm.get('commentsEnabled') as FormControl;
	}

	public onFileSelect(files: IHubFileResponse[]): void {
		let sFiles = this.selectedFiles;
		files.forEach((f) => {
			if (!sFiles.map((file) => file.id).includes(f.id)) {
				sFiles.push(f);
			}
		});
		this.selectedFiles = sFiles;
		if (this.price) {
			this.price.enable();
		}
		setTimeout(() => {
			this.buttons.forEach((button) => button.nativeElement.blur());
			this.sendBtnRef.nativeElement.focus();
		}, 0);
	}

	public addVoiceMessage(audioUrl: string): void {
		this.audioFile = audioUrl;
		this.crd.detectChanges();
	}

	public removeVoiceMessage(): void {
		this.audioFile = null;
		this.selectedFiles = this.selectedFiles.filter((item) => item.file.type !== 'VOICE');
		this.crd.detectChanges();
	}

	public onEmojiSelected(emoji: string): void {
		const currentValue = this.postForm.get('text')?.value || '';
		this.postForm.get('text')?.setValue(currentValue + emoji);
	}

	private editPost() {
		this.onFileSelect(this.post.attachments.map((item) => item.file));
		this.postForm.patchValue({
			text: this.post.text,
			price: !!this.post.price ? this.post.price.rawAmount : '',
			access: this.post.access,
			commentsEnabled: !this.post.commentsEnabled,
		});
		if (this.post.price) this.priceHintMessage = PriceHint.editPost;
	}

	private editLooks() {
		this.postForm.patchValue({
			text: this.looks.description,
			price: '',
			access: [PostAccess.free],
			commentsEnabled: this.isUpdate ? !this.looks.commentsEnabled : false,
		});
		if (this.looks.price) this.priceHintMessage = PriceHint.editPost;
	}

	private editMessage() {
		switch (this.message.type) {
			case EMessagesMessageTypes.AUDIO:
			case EMessagesMessageTypes.VIDEO:
			case EMessagesMessageTypes.PHOTO:
			case EMessagesMessageTypes.DOCUMENT:
			case EMessagesMessageTypes.MULTIPART:
				this.onFileSelect((this.message as IMessagesPhotoDefaultMessageResponse).files);
				break;
			default:
				break;
		}
		this.postForm.patchValue({
			text: this.message.text,
			price: !!this.message.price ? this.message.price.rawAmount : '',
			access: true,
		});
		if (this.message.price) this.priceHintMessage = PriceHint.editPost;
		if ((this.message as IDelayMessageResponse).delayDate != null) {
			this.selectedDateTime = moment((this.message as IDelayMessageResponse).delayDate);
		}
	}

	get dateTimeNow() {
		return new Date();
	}

	public addDelayDate(e: any) {
		this.selectedDateTime = moment(e.value);
	}

	get selectedDateText(): string {
		if (this.selectedDateTime != null) {
			return this.selectedDateTime.format('DD/MM/YYYY');
		}
		return '';
	}

	get selectedTimeText(): string {
		if (this.selectedDateTime != null) {
			return this.selectedDateTime.format('hh:mm A');
		}
		return '';
	}

	public is12HoursUserFormat(): boolean {
		const format = new Intl.DateTimeFormat(undefined, { hour: 'numeric' }).resolvedOptions().hourCycle;
		const dateFormat = format.startsWith('h12') ? '12-hour' : '24-hour';
		return dateFormat === '12-hour';
	}

	public get getPriceHintMessage(): EKeysTransloco {
		return PriceHint[this.priceHintMessage] as EKeysTransloco;
	}
}
