import { ChangeDetectorRef, Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HubService } from '../../../../api/hub.service';
import { CommonModule } from '@angular/common';
import { AddMediaComponent } from '../add-media/add-media.component';
import { MediaFileComponent } from '../media-file/media-file.component';
import { FoldersListComponent } from '../folders-list/folders-list.component';
import { IconsComponent } from '../../../../uikit/icons/icons.component';
import { FileFilterComponent } from '../file-filter/file-filter.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FolderCreatorResponse, fromFolder, fromUnassigned } from '../../../../model/content/folder';
import { DialogComponent } from '../../../../uikit/dialog/dialog.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { StorageService } from '../../../../service/storage.service';
import { UserRepositoryService } from '../../../../repository/user-repository.service';
import {filter, Subscription, take, tap} from 'rxjs';
import { IHubFileResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { IHubFolderCreatorResponse } from 'desiren-core-lib/lib/types/hub/creator/folder.creator.hub.interface';
import { MediaPlaceholderComponent } from '../media-file/media-placeholder/media-placeholder.component';
import { TranslationsService } from '../../../../service/translations/translations.service';
import {MessagesApiService} from "../../../../api/ws.service";
import {UUID} from "crypto";

@Component({
	selector: 'app-content-selector',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AddMediaComponent,
		MediaFileComponent,
		FoldersListComponent,
		IconsComponent,
		FileFilterComponent,
		InfiniteScrollModule,
		DialogComponent,
		MediaPlaceholderComponent,
	],
	templateUrl: './content-selector.component.html',
	styleUrl: './content-selector.component.scss',
})
export class ContentSelectorComponent implements OnInit, OnDestroy {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
  public readonly messagesApiService: MessagesApiService = inject(MessagesApiService);
	page = 1;
	count = 0;
	uploadCount = [];
	public isFolderSelected: boolean = false;
	public isSelectionAvailable: boolean = true;
	@ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
	@ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;
	@ViewChild('addMedia') addMedia!: AddMediaComponent;
	@ViewChild('add_folder') add_folder: DialogComponent | null = null;
	form = new FormGroup({
		folderName: new FormControl('', {
			validators: [Validators.required, Validators.maxLength(64)],
		}),
	});

	folders: FolderCreatorResponse[] = [];
	selectedFolder: FolderCreatorResponse | null = null;
	folderFiles: IHubFileResponse[] = [];
	selectedFiles: string[] = [];
	filter: EHubFileType | null = null;
	@Output() onSelect = new EventEmitter<IHubFileResponse[]>();
	@Output() onSelectFolder = new EventEmitter<IHubFolderCreatorResponse>();
	@Input() isLooksMode: boolean = false;
	folderUpdate$: Subscription;

	constructor(private hubApi: HubService, private _crd: ChangeDetectorRef, private storage: StorageService, private userRepo: UserRepositoryService) {
		this.folderUpdate$ = this.hubApi.folderUpdate.subscribe((any) => {
			if (this.isContentCreator) {
				this.getFolders();
			} else {
				this.page = 1;
				this.folderFiles = [];
				this.getFiles();
			}
		});
	}

	get isContentCreator(): boolean {
		return this.userRepo.meLazy().cache.isCreator;
	}

	ngOnInit(): void {
		if (this.isContentCreator) {
			this.getFolders();
		}
		this.getFiles();
	}

	ngOnDestroy() {
		this.folderUpdate$.unsubscribe();
	}

	folderTotal(folder: FolderCreatorResponse | null) {
		if (folder?.id != null) {
			return folder.totalPhotos + folder.totalVideos;
		}
		if (!this.folders.length) {
			return 0;
		}
		return this.folders
			.map((f) => {
				return f.id == null ? f.totalPhotos + f.totalVideos : 0;
			})
			.reduce((a, b) => a + b);
	}

	folderTotalPhotos(folder: FolderCreatorResponse | null) {
		if (folder?.id != null) {
			return folder.totalPhotos;
		}
		if (!this.folders.length) {
			return 0;
		}
		return this.folders
			.map((f) => {
				return f.id == null ? f.totalPhotos : 0;
			})
			.reduce((a, b) => a + b);
	}

	folderTotalVideos(folder: FolderCreatorResponse | null) {
		if (folder?.id != null) {
			return folder.totalVideos;
		}
		if (!this.folders.length) {
			return 0;
		}
		return this.folders
			.map((f) => {
				return f.id == null ? f.totalVideos : 0;
			})
			.reduce((a, b) => a + b);
	}

	get files() {
		if (this.filter == null) {
			return this.folderFiles;
		} else {
			return this.folderFiles.filter((f) => f.file.type == this.filter);
		}
	}

	open() {
		this.dialog.nativeElement.show();
		this.dialog.nativeElement.classList.add('app-dialog-opened');
		this.overlay.nativeElement.classList.add('opened');
	}

	close() {
		this.selectFolder(null);
		this.dialog.nativeElement.close();
		this.dialog.nativeElement.classList.remove('app-dialog-opened');
		this.overlay.nativeElement.classList.remove('opened');
		this.isSelectionAvailable = true;
	}

	selectPhoto() {
		this.filter = EHubFileType.PHOTO;
		this.addMedia.selectPhoto();
	}

	selectVideo() {
		this.filter = EHubFileType.VIDEO;
		this.addMedia.selectVideo();
	}

	getFolders() {
		this.hubApi
			.getFolders(
				{
					page: 1,
					limit: 50,
				},
				this.isContentCreator
			)
			.then((res) => {
				this.hubApi.unassigned.next(fromUnassigned(res.unassigned ?? null, this.translationsService));
				if (this.selectedFolder == null || this.selectedFolder?.id == null) {
					this.selectedFolder = fromUnassigned(res.unassigned ?? null, this.translationsService);
					this.folders = [this.selectedFolder, ...res.data.map((f) => fromFolder(f as IHubFolderCreatorResponse))].sort((a, b) => {
						return b.totalVideos + b.totalPhotos - (a.totalVideos + a.totalPhotos);
					});
				} else {
					const selectedUpdate = res.data.find((folder) => folder.id == this.selectedFolder.id);
					this.selectedFolder = fromFolder(selectedUpdate as IHubFolderCreatorResponse);
					this.folders = [fromUnassigned(res.unassigned ?? null, this.translationsService), ...res.data.map((f) => fromFolder(f as IHubFolderCreatorResponse))].sort((a, b) => {
						return b.totalVideos + b.totalPhotos - (a.totalVideos + a.totalPhotos);
					});
				}
				this._crd.detectChanges();
			});
	}

	selectFolder(folderId: string | null) {
		this.isFolderSelected = true;
		this.page = 1;
		this.count = 0;
		this.folderFiles = [];
		this.selectedFiles = [];
		this.filter = null;
		this.selectedFolder = this.folders.find((f) => f.id == folderId) ?? fromUnassigned(null, this.translationsService);
		this.getFiles();
	}

	getFiles() {
		if (this.count > 0 && this.folderFiles.length == this.count) {
			return;
		}
		const folderId = this.selectedFolder?.id ?? null;
		this.hubApi.getAllFiles(this.page, 30, folderId, this.isContentCreator, this.filter != null ? [this.filter] : [EHubFileType.PHOTO, EHubFileType.VIDEO]).then((data) => {
			this.count = data.count;
			if (this.page > 1) {
				this.folderFiles = this.folderFiles.concat(data.data);
			} else {
				this.folderFiles = [...data.data];
			}
			if (this.folderFiles.length < this.count) {
				this.page = this.page + 1;
			}

			this._crd.detectChanges();
		});
	}

	setFilter(type: EHubFileType | null) {
		this.filter = type;
		this.page = 1;
		this.count = 0;
		this.folderFiles = [];
		this.selectedFiles = [];
		this.getFiles();
	}

	selectFile(fileId: string) {
		let index = this.selectedFiles.indexOf(fileId);
		if (index > -1) {
			this.selectedFiles.splice(index, 1);
		} else {
			this.selectedFiles.push(fileId);
		}
		if (this.isLooksMode && !!this.selectedFiles.length) {
			this.isSelectionAvailable = false;
		} else {
			this.isSelectionAvailable = true;
		}
	}

	onLoadStart(count: number) {
		this.uploadCount = Array(count)
			.fill(0)
			.map((x, i) => i);
	}

	onFileLoaded(files: IHubFileResponse[]) {
    if (files.length) {
      const set = new Set<any>();
      const loaded = new Set<UUID>();
      this.messagesApiService.hubStatusSubj$
        .pipe(
          filter((data) => files.some((key) => key.id === data.fileId)),
          tap((data) => set.add(data)),
          filter((data) => {
            const maxPercent = Math.max(
              ...Array.from(set)
                .filter((key) => key.fileId === data.fileId)
                .map((key) => key.percent)
            );
            return data.percent >= maxPercent;
          }),
          filter((data) => data.percent === 100),
          tap((data) => {
            this.folderFiles = [data.file, ...this.files];
            // this.currentFiles = this.fileList;
            loaded.add(data.fileId);
            this.uploadCount.shift();
            this._crd.detectChanges();
            this.hubApi.folderUpdate.next('update');
          }),
          tap((data) => {
            if (Array.from(loaded)?.length === files.length) {
              this.uploadCount = [];
            }
          }),
          filter((data) => Array.from(loaded)?.length === files.length),
          take(1)
        )
        .subscribe();
    }
	}

	finishSelection() {
		let selectedFiles = this.folderFiles.filter((file) => {
			return this.selectedFiles.includes(file.id);
		});
		this.onSelect.emit(selectedFiles);
		this.selectedFiles = [];
		this.close();
	}

	onScrollUp() {
		if (this.folderFiles.length < this.count) {
			this.getFiles();
		}
	}

	showAddFolderDialog() {
		this.add_folder?.open();
	}

	closeAddFolderDialog() {
		this.add_folder?.close();
	}

	addFolder() {
		if (this.form.valid) {
			let value = this.form.controls.folderName.value?.trim();
			this.form.reset();
			this.hubApi.addFolder(value!, this.isContentCreator).then((_) => {
				this.hubApi.folderUpdate.next('update');
				this.closeAddFolderDialog();
			});
		}
	}
}
