import { NgClass, NgIf } from '@angular/common';
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [NgClass, NgIf, IconsComponent],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent {
  @Input() tagName: string;
  @Input() isRecommendation: boolean = false;
  @Input() isEditable: boolean = false;
  @Output() removeTag: EventEmitter<void> = new EventEmitter();
  @Output() selectTag: EventEmitter<string> = new EventEmitter();
}
