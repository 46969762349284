<div class="content" [ngClass]="[
        isCircle ? 'circle' : '',
        online ? 'online' : '',
        isCreator ? 'creator' : '',
        'marker-size-' + size
      ]">
  <div class="profile-avatar" [ngClass]="['size-' + size ]">
    <ng-container *ngTemplateOutlet="!!this.userImageData ? fromUrl : placeholder"></ng-container>
  </div>
  <ng-template #fromUrl>
    <div class="profile-avatar-img" (click)="showImage()">
      <app-image-blurhash [src]="avatarUrl" [blurhash]="avatarHash" [width]="sizePx"
        [height]="sizePx"></app-image-blurhash>
    </div>
  </ng-template>
  <ng-template #placeholder>
    <div class="profile-avatar-img profile-avatar-placeholder"></div>
  </ng-template>
  <ng-template #statusLabel>
    <div class="online-label"></div>
  </ng-template>
</div>