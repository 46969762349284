import {EHubPhotoMimeTypes} from "desiren-core-lib/lib/enums/hub/mime-types/photo.mime-types.hub.enum";
import {EHubVideoMimeTypes} from "desiren-core-lib/lib/enums/hub/mime-types/video.mime-types.hub.enum";
import {EHubVoiceMimeTypes} from "desiren-core-lib/lib/enums/hub/mime-types/voice.mime-types.hub.enum";
import * as EHubAudioMimeTypes from "desiren-core-lib/lib/enums/hub/mime-types/audio.mime-types.hub.enum";
import {EHubFileType} from "desiren-core-lib/lib/enums/hub/file-type.hub.enum.js";


export class AppMimeTypes {
  static get supportedMimeTypes(): string {
    const photo = Object.keys(EHubPhotoMimeTypes);
    const video = Object.keys(EHubVideoMimeTypes);
    const voice = Object.keys(EHubVoiceMimeTypes);
    const audio = Object.keys(EHubAudioMimeTypes);
    return [...photo, ...video].join(',');
  }
  static get supportedMimePhotoTypes(): string {
    return Object.keys(EHubPhotoMimeTypes).join(',');
  }
  static get supportedMimeVideoTypes(): string {
    return Object.keys(EHubVideoMimeTypes).join(',');
  }
  static getFileType(mime: string): EHubFileType | null {
    if (Object.keys(EHubPhotoMimeTypes).includes(mime)) {
      return EHubFileType.PHOTO;
    }
    if (Object.keys(EHubVideoMimeTypes).includes(mime)) {
      return EHubFileType.VIDEO;
    }
    if (Object.keys(EHubVoiceMimeTypes).includes(mime)) {
      return EHubFileType.VOICE;
    }
    if (Object.keys(EHubAudioMimeTypes).includes(mime)) {
      return EHubFileType.AUDIO;
    }
    return null;
  }
}
