<div class="voice-message" #message [ngClass]="{'disabled': isDisabled}">

  <button (click)="audioControl($event)" class="control btn btn-icon">
    <app-icons [name]="isPlaying ? 'pause' : 'play' "
                [width]="20"
                [height]="20"
                [fillColor]="this.isDisabled ? '#ffffff' : '#DEDEEB'"
      ></app-icons>
  </button>
  <div #waveform  class="waveform"></div>
  <button *ngIf="showDeleteButton" (click)="onRemoveMessageEvent.emit()" class="btn btn-xs-icon">
    <app-icons [name]="'close_2'"
                [width]="14"
                [height]="14"
                [fillColor]="'#6A49FA'"
      ></app-icons>
  </button>
</div>
