import { inject, Injectable } from '@angular/core';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { IHubListFileCreatorResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { HubService } from 'src/app/api/hub.service';
import { ChecksumError } from 'src/app/api/interceptors/error.interceptor';
import { MessagesApiService } from 'src/app/api/ws.service';
import { Md5 } from 'ts-md5';
import * as uuid from 'uuid';
import { TranslationsService } from '../../service/translations/translations.service';

export interface UploadVoiceFileRequest {
	type: EHubFileType;
	fileName: string;
	mimeType: string;
	checksum: string;
	confirmExisting: boolean;
	duration: number;
}

@Injectable({
	providedIn: 'root',
})
export class VoiceRecorderService {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	private mediaRecorder: MediaRecorder;
	private audioChunks: Blob[] = [];
	private audioBlob: Blob;
	private recordingTimeOut: number = 600000;
	private hubApi = inject(HubService);
	private socket = inject(MessagesApiService);
	private readonly toastr: ToastrService = inject(ToastrService);
	apiUrl: string;
	res: any;
	public voiceMessagePauseEvent = new Subject<void>();
	private startTime: number;
	private recordingDurationSubject = new BehaviorSubject<string>('0:00');
	public recordingDuration$: Observable<string> = this.recordingDurationSubject.asObservable();
	private recordingSubscription: any;
	voiceMessagePauseEvent$ = this.voiceMessagePauseEvent.asObservable();

	constructor() {
		this.socket.onConnect();
	}

	async requestMicrophoneAccess() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			stream.getTracks().forEach((track) => track.stop());
		} catch (error) {
			console.error(error);
			this.toastr.info(this.translationsService.translate(this.translationsService.keys.VOICE_RECORDER_TOASTR_VOICE_MIC_PERMISSION));
		}
	}

	async getMediaStream() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			return stream;
		} catch (error) {
			alert(error);
			return null;
		}
	}

	async startRecording() {
		this.recordingDurationSubject.next(`0:00`);
		const stream = await this.getMediaStream();
		if (stream) {
			const options = {
				mimeType: this.isIOS() ? 'audio/mp4' : 'audio/webm',
			};
			this.mediaRecorder = new MediaRecorder(stream, options);
		} else {
			console.log('MediaRecorder error');
			return null;
		}

		this.audioChunks = [];
		this.mediaRecorder.ondataavailable = (event) => {
			this.audioChunks.push(event.data);
		};
		this.mediaRecorder.start();
		this.startTime = Date.now();
		this.recordingSubscription = interval(1000).subscribe(() => {
			this.updateRecordingDuration();
		});
		setTimeout(() => {
			if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
				this.stopRecording();
			}
		}, this.recordingTimeOut);
	}

	resetRecording() {
		if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
			this.stopRecording();
		}
		this.mediaRecorder = null;
		this.audioChunks = [];
	}

	stopRecording(): Promise<Blob> {
		return new Promise((resolve) => {
			this.mediaRecorder.onstop = async () => {
				const type = this.isIOS() ? 'audio/mp4' : 'audio/webm';
				this.audioBlob = new Blob(this.audioChunks, { type });
				console.log('size = ', (this.audioBlob.size / (1024 * 1024)).toFixed(2), 'MB');
				this.recordingDurationSubject.next(`0:00`);
				if (this.recordingSubscription) {
					this.recordingSubscription.unsubscribe();
				}
				resolve(this.audioBlob);
			};
			this.mediaRecorder.stop();
		});
	}

	private updateRecordingDuration(): void {
		const elapsedTime = Date.now() - this.startTime;
		const minutes = Math.floor(elapsedTime / 60000);
		const seconds = Math.floor((elapsedTime % 60000) / 1000)
			.toString()
			.padStart(2, '0');
		this.recordingDurationSubject.next(`${minutes}:${seconds}`);
	}

	private isIOS(): boolean {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	}

	get isRecording(): boolean {
		return this.mediaRecorder && this.mediaRecorder.state === 'recording';
	}

	async uploadMessage(): Promise<IHubListFileCreatorResponse> {
		const audioFile = new File([this.audioBlob], 'recording.webm', {
			type: 'audio/mpeg',
		});
		const data = await this.processAudioFile(audioFile);
		const body: UploadVoiceFileRequest = {
			...data,
			type: EHubFileType.VOICE,
			confirmExisting: false,
			fileName: uuid.v4() + '.' + 'recording' + '.webm',
		};

		let uploadData: any;
		try {
			uploadData = await this.hubApi.getPreBasic([body]);
		} catch (e: any) {
			if (e instanceof ChecksumError) {
				this.toastr.clear();
				this.toastr.error(e.message);
			}
			throw e;
		}

		await this.hubApi.uploadToAws(uploadData[0].link, this.audioBlob).toPromise();
		const finalizeData = uploadData.map((item: any) => {
			return {
				fileId: item.id,
			};
		});
		return await this.hubApi.finalizeUploading(finalizeData, this.socket.socketId, true);
	}

	async processAudioFile(
		file: File,
		id?: string
	): Promise<{
		fileName: string;
		mimeType: string;
		checksum: string;
		duration: number;
	}> {
		try {
			const fileMetadata = await new Promise<{
				fileName: string;
				mimeType: string;
				checksum: string;
			}>((resolve, reject) => {
				const fr = new FileReader();
				fr.onload = (e) => {
					const binary = e.target?.result;
					const hash = Md5.hashAsciiStr(binary as string);
					const fileExt = file.name.split('.').pop()?.toLowerCase() ?? '';
					resolve({
						fileName: (id ?? uuid.v4()) + '.' + fileExt,
						mimeType: file.type,
						checksum: hash,
					});
				};
				fr.onerror = reject;
				fr.readAsBinaryString(file);
			});

			const audioDuration = await new Promise<{ duration: number }>((resolve, reject) => {
				const audio = new Audio();
				audio.onloadedmetadata = () => {
					if (audio.duration === Infinity) {
						audio.currentTime = 1e101;
						audio.ontimeupdate = () => {
							if (!Math.floor(audio.duration)) return reject(new Error('Duration error'));
							audio.ontimeupdate = null;
							resolve({ duration: Math.floor(audio.duration) });
							audio.currentTime = 0;
						};
					} else {
						resolve({ duration: Math.floor(audio.duration) });
					}
				};

				audio.onerror = reject;
				audio.src = URL.createObjectURL(file);
			});

			return {
				...fileMetadata,
				duration: audioDuration.duration,
			};
		} catch (error) {
			throw new Error('Failed to process audio file');
		}
	}
}
