import {
  IHubFolderCreatorResponse,
  IHubUnassigned
} from "desiren-core-lib/lib/types/hub/creator/folder.creator.hub.interface";
import {IHubFileUserResponse} from "desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface";
import {IHubUserResponse} from "desiren-core-lib/lib/types/hub/user.hub.interface";
import {UUID} from "crypto";
import {TranslationsService} from "../../service/translations/translations.service";

export interface FolderCreatorResponse {
  id: UUID | null;
  name: string;
  totalPhotos: number;
  totalVideos: number;
  totalAudios: number;
  totalVoices: number;
  totalDocuments: number;
  last: ReadonlyArray<IHubFolderCreatorResponse | IHubFileUserResponse>;
  user: Readonly<IHubUserResponse> | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
}

export function fromUnassigned(
  data: IHubUnassigned | null,
  translationsService: TranslationsService,
): FolderCreatorResponse {
  return {
    id: null,
    name: translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_HEADER_ALL_MEDIA),
    totalPhotos: data?.totalPhotos ?? 0,
    totalVideos: data?.totalVideos ?? 0,
    totalAudios: data?.totalAudios ?? 0,
    totalVoices: data?.totalVoices ?? 0,
    totalDocuments: data?.totalDocuments ?? 0,
    last: data?.last ?? [],
    user: data?.user ?? null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  }
}

export function fromFolder(data: IHubFolderCreatorResponse): FolderCreatorResponse {
  return {
    id: data.id,
    name: data.name,
    totalPhotos: data?.totalPhotos ?? 0,
    totalVideos: data?.totalVideos ?? 0,
    totalAudios: data?.totalAudios ?? 0,
    totalVoices: data?.totalVoices ?? 0,
    totalDocuments: data?.totalDocuments ?? 0,
    last: data?.last ?? [],
    user: data?.user ?? null,
    createdAt: data?.createdAt ?? null,
    updatedAt: data?.updatedAt ?? null,
    deletedAt: data?.deletedAt ?? null
  }
}
