import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { decode } from 'blurhash';
import { PlatformService } from 'src/app/service/platform.service';

@Component({
	selector: 'app-image-blurhash',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './image-blurhash.component.html',
	styleUrl: './image-blurhash.component.scss',
})
export class ImageBlurhashComponent implements OnChanges {
	private readonly platform: PlatformService = inject(PlatformService);
	private isBrowser: boolean = false;
	@Input() blurhash!: string;
	@Input() src!: string;
	@Input() width: number = 100;
	@Input() height: number = 100;

	imageLoaded: boolean = false;
	constructor(private el: ElementRef) {
		this.isBrowser = this.platform.isBrowser;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.blurhash && this.isBrowser) {
			this.renderBlurhash();
		}
	}

	private renderBlurhash(): void {
		const container = this.el.nativeElement.querySelector('.blurhash-container')!;
		this.width = container.getBoundingClientRect().width;
		this.height = container.getBoundingClientRect().height;
		const canvas = document.createElement('canvas');
		canvas.width = 64
		canvas.height = 64
		const pixels = decode(this.blurhash, 64, 64);
		const ctx = canvas.getContext('2d');

		if (ctx && !!canvas.width && !!canvas.height) {
			const imageData = ctx.createImageData(canvas.width, canvas.height);
			imageData.data.set(Uint8ClampedArray.from(pixels));
			ctx.putImageData(imageData, 0, 0);
			canvas.style.width = '100%';
			canvas.style.height = '100%';
			container.prepend(canvas);
		}
	}

	onImageLoad(): void {
		this.imageLoaded = true;
	}

	onImageError(): void {
		this.imageLoaded = false;
	}
}
