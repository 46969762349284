<div class="file-item" [ngClass]="{'checked': isSelected, 'disabled' : !isFileAvailable }">
  <div class="file-item-header">
    <span class="file-item-date attention-text">{{this.mediaFile.createdAt | date: 'dd MMM' : undefined:
      translationsService.getActiveLang()}}</span>
    <div class="file-item-checkbox-wrapper" *ngIf="isFileAvailable && isSelectionAvailable">
      <label class="file-item-checkbox" [ngClass]="isSelected ? 'checked' : ''" (click)="check()"
        (touchstart)="showTouch($event)" (touchend)="clearTouch()"></label>
    </div>
  </div>
  <div class="video-icon" *ngIf="isVideo">
    <app-icons name="video" width="20" height="20" fillColor="#ffffff"></app-icons>
  </div>
  <img (click)="show()" class="file-item__img" [appBlurhash]="mediaFile?.file?.hash" priority="true" fill="true"
    [style.object-fit]="'cover'" [ngSrc]="previewUrl" [attr.alt]="mediaFile.id">
</div>