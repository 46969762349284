import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { IconsComponent } from '../../../../uikit/icons/icons.component';
import { FileUploadingBody, HubService, PreloadResponse } from '../../../../api/hub.service';
import { MessagesApiService } from '../../../../api/ws.service';
import { delay, filter, forkJoin, from, map, mergeMap, retryWhen, Subject, tap } from 'rxjs';
import { AppMimeTypes } from '../../../../model/mime-types';
import * as uuid from 'uuid';
import { Md5 } from 'ts-md5';
import { ToastrService } from 'ngx-toastr';
import { StorageDataKey, StorageService } from '../../../../service/storage.service';
import { ChecksumError } from '../../../../api/interceptors/error.interceptor';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { IHubFileResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { IHubMultipartUploadResponse, IHubMultipartUploadVideoResponse } from 'desiren-core-lib/lib/types/hub/upload.hub.interface';
import { TranslationsService } from '../../../../service/translations/translations.service';

export interface UploadFileRequest {
	type: EHubFileType;
	fileName: string;
	mimeType: string;
	checksum: string;
	width: number;
	height: number;
	confirmExisting: boolean;
	duration: number | undefined;
}

interface UploadFiles {
	file: File;
}

interface ProcessorResult {
	presign: UploadFileRequest;
	files: UploadFiles;
}

enum VideoMimeTypes {
	mp4 = 'video/mp4',
	ogg = 'video/ogg',
	webm = 'video/webm',
	quicktime = 'video/quicktime',
}

function getMimeTypeString(filename: string): VideoMimeTypes {
	let parts = filename.split('.');
	let name = parts[parts.length - 1];
	switch (name) {
		case 'mp4':
			return VideoMimeTypes.mp4;
		case 'ogg':
			return VideoMimeTypes.ogg;
		case 'webm':
			return VideoMimeTypes.webm;
		case 'mov':
			return VideoMimeTypes.quicktime;
		default:
			throw Error();
	}
}

@Component({
	selector: 'app-add-media',
	standalone: true,
	imports: [CommonModule, IconsComponent],
	templateUrl: './add-media.component.html',
	styleUrl: './add-media.component.scss',
})
export class AddMediaComponent implements OnDestroy {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	@Input('folderId') folderId: string | null = null;
	@ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
	@ViewChild('fileInputPhoto') fileInputPhoto!: ElementRef<HTMLInputElement>;
	@ViewChild('fileInputVideo') fileInputVideo!: ElementRef<HTMLInputElement>;
	@Output('onLoadStart') onLoadStart = new EventEmitter<number>();
	@Output('onLoaded') onLoaded = new EventEmitter<IHubFileResponse[]>();

	private hubApi = inject(HubService);
	private socket = inject(MessagesApiService);
	private document = inject(DOCUMENT);
	private toastr = inject(ToastrService);
	private storage = inject(StorageService);

	public readonly filesHandler$ = new Subject<FileList | null>();

	public supportedMimeTypes = AppMimeTypes.supportedMimeTypes;
	public supportedMimePhotoTypes = AppMimeTypes.supportedMimePhotoTypes;
	public supportedMimeVideoTypes = AppMimeTypes.supportedMimeVideoTypes;

	private readonly filesHandlerObs = this.filesHandler$
		.pipe(
			filter((files) => Boolean(files !== null)),
			map((files) => files as FileList),
			map((files) =>
				Array.from(files).map((file) => ({
					file: file,
					type: AppMimeTypes.getFileType(file.type),
				}))
			),
			map((files) => files.filter((obj) => Boolean(obj.type))),
			map((files) => files.map((key) => key as { file: File; type: EHubFileType })),
			tap({
				next: (data) => {
					this.toastr.info(
						`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_PROCESSING)} ${data.length} ${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_FILES)}`
					);
					this.onLoadStart.emit(data.length);
				},
			}),
			mergeMap(async (files) => await Promise.allSettled(files.map((file) => this.processorMap(file))))
		)
		.subscribe(async (res: any) => {
			let basicFiles: ProcessorResult[] = [];
			let multipartFiles: ProcessorResult[][] = [];
			const files = res.map((data: { status: string; value: ProcessorResult | ProcessorResult[] }) => data.value);
      for (let file of files) {
				if (file instanceof Array) {
					multipartFiles.push(file);
				} else {
					basicFiles.push(file);
				}
			}
			if (basicFiles.length) {
				await this._basicFileUploader(basicFiles);
			}
			if (multipartFiles.length) {
				await this._multipartFileUploader(multipartFiles);
			}
		});

	private async _basicFileUploader(files: ProcessorResult[]) {
		const isContentCreator = this.storage.getLocalJsonData(StorageDataKey.apiUser).isCreator;
		let uploadData: PreloadResponse[] = [];
		try {
			uploadData = await this.hubApi.getPreBasic(files.map((f: ProcessorResult) => f.presign));
		} catch (e: any) {
			if (e instanceof ChecksumError) {
				this.toastr.clear();
				this.toastr.error(e.message);
				this.onLoaded.emit([]);
			}
			return;
		}
		let forks = [];
		this.toastr.clear();
		this.toastr.info(
			`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_UPLOADING)} ${files.length} ${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_FILES)}`
		);
		for (let i = 0; i < files.length; i++) {
			let fork = [this.hubApi.uploadToAws(uploadData[i].link, files[i].files.file)];
			forks.push(forkJoin(fork));
		}
		forkJoin(forks).subscribe((_) => {
			const finalizeData = uploadData.map<FileUploadingBody>((item) => {
				return {
					fileId: item.id,
					folderId: this.folderId!,
				};
			});
			this.hubApi.finalizeUploading(finalizeData, this.socket.socketId, isContentCreator).then((res) => {
				this.fileInput.nativeElement!.value = '';
				this.fileInputPhoto.nativeElement!.value = '';
				this.fileInputVideo.nativeElement!.value = '';

				this.onLoaded.emit([...res.data]);
				this.toastr.clear();
				this.toastr.success(
					`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_UPLOADED)} ${files.length} ${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_FILES)}`
				);
			});
		});
	}

	private async _multipartFileUploader(files: ProcessorResult[][]) {
		let uploadData: IHubMultipartUploadResponse[] = [];
		try {
			uploadData = await this.hubApi.getPreMultipartBasic(
				files.map((f: ProcessorResult[]) => {
					return {
						type: EHubFileType.VIDEO,
						fileName: f[0].files.file.name.toLowerCase(),
						mimeType: getMimeTypeString(f[0].files.file.name.toString().toLowerCase()),
						confirmExisting: false,
						parts: f.length,
						height: f[0].presign.height,
						width: f[0].presign.width,
						duration: f[0].presign.duration,
					};
				})
			);
		} catch (e: any) {
			if (e instanceof ChecksumError) {
				this.toastr.clear();
				this.toastr.error(e.message);
				this.onLoaded.emit([]);
			}
			return;
		}
		let forks = [];
		let tags: Map<string, { part: number; tag: string }[]> = new Map();
		this.toastr.clear();
		this.toastr.info(
			`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_UPLOADING)} ${files.length} ${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_FILES)}`
		);
		for (let i = 0; i < files.length; i++) {
			let fileUploadData = uploadData[i] as IHubMultipartUploadVideoResponse;
			let fork = [];
			let progress = [];
			for (let fi = 0; fi < fileUploadData.links.length; fi++) {
				fork.push(
					this.hubApi.uploadToAws(fileUploadData.links[fi].url, files[i][fi].files.file).pipe(
						tap((response: Response) => {
							if (tags.has(fileUploadData.awsFileId)) {
								let data = tags.get(fileUploadData.awsFileId);
								data.push({ part: fi, tag: JSON.parse(response.headers.get('ETag')) });
								tags.set(fileUploadData.awsFileId, data);
							} else {
								tags.set(fileUploadData.awsFileId, [{ part: fi, tag: JSON.parse(response.headers.get('ETag')) }]);
							}
							progress.push(fi);
							this.toastr.clear();
							this.toastr.info(`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_UPLOADING)} ${((progress.length / fileUploadData.links.length) * 100).toFixed(0)}%`);
						})
					)
				);
			}
			forks.push(forkJoin(fork));
		}

		forkJoin(forks).subscribe((forksResult) => {
			const finalizeData = uploadData.map((item) => {
				return {
					id: item.id,
					awsFileId: item.awsFileId,
					parts: tags.get(item.awsFileId).map((data) => {
						return {
							PartNumber: data.part + 1,
							ETag: data.tag,
						};
					}),
				};
			});
			forkJoin(
				Array.from(
					finalizeData.map((data) => {
						return from(this.hubApi.finalizeMultipart(data, this.folderId!, this.socket.socketId)).pipe(
							tap((res) => {
								return from(
									this.hubApi
										.finalizeUploading(
											[
												{
													fileId: data.id,
													folderId: this.folderId,
												},
											],
											this.socket.socketId,
											true
										)
										.then((file) => {
											this.onLoaded.emit([...file.data]);
										})
								).pipe(
									retryWhen((errors) =>
										errors.pipe(
											tap((err) => console.log(`Error occurred: ${err.message}. Retrying...`)),
											delay(2000)
										)
									)
								);
							})
						);
					})
				)
			).subscribe((res) => {
				this.fileInput.nativeElement!.value = '';
				this.fileInputPhoto.nativeElement!.value = '';
				this.fileInputVideo.nativeElement!.value = '';
				this.toastr.clear();
				this.toastr.success(
					`${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_UPLOADED)} ${files.length} ${this.translationsService.translate(this.translationsService.keys.CONTENT_ADD_MEDIA_TOASTR_FILES)}`
				);
			});
		});
	}

	public selectFiles() {
		this.fileInput.nativeElement.click();
	}

	public selectPhoto() {
		this.fileInputPhoto.nativeElement.click();
	}

	public selectVideo() {
		this.fileInputVideo.nativeElement.click();
	}

	// @ts-ignore
	private async processorMap(data: { file: File; type: EHubFileType }): Promise<ProcessorResult | ProcessorResult[]> {
		switch (data.type) {
			case EHubFileType.PHOTO:
				if (data.file.name.toLowerCase().includes('heic')) {
					// let jpgBlob;
					// let promise = new Promise((resolve, reject) => {
					//   const reader = new FileReader();
					//   reader.onload = async function(e) {
					//     //@ts-ignore
					//     const blob = new Blob([new Uint8Array(e.target.result)], {type: 'image/heic' });
					//     jpgBlob = await heic2any({
					//       blob,
					//       toType: "image/jpeg",
					//       quality: 1
					//     });
					//     resolve(jpgBlob);
					//   };
					//   reader.readAsArrayBuffer(data.file);
					// });
					// await promise.then(_ => {});
					// return await this.processPhoto({file: new File([jpgBlob], data.file.name + '.jpeg', {
					//   type: 'image/jpeg'
					//   }), type: EHubFileType.PHOTO});
					return await this.processPhoto({ file: data.file, type: EHubFileType.PHOTO });
				} else {
					return await this.processPhoto({ file: data.file, type: EHubFileType.PHOTO });
				}
			case EHubFileType.VOICE:
				return await this.processVoice({ file: data.file, type: EHubFileType.VOICE });
			case EHubFileType.VIDEO:
				return await this.processVideo({ file: data.file, type: EHubFileType.VIDEO });
			case EHubFileType.DOCUMENT:
				return await this.processDocument({ file: data.file, type: EHubFileType.DOCUMENT });
			case EHubFileType.AUDIO:
				return await this.processAudio({ file: data.file, type: EHubFileType.AUDIO });
		}
	}

	private async processPhoto(data: { file: File; type: EHubFileType.PHOTO }): Promise<ProcessorResult> {
		const extraData = await this.getPhotoExtraData(data);
		return this.readFileCheck(data.file).then((res) => {
			return {
				presign: {
					...res,
					type: data.type,
					height: extraData.height,
					width: extraData.width,
					confirmExisting: false,
				},
				files: {
					file: data.file,
				},
			} as ProcessorResult;
		});
	}

	private async processVideo(data: { file: File; type: EHubFileType.VIDEO }): Promise<ProcessorResult | ProcessorResult[]> {
    if (data.file.size > 1024 * 1024 * 30) {
			return await this.processMultipartVideo(data);
		}
		const extraData = await this.getVideoExtraData(data);
		const id = uuid.v4();
		const fileCheck = await this.readFileCheck(data.file, id);
		return {
			presign: {
				...fileCheck,
				type: data.type,
				height: extraData.height,
				width: extraData.width,
				duration: extraData.duration,
				confirmExisting: false,
			},
			files: {
				file: data.file,
			},
		};
	}

	private async processMultipartVideo(data: { file: File; type: EHubFileType.VIDEO }): Promise<ProcessorResult[]> {
    let chunks = this.createChunks(data.file);
		let result: ProcessorResult[] = [];
		const extraData = await this.getVideoExtraData(data);
		for (let chunk of chunks) {
			const id = uuid.v4();
			const fileCheck = await this.readFileCheck(new File([chunk], data.file.name), id);
      result.push({
				presign: {
					...fileCheck,
					type: data.type,
					height: extraData.height,
					width: extraData.width,
					duration: extraData.duration,
					confirmExisting: false,
				},
				files: {
					file: new File([chunk], fileCheck.fileName),
				},
			});
		}
		return result;
	}

	private async processAudio(data: { file: File; type: EHubFileType.AUDIO }): Promise<ProcessorResult> {
		const extraData = await this.getAudioExtraData(data);
		// TODO: Get url
		// TODO: Upload
		return {} as Promise<ProcessorResult>;
	}

	private async processVoice(data: { file: File; type: EHubFileType.VOICE }): Promise<ProcessorResult> {
		const extraData = await this.getAudioExtraData(data);
		// TODO: Get url
		// TODO: Upload
		return {} as Promise<ProcessorResult>;
	}

	private async processDocument(data: { file: File; type: EHubFileType.DOCUMENT }): Promise<ProcessorResult> {
		// TODO: Get url
		// TODO: Upload
		return {} as Promise<ProcessorResult>;
	}

	private async getPhotoExtraData(data: { file: File; type: EHubFileType.PHOTO }): Promise<{ width: number; height: number }> {
		// @ts-ignore
		return new Promise((resolve) => {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				const file = new Image();
				file.onload = () => {
					resolve({ width: Math.floor(file.width), height: Math.floor(file.height) });
				};
				if (typeof fileReader.result === 'string') {
					file.src = fileReader.result;
				}
			};
			fileReader.readAsDataURL(data.file);
		});
	}

	private async getVideoExtraData(data: { file: File; type: EHubFileType.VIDEO }): Promise<{ width: number; height: number; duration: number }> {
		// @ts-ignore
		return new Promise((resolve) => {
			const video = this.document.createElement('video');
			video.addEventListener('loadedmetadata', (event) => {
				const response = {
					width: Math.floor(video.videoWidth),
					height: Math.floor(video.videoHeight),
					duration: Math.floor(video.duration),
				};
				video.remove();
				resolve(response);
			});
			video.src = URL.createObjectURL(data.file);
		});
	}

	private async getAudioExtraData(data: { file: File; type: EHubFileType.AUDIO } | { file: File; type: EHubFileType.VOICE }): Promise<{ duration: number }> {
		// @ts-ignore
		return new Promise((resolve) => {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				const file = new Audio();
				file.onload = () => {
					resolve({ duration: Math.floor(file.duration) });
				};
				if (typeof fileReader.result === 'string') {
					file.src = fileReader.result;
				}
			};
			fileReader.readAsDataURL(data.file);
		});
	}
	readFileCheck(file: File, id?: string | undefined): Promise<{ fileName: string; mimeType: string; checksum: string }> {
		return new Promise((resolve, reject) => {
			const fr = new FileReader();
			fr.onload = (e) => {
				let binary = e.target?.result;
				const hash = Md5.hashAsciiStr(binary as string);
				const fileExt = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
				resolve({
					fileName: (id ?? uuid.v4()) + '-source.' + fileExt,
					mimeType: file.type,
					checksum: hash,
				});
			};
			fr.onerror = reject;
			fr.readAsBinaryString(file);
		});
	}

	private createChunks(file: File, cSize: number = 1024 * 1024 * 30): Blob[] {
		let startPointer = 0;
		let endPointer = file.size;
		let chunks = [];
		while (startPointer < endPointer) {
			let newStartPointer = startPointer + cSize;
			chunks.push(file.slice(startPointer, newStartPointer));
			startPointer = newStartPointer;
		}
		return chunks;
	}

	ngOnDestroy() {
		this.filesHandlerObs.unsubscribe();
	}
}
