export enum EKeysTransloco {
	'GLOBAL_TOASTR_LINK_WAS_COPIED' = 'GLOBAL_TOASTR_LINK_WAS_COPIED',
	'GLOBAL_TOASTR_SUCCESS' = 'GLOBAL_TOASTR_SUCCESS',
	'GLOBAL_TOASTR_ERROR' = 'GLOBAL_TOASTR_ERROR',
	'GLOBAL_TOASTR_TO_SEND_A_MESSAGE_YOU_MUST_BE_SUBSCRIBED' = 'GLOBAL_TOASTR_TO_SEND_A_MESSAGE_YOU_MUST_BE_SUBSCRIBED',
	'GLOBAL_TOASTR_THE_PAYMENT_METHOD_HAS_BEEN_ADDED' = 'GLOBAL_TOASTR_THE_PAYMENT_METHOD_HAS_BEEN_ADDED',
	'GLOBAL_TOASTR_AN_ERROR_OCCURRED_WHILE_ADDING_A_PAYMENT' = 'GLOBAL_TOASTR_AN_ERROR_OCCURRED_WHILE_ADDING_A_PAYMENT',
	'GLOBAL_TEXT_ALL_FANS' = 'GLOBAL_TEXT_ALL_FANS',
	'GLOBAL_BUTTON_NEXT' = 'GLOBAL_BUTTON_NEXT',
	'GLOBAL_BUTTON_CANCEL' = 'GLOBAL_BUTTON_CANCEL',
	'GLOBAL_BUTTON_NEXT_STEP' = 'GLOBAL_BUTTON_NEXT_STEP',
	'GLOBAL_BUTTON_BACK' = 'GLOBAL_BUTTON_BACK',
	'GLOBAL_BUTTON_SAVE' = 'GLOBAL_BUTTON_SAVE',
	'GLOBAL_SPAN_CROP' = 'GLOBAL_SPAN_CROP',
	'GLOBAL_BUTTON_UPLOAD' = 'GLOBAL_BUTTON_UPLOAD',
	'GLOBAL_TEXT_MY_SUBSCRIPTION' = 'GLOBAL_TEXT_MY_SUBSCRIPTION',
	'GLOBAL_TEXT_NEW_POST' = 'GLOBAL_TEXT_NEW_POST',
	'GLOBAL_TOASTR_TEMPORARY_UNAVAILABLE' = 'GLOBAL_TOASTR_TEMPORARY_UNAVAILABLE',
	'GLOBAL_TOASTR_USER_NOT_FOUND' = 'GLOBAL_TOASTR_USER_NOT_FOUND',
	'GLOBAL_TEXT_TODAY' = 'GLOBAL_TEXT_TODAY',
	'GLOBAL_DELETED_USER_NICKNAME' = 'GLOBAL_DELETED_USER_NICKNAME',
	'GLOBAL_DELETE' = 'GLOBAL_DELETE',
	'GLOBAL_EDIT' = 'GLOBAL_EDIT',
	'GLOBAL_UPDATE' = 'GLOBAL_UPDATE',
	'GLOBAL_PUBLISH' = 'GLOBAL_PUBLISH',

	'AGENCY_TAG_TEXT_ROLE_ACCOUNT_MANAGEMENT' = 'AGENCY_TAG_TEXT_ROLE_ACCOUNT_MANAGEMENT',
	'AGENCY_TAG_TEXT_ROLE_CONTENT_AUDIENCE' = 'AGENCY_TAG_TEXT_ROLE_CONTENT_AUDIENCE',
	'AGENCY_TAG_TEXT_ROLE_TAILORED_MARKETING' = 'AGENCY_TAG_TEXT_ROLE_TAILORED_MARKETING',
	'AGENCY_TAG_TEXT_ROLE_CREATOR_CONSULTING' = 'AGENCY_TAG_TEXT_ROLE_CREATOR_CONSULTING',

	'COMMENTS_ADD_COMMENT_BUTTON_SEND' = 'COMMENTS_ADD_COMMENT_BUTTON_SEND',
	'COMMENTS_COMMENT_ITEM_BUTTON_REPLY' = 'COMMENTS_COMMENT_ITEM_BUTTON_REPLY',

	'CONFIRM_PURCHASE_HEADER_MODAL_SUBSCRIPTION' = 'CONFIRM_PURCHASE_HEADER_MODAL_SUBSCRIPTION',
	'CONFIRM_PURCHASE_HEADER_MODAL_TRIAL' = 'CONFIRM_PURCHASE_HEADER_MODAL_TRIAL',
	'CONFIRM_PURCHASE_HEADER_MODAL_POST' = 'CONFIRM_PURCHASE_HEADER_MODAL_POST',
	'CONFIRM_PURCHASE_HEADER_MODAL_TIPS' = 'CONFIRM_PURCHASE_HEADER_MODAL_TIPS',
	'CONFIRM_PURCHASE_SPAN_FREE_TRIAL_PERIOD_1_WEEK' = 'CONFIRM_PURCHASE_SPAN_FREE_TRIAL_PERIOD_1_WEEK',
	'CONFIRM_PURCHASE_SPAN_AFTER_TRIAL_PERIOD' = 'CONFIRM_PURCHASE_SPAN_AFTER_TRIAL_PERIOD',
	'CONFIRM_PURCHASE_SPAN_5_PERCENT_VAT' = 'CONFIRM_PURCHASE_SPAN_5_PERCENT_VAT',
	'CONFIRM_PURCHASE_SPAN_YOU_WILL_BE_CHARGED' = 'CONFIRM_PURCHASE_SPAN_YOU_WILL_BE_CHARGED',
	'CONFIRM_PURCHASE_SPAN_SUBSCRIBE_TO' = 'CONFIRM_PURCHASE_SPAN_SUBSCRIBE_TO',
	'CONFIRM_PURCHASE_SPAN_FOR' = 'CONFIRM_PURCHASE_SPAN_FOR',
	'CONFIRM_PURCHASE_SPAN_ADD_NEW_CARD' = 'CONFIRM_PURCHASE_SPAN_ADD_NEW_CARD',
	'CONFIRM_PURCHASE_SWIPE_TRIAL' = 'CONFIRM_PURCHASE_SWIPE_TRIAL',
	'CONFIRM_PURCHASE_SWIPE_PAY' = 'CONFIRM_PURCHASE_SWIPE_PAY',
	'CONFIRM_PURCHASE_BUTTON_ADD_CARD' = 'CONFIRM_PURCHASE_BUTTON_ADD_CARD',
	'COOKIE_DIALOG_TEXT_COOKIE_DIALOG' = 'COOKIE_DIALOG_TEXT_COOKIE_DIALOG',
	'COOKIE_DIALOG_BUTTON_HERE' = 'COOKIE_DIALOG_BUTTON_HERE',
	'COOKIE_DIALOG_BUTTON_ACCEPT_ALL' = 'COOKIE_DIALOG_BUTTON_ACCEPT_ALL',

	'OWL_TEXT_ADD_A_SECOND' = 'OWL_TEXT_ADD_A_SECOND',
	'OWL_TEXT_MINUS_A_SECOND' = 'OWL_TEXT_MINUS_A_SECOND',
	'OWL_TEXT_ADD_A_MINUTE' = 'OWL_TEXT_ADD_A_MINUTE',
	'OWL_TEXT_MINUS_A_MINUTE' = 'OWL_TEXT_MINUS_A_MINUTE',
	'OWL_TEXT_ADD_A_HOUR' = 'OWL_TEXT_ADD_A_HOUR',
	'OWL_TEXT_MINUS_A_HOUR' = 'OWL_TEXT_MINUS_A_HOUR',
	'OWL_TEXT_PREVIOUS_MONTH' = 'OWL_TEXT_PREVIOUS_MONTH',
	'OWL_TEXT_NEXT_MONTH' = 'OWL_TEXT_NEXT_MONTH',
	'OWL_TEXT_PREVIOUS_YEAR' = 'OWL_TEXT_PREVIOUS_YEAR',
	'OWL_TEXT_NEXT_YEAR' = 'OWL_TEXT_NEXT_YEAR',
	'OWL_TEXT_PREVIOUS_21_YEARS' = 'OWL_TEXT_PREVIOUS_21_YEARS',
	'OWL_TEXT_NEXT_21_YEARS' = 'OWL_TEXT_NEXT_21_YEARS',
	'OWL_TEXT_CHANGE_TO_MONTH_VIEW' = 'OWL_TEXT_CHANGE_TO_MONTH_VIEW',
	'OWL_TEXT_CHOOSE_MONTH_AND_YEAR' = 'OWL_TEXT_CHOOSE_MONTH_AND_YEAR',
	'OWL_TEXT_CANCEL' = 'OWL_TEXT_CANCEL',
	'OWL_TEXT_SET' = 'OWL_TEXT_SET',
	'OWL_TEXT_FROM' = 'OWL_TEXT_FROM',
	'OWL_TEXT_TO' = 'OWL_TEXT_TO',
	'OWL_TEXT_AM' = 'OWL_TEXT_AM',
	'OWL_TEXT_PM' = 'OWL_TEXT_PM,',

	'CREATORS_CREATORS_POST_TEXT_AREA_COMPOSE_NEW_POST' = 'CREATORS_CREATORS_POST_TEXT_AREA_COMPOSE_NEW_POST',
	'CREATORS_CREATORS_POST_SPAN_TURN_OFF_COMMENTS' = 'CREATORS_CREATORS_POST_SPAN_TURN_OFF_COMMENTS',
	'CREATORS_CREATORS_POST_BUTTON_SEND' = 'CREATORS_CREATORS_POST_BUTTON_SEND',
	'CREATORS_CREATORS_POST_SPAN_MESSAGE_PRICE' = 'CREATORS_CREATORS_POST_SPAN_MESSAGE_PRICE',
	'CREATORS_CREATORS_POST_INPUT_FREE' = 'CREATORS_CREATORS_POST_INPUT_FREE',
	'CREATORS_CREATORS_POST_SPAN_NEW_POST_MINIMUM_3_USD_OR_FREE' = 'CREATORS_CREATORS_POST_SPAN_NEW_POST_MINIMUM_3_USD_OR_FREE',
	'CREATORS_CREATORS_POST_SPAN_EDIT_POST_MINIMUM_3_USD_OR_FREE' = 'CREATORS_CREATORS_POST_SPAN_EDIT_POST_MINIMUM_3_USD_OR_FREE',
	'CREATORS_CREATORS_POST_SPAN_TAGS' = 'CREATORS_CREATORS_POST_SPAN_TAGS',
	'CREATORS_CREATORS_POST_INPUT_TYPE_SOMETHING' = 'CREATORS_CREATORS_POST_INPUT_TYPE_SOMETHING',
	'CREATORS_CREATORS_POST_SPAN_PRIVACY_SETTINGS' = 'CREATORS_CREATORS_POST_SPAN_PRIVACY_SETTINGS',
	'CREATORS_CREATORS_POST_SPAN_PUBLIC' = 'CREATORS_CREATORS_POST_SPAN_PUBLIC',
	'CREATORS_CREATORS_POST_SPAN_THIS_POST_WILL_BE_VISIBLE_TO' = 'CREATORS_CREATORS_POST_SPAN_THIS_POST_WILL_BE_VISIBLE_TO',
	'CREATORS_CREATORS_POST_SPAN_ALL_USERS' = 'CREATORS_CREATORS_POST_SPAN_ALL_USERS',
	'CREATORS_CREATORS_POST_SPAN_AND' = 'CREATORS_CREATORS_POST_SPAN_AND',
	'CREATORS_CREATORS_POST_SPAN_YOUR_SUBSCRIBERS' = 'CREATORS_CREATORS_POST_SPAN_YOUR_SUBSCRIBERS',
	'CREATORS_CREATORS_POST_SPAN_SUBSCRIBERS_ONLY' = 'CREATORS_CREATORS_POST_SPAN_SUBSCRIBERS_ONLY',

	'DONATE_AMOUNT_HEADER_ENTER_AMOUNT' = 'DONATE_AMOUNT_HEADER_ENTER_AMOUNT',
	'DONATE_AMOUNT_BUTTON_NEXT' = 'DONATE_AMOUNT_BUTTON_NEXT',

	'FEED_SUGGESTIONS_HEADER_SUGGESTIONS' = 'FEED_SUGGESTIONS_HEADER_SUGGESTIONS',
	'FEED_SUGGESTIONS_SPAN_POSTS' = 'FEED_SUGGESTIONS_SPAN_POSTS',

	'FUN_FILTER_SPAN_FAN_FILTER_TITTLE' = 'FUN_FILTER_SPAN_FAN_FILTER_TITTLE',
	'FUN_FILTER_BUTTON_FAN_FILTER_CLEAR_ALL' = 'FUN_FILTER_BUTTON_FAN_FILTER_CLEAR_ALL',
	'FUN_FILTER_SPAN_TYPE_OF_CONTENT' = 'FUN_FILTER_SPAN_TYPE_OF_CONTENT',
	'FUN_FILTER_BUTTON_CLEAR' = 'FUN_FILTER_BUTTON_CLEAR',
	'FUN_FILTER_NAME_GENDER' = 'FUN_FILTER_HEADER_GENDER',
	'FUN_FILTER_NAME_BUTT' = 'FUN_FILTER_HEADER_BUTT',
	'FUN_FILTER_NAME_BREASTS' = 'FUN_FILTER_HEADER_BREASTS',
	'FUN_FILTER_NAME_PENIS' = 'FUN_FILTER_HEADER_PENIS',
	'FUN_FILTER_NAME_HAIR_STYLE' = 'FUN_FILTER_HEADER_HAIR_STYLE',
	'FUN_FILTER_NAME_HAIR_LENGTH' = 'FUN_FILTER_HEADER_HAIR_LENGTH',
	'FUN_FILTER_NAME_SKIN_TONE' = 'FUN_FILTER_HEADER_SKIN_TONE',
	'FUN_FILTER_NAME_EYE_COLOR' = 'FUN_FILTER_HEADER_EYE_COLOR',
	'FUN_FILTER_NAME_HAIR_COLOR' = 'FUN_FILTER_HEADER_HAIR_COLOR',
	'FUN_FILTER_NAME_ADDITIONAL' = 'FUN_FILTER_NAME_ADDITIONAL',
	'FUN_FILTER_NAME_BODY_TYPE' = 'FUN_FILTER_HEADER_BODY_TYPE',
	'FUN_FILTER_SPAN_HEIGHT' = 'FUN_FILTER_SPAN_HEIGHT',
	'FUN_FILTER_BUTTON_SAVE' = 'FUN_FILTER_BUTTON_SAVE',

	'MEDIA_PREVIEW_DIALOG_BUTTON_CLOSE' = 'MEDIA_PREVIEW_DIALOG_BUTTON_CLOSE',

	'USERS_SUBNAVIGATION_SPAN_MENU_BECOME_A_CREATOR' = 'USERS_SUBNAVIGATION_SPAN_MENU_BECOME_A_CREATOR',
	'USERS_SUBNAVIGATION_SPAN_MY_PROFILE' = 'USERS_SUBNAVIGATION_SPAN_MY_PROFILE',
	'USERS_SUBNAVIGATION_SPAN_QUEUE' = 'USERS_SUBNAVIGATION_SPAN_QUEUE',
	'USERS_SUBNAVIGATION_SPAN_STATEMENTS' = 'USERS_SUBNAVIGATION_SPAN_STATEMENTS',
	'USERS_SUBNAVIGATION_SPAN_CONTENT_HUB' = 'USERS_SUBNAVIGATION_SPAN_CONTENT_HUB',
	'USERS_SUBNAVIGATION_SPAN_SETTINGS' = 'USERS_SUBNAVIGATION_SPAN_SETTINGS',
	'USERS_SUBNAVIGATION_SPAN_BOOST_YOUR_ACCOUNT' = 'USERS_SUBNAVIGATION_SPAN_BOOST_YOUR_ACCOUNT',
	'USERS_SUBNAVIGATION_SPAN_LOGOUT' = 'USERS_SUBNAVIGATION_SPAN_LOGOUT',

	'NAVIGATOR_MENU_HOME' = 'NAVIGATOR_MENU_HOME',
	'NAVIGATOR_MENU_DESIRES' = 'NAVIGATOR_MENU_DESIRES',
	'NAVIGATOR_MENU_MESSAGES' = 'NAVIGATOR_MENU_MESSAGES',
	'NAVIGATOR_MENU_NOTIFICATIONS' = 'NAVIGATOR_MENU_NOTIFICATIONS',
	'NAVIGATOR_MENU_MY_PROFILE' = 'NAVIGATOR_MENU_MY_PROFILE',
	'NAVIGATOR_MENU_LOOKS' = 'NAVIGATOR_MENU_LOOKS',
	'NAVIGATOR_MENU_STATEMENTS' = 'NAVIGATOR_MENU_STATEMENTS',
	'NAVIGATOR_MENU_CONTENT_HUB' = 'NAVIGATOR_MENU_CONTENT_HUB',
	'NAVIGATOR_MENU_QUEUE' = 'NAVIGATOR_MENU_QUEUE',
	'NAVIGATOR_MENU_STATISTICS' = 'NAVIGATOR_MENU_STATISTICS',
	'NAVIGATOR_MENU_SETTINGS' = 'NAVIGATOR_MENU_SETTINGS',
	'NAVIGATOR_MENU_NEW_POST' = 'NAVIGATOR_MENU_NEW_POST',
	'NAVIGATOR_MENU_BECOME_A_CREATOR' = 'NAVIGATOR_MENU_BECOME_A_CREATOR',
	'NAVIGATOR_MENU_LOGIN' = 'NAVIGATOR_MENU_LOGIN',
	'NAVIGATOR_MENU_BOOST_YOUR_ACCOUNT' = 'NAVIGATOR_MENU_BOOST_YOUR_ACCOUNT',
	'NAVIGATOR_MENU_LOGIN_NOW' = 'NAVIGATOR_MENU_LOGIN_NOW',

	'POLICY_LINKS_LI_PRIVACY' = 'POLICY_LINKS_LI_PRIVACY',
	'POLICY_LINKS_LI_COOKIE' = 'POLICY_LINKS_LI_COOKIE',
	'POLICY_LINKS_LI_TERMS_OF_SERVICE' = 'POLICY_LINKS_LI_TERMS_OF_SERVICE',

	'POST_CREATOR_POST_TOASTR_THE_POST_HAS_BEEN_DELETED' = 'POST_CREATOR_POST_TOASTR_THE_POST_HAS_BEEN_DELETED',
	'POST_CREATOR_POST_SPAN_POST' = 'POST_CREATOR_POST_SPAN_POST',
	'POST_CREATOR_POST_SPAN_DELETED_POST_BY_CREATOR' = 'POST_CREATOR_POST_SPAN_DELETED_POST_BY_CREATOR',

	'POST_FEED_POST_SPAN_PRICE' = 'POST_FEED_POST_SPAN_PRICE',
	'POST_FEED_POST_SPAN_SUBSCRIBERS' = 'POST_FEED_POST_SPAN_SUBSCRIBERS',
	'POST_FEED_POST_SPAN_EDIT_POST' = 'POST_FEED_POST_SPAN_EDIT_POST',
	'POST_FEED_POST_SPAN_DELETE_POST' = 'POST_FEED_POST_SPAN_DELETE_POST',

	'POST_FEED_LOCKED_SPAN_SUBSCRIBE_FOR' = 'POST_FEED_LOCKED_SPAN_SUBSCRIBE_FOR',
	'POST_FEED_LOCKED_SPAN_FREE' = 'POST_FEED_LOCKED_SPAN_FREE',
	'POST_FEED_LOCKED_SPAN_UNLOCK' = 'POST_FEED_LOCKED_SPAN_UNLOCK',

	'READ_MORE_LESS_SPAN_SHOW_LESS' = 'READ_MORE_LESS_SPAN_SHOW_LESS',
	'READ_MORE_LESS_SPAN_SHOW_MORE' = 'READ_MORE_LESS_SPAN_SHOW_MORE',

	'RECOVER_PASSWORD_SPAN_PASSWORD_RECOVERY' = 'RECOVER_PASSWORD_SPAN_PASSWORD_RECOVERY',
	'RECOVER_PASSWORD_SPAN_PASS_RECOVERY_TEXT' = 'RECOVER_PASSWORD_SPAN_PASS_RECOVERY_TEXT',
	'RECOVER_PASSWORD_INPUT_EMAIL' = 'RECOVER_PASSWORD_INPUT_EMAIL',
	'RECOVER_PASSWORD_BUTTON_SUBMIT' = 'RECOVER_PASSWORD_BUTTON_SUBMIT',
	'RECOVER_PASSWORD_SPAN_NEW_PASSWORD' = 'RECOVER_PASSWORD_SPAN_NEW_PASSWORD',
	'RECOVER_PASSWORD_SPAN_NEW_PASSWORD_HINT' = 'RECOVER_PASSWORD_SPAN_NEW_PASSWORD_HINT',
	'RECOVER_PASSWORD_SPAN_CONFIRM_NEW_PASSWORD' = 'RECOVER_PASSWORD_SPAN_CONFIRM_NEW_PASSWORD',
	'RECOVER_PASSWORD_SPAN_PASSWORDS_DO_NOT_MATCH' = 'RECOVER_PASSWORD_SPAN_PASSWORDS_DO_NOT_MATCH',
	'RECOVER_PASSWORD_BUTTON_RECOVER' = 'RECOVER_PASSWORD_BUTTON_RECOVER',
	'RECOVER_PASSWORD_SPAN_YOUR_PASSWORD_HAS_BEEN' = 'RECOVER_PASSWORD_SPAN_YOUR_PASSWORD_HAS_BEEN',
	'RECOVER_PASSWORD_SPAN_SUCCESSFULLY_RECOVERED' = 'RECOVER_PASSWORD_SPAN_SUCCESSFULLY_RECOVERED',
	'RECOVER_PASSWORD_BUTTON_LETS_CONTINUE' = 'RECOVER_PASSWORD_BUTTON_LETS_CONTINUE',
	'RECOVER_PASSWORD_TOASTR_THE_CODE_HAS_BEEN_SENT_TO_THE_MAIL' = 'RECOVER_PASSWORD_TOASTR_THE_CODE_HAS_BEEN_SENT_TO_THE_MAIL',
	'RECOVER_PASSWORD_TOASTR_PASSWORD_CHANGED_SUCCESSFULLY' = 'RECOVER_PASSWORD_TOASTR_PASSWORD_CHANGED_SUCCESSFULLY',

	'SEARCH_PLACEHOLDER_SEARCH_USERS' = 'SEARCH_PLACEHOLDER_SEARCH_USERS',
	'SEARCH_PLACEHOLDER_SEARCH_TAG' = 'SEARCH_PLACEHOLDER_SEARCH_TAG',

	'SINGUP_TITLE_SIGNUP_CONTENT_TITLE' = 'SINGUP_TITLE_SIGNUP_CONTENT_TITLE',
	'SINGUP_TITLE_CREATE_YOUR_ACCOUNT' = 'SINGUP_TITLE_CREATE_YOUR_ACCOUNT',
	'SINGUP_INPUT_EMAIL' = 'SINGUP_INPUT_EMAIL',
	'SINGUP_INPUT_PASSWORD' = 'SINGUP_INPUT_PASSWORD',
	'SINGUP_SPAN_PASSWORD_HINT' = 'SINGUP_SPAN_PASSWORD_HINT',
	'SINGUP_INPUT_CONFIRM_YOUR_PASSWORD' = 'SINGUP_INPUT_CONFIRM_YOUR_PASSWORD',
	'SINGUP_BUTTON_NEXT' = 'SINGUP_BUTTON_NEXT',
	'SINGUP_SPAN_ALREADY_HAVE_AN_ACCOUNT' = 'SINGUP_SPAN_ALREADY_HAVE_AN_ACCOUNT',
	'SINGUP_BUTTON_LOG_IN' = 'SINGUP_BUTTON_LOG_IN',
	'SINGUP_SPAN_BY_SIGNING_UP_YOU_AGREE_TO_OUR' = 'SINGUP_SPAN_BY_SIGNING_UP_YOU_AGREE_TO_OUR',
	'SINGUP_SPAN_TERMS_OF_SERVICE' = 'SINGUP_SPAN_TERMS_OF_SERVICE',
	'SINGUP_SPAN_AND' = 'SINGUP_SPAN_AND',
	'SINGUP_SPAN_PRIVACY_POLICY' = 'SINGUP_SPAN_PRIVACY_POLICY',
	'SINGUP_SPAN_AND_CONFIRM_THAT_YOU_ARE_AT_LEAST_18_YEARS_OLD' = 'and_confirm_that_you_are_at_least_18_years_old',
	'SINGUP_TITLE_LOGIN_TO_YOUR_ACCOUNT' = 'SINGUP_TITLE_LOGIN_TO_YOUR_ACCOUNT',
	'SINGUP_BUTTON_FORGOT_YOUR_PASSWORD' = 'SINGUP_BUTTON_FORGOT_YOUR_PASSWORD',
	'SINGUP_SPAN_RECOVER' = 'SINGUP_SPAN_RECOVER',
	'SINGUP_BUTTON_DONT_HAVE_AN_ACCOUNT' = 'SINGUP_BUTTON_DONT_HAVE_AN_ACCOUNT',
	'SINGUP_BUTTON_REGISTER' = 'SINGUP_BUTTON_REGISTER',

	'SIX_DIGIT_CODE_INPUT_SPAN_ENTER_CODE' = 'SIX_DIGIT_CODE_INPUT_ENTER_CODE',
	'SIX_DIGIT_CODE_INPUT_SPAN_SEND_RECOVERY_CODE_TEXT' = 'SIX_DIGIT_CODE_INPUT_SPAN_SEND_RECOVERY_CODE_TEXT',
	'SIX_DIGIT_CODE_INPUT_BUTTON_NEXT' = 'SIX_DIGIT_CODE_INPUT_BUTTON_NEXT',

	'TAGS_FILTER_SPAN_TITTLE' = 'TAGS_FILTER_SPAN_TITTLE',
	'TAGS_FILTER_BUTTON_CLEAR_ALL' = 'TAGS_FILTER_BUTTON_CLEAR_ALL',
	'TAGS_FILTER_BUTTON_FILTER_TAGS' = 'TAGS_FILTER_BUTTON_FILTER_TAGS',
	'TAGS_FILTER_SPAN_MOST_POPULAR_TAGS' = 'TAGS_FILTER_SPAN_MOST_POPULAR_TAGS',
	'TAGS_FILTER_BUTTON_SAVE' = 'TAGS_FILTER_BUTTON_SAVE',

	'UNSUBSCRIBED_TEXT_YOU_HAVE_SUCCESSFULLY_UNSUBSCRIBED' = 'UNSUBSCRIBED_TEXT_YOU_HAVE_SUCCESSFULLY_UNSUBSCRIBED',

	'USER_POPUP_SPAN_FOLLOWERS' = 'USER_POPUP_SPAN_FOLLOWERS',
	'USER_POPUP_SPAN_FOLLOWINGS' = 'USER_POPUP_SPAN_FOLLOWINGS',
	'USER_POPUP_SPAN_MY_PROFILE' = 'USER_POPUP_SPAN_MY_PROFILE',
	'USER_POPUP_SPAN_MY_LISTS' = 'USER_POPUP_SPAN_MY_LISTS',
	'USER_POPUP_SPAN_MY_REFERRALS' = 'USER_POPUP_SPAN_MY_REFERRALS',
	'USER_POPUP_SPAN_SETTINGS' = 'USER_POPUP_SPAN_SETTINGS',
	'USER_POPUP_SPAN_HELP_AND_SUPPORT' = 'USER_POPUP_SPAN_HELP_AND_SUPPORT',
	'USER_POPUP_SPAN_LOG_OUT' = 'USER_POPUP_SPAN_LOG_OUT',

	'VOICE_RECORDER_SPAN_VOICE_ALERT' = 'VOICE_RECORDER_SPAN_VOICE_ALERT',
	'VOICE_RECORDER_TOASTR_VOICE_MIC_PERMISSION' = 'VOICE_RECORDER_TOASTR_VOICE_MIC_PERMISSION',

	'WELCOME_TEXT_CONGRATULATIONS' = 'WELCOME_TEXT_CONGRATULATIONS',
	'WELCOME_TEXT_YOURE_ON_THE_WAITLIST' = 'WELCOME_TEXT_YOURE_ON_THE_WAITLIST',
	'WELCOME_TEXT_TO_INCREASE_YOUR_CHANCES_OF_BECOMING_A_PART_OF' = 'WELCOME_TEXT_TO_INCREASE_YOUR_CHANCES_OF_BECOMING_A_PART_OF',
	'WELCOME_TEXT_REFER_A_FRIEND_WITH_YOUR_PERSONAL_CODE' = 'WELCOME_TEXT_REFER_A_FRIEND_WITH_YOUR_PERSONAL_CODE',
	'WELCOME_BUTTON_COPY_LINK' = 'WELCOME_BUTTON_COPY_LINK',

	'PROFILE_PROFILE_DONATE_SPAN_DONATE' = 'PROFILE_PROFILE_DONATE_SPAN_DONATE',
	'PROFILE_PROFILE_DONATE_SPAN_TIP_AMOUNT' = 'PROFILE_PROFILE_DONATE_SPAN_TIP_AMOUNT',
	'PROFILE_PROFILE_DONATE_SPAN_MINIMUM_3_USD_5_DELAWARE_VAT' = 'PROFILE_PROFILE_DONATE_SPAN_MINIMUM_3_USD_5_DELAWARE_VAT',

	'PROFILE_PROFILE_EDIT_SPAN_EDIT_PROFILE' = 'PROFILE_PROFILE_EDIT_SPAN_EDIT_PROFILE',

	'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_NO_SPECIFIC_REASON' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_NO_SPECIFIC_REASON',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_LOW_POSTING_FREQUENCY' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_LOW_POSTING_FREQUENCY',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_PRIVATE_MESSAGES_NOT_REPLIED' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_PRIVATE_MESSAGES_NOT_REPLIED',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_SUBSCRIPTION_PRICE_TOO_HIGH' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_SUBSCRIPTION_PRICE_TOO_HIGH',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_WILL_RE_SUBSCRIBE_LATER' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SELECT_WILL_RE_SUBSCRIBE_LATER',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FOLLOW' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FOLLOW',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_PER_MONTH' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_PER_MONTH',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_SEVENDAYFREETRIAL' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_SEVENDAYFREETRIAL',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FOLLOWED' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FOLLOWED',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FREE_TRIAL' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_FREE_TRIAL',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_MONTH' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_MONTH',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_COMMON_PRICE' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_COMMON_PRICE',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_THE_SUBSCRIPTION_ENDS' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_THE_SUBSCRIPTION_ENDS',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AFTER_THE_SEVEN_DAY_TRIAL_PERIOD_ENDS' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AFTER_THE_SEVEN_DAY_TRIAL_PERIOD_ENDS',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AUTOMATICRENEW' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AUTOMATICRENEW',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_REGULAR_PRICE' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_REGULAR_PRICE',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_UNLESS_CANCELED' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_UNLESS_CANCELED',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_CANCEL_SUBSCRIPTION' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_CANCEL_SUBSCRIPTION',
	'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AREUOYSURETOCANCEL' = 'PROFILE_PROFILE_FOLLOWING_OFFER_SPAN_AREUOYSURETOCANCEL',
	'PROFILE_PROFILE_FOLLOWING_OFFER_BUTTON_UNSUBSCRIBE' = 'PROFILE_PROFILE_FOLLOWING_OFFER_BUTTON_UNSUBSCRIBE',

	'PROFILE_PROFILE_SEND_MESSAGE_SPAN_MESSAGE' = 'PROFILE_PROFILE_SEND_MESSAGE_SPAN_MESSAGE',
	'PROFILE_PROFILE_SEND_MESSAGE_TOASTR_TO_SEND_A_MESSAGE_YOU_MUST_BE_SUBSCRIBED' = 'PROFILE_PROFILE_SEND_MESSAGE_TOASTR_TO_SEND_A_MESSAGE_YOU_MUST_BE_SUBSCRIBED',

	'PROFILE_PROFILE_STATS_SPAN_POSTS' = 'PROFILE_PROFILE_STATS_SPAN_POSTS',
	'PROFILE_PROFILE_STATS_SPAN_FOLLOWS' = 'PROFILE_PROFILE_STATS_SPAN_FOLLOWS',

	'AGE' = 'AGE',
	'GENDER' = 'GENDER',
	'HEIGHT' = 'HEIGHT',

	'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_H2_SEVENDAYFREETRIAL' = 'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_H2_SEVENDAYFREETRIAL',
	'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_SPAN_SEVENDAYTRIALTEXT' = 'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_SPAN_SEVENDAYTRIALTEXT',
	'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_BUTTON_ACTIVATETRIALTEXT' = 'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_BUTTON_ACTIVATETRIALTEXT',
	'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_SPAN_TRIAL_FOOTER' = 'PROFILE_PROFILE_TRIAL_FOLLOWING_OFFER_SPAN_TRIAL_FOOTER',

	'ONBOARDING_TAGS' = 'ONBOARDING_TAGS',
	'ONBOARDING_WHAT_YOUR_BODY_TYPE' = 'ONBOARDING_WHAT_YOUR_BODY_TYPE',
	'ONBOARDING_WHAT_YOUR_HAIR_COLOR' = 'ONBOARDING_WHAT_YOUR_HAIR_COLOR',
	'ONBOARDING_WHAT_YOUR_EYE_COLOR' = 'ONBOARDING_WHAT_YOUR_EYE_COLOR',
	'ONBOARDING_SKIN_TONE' = 'ONBOARDING_SKIN_TONE',
	'ONBOARDING_HAIR_LENGTH' = 'ONBOARDING_HAIR_LENGTH',
	'ONBOARDING_HAIR_STYLE' = 'ONBOARDING_HAIR_STYLE',
	'ONBOARDING_PENIS' = 'ONBOARDING_PENIS',
	'ONBOARDING_BREASTS' = 'ONBOARDING_BREASTS',
	'ONBOARDING_BUTT' = 'ONBOARDING_BUTT',
	'ONBOARDING_GENDER' = 'ONBOARDING_GENDER',

	'ONBOARDING_SFW' = 'ONBOARDING_SFW',
	'ONBOARDING_NSFW' = 'ONBOARDING_NSFW',
	'ONBOARDING_SFW_COSPLAY' = 'ONBOARDING_SFW_COSPLAY',
	'ONBOARDING_SFW_ASMR' = 'ONBOARDING_SFW_ASMR',
	'ONBOARDING_SFW_SPORTS' = 'ONBOARDING_SFW_SPORTS',
	'ONBOARDING_SFW_VLOGS' = 'ONBOARDING_SFW_VLOGS',
	'ONBOARDING_SFW_DANCING' = 'ONBOARDING_SFW_DANCING',
	'ONBOARDING_SFW_COOKING_SHOWS' = 'ONBOARDING_SFW_COOKING_SHOWS',
	'ONBOARDING_SFW_FEET' = 'ONBOARDING_SFW_FEET',
	'ONBOARDING_SFW_GAMING' = 'ONBOARDING_SFW_GAMING',
	'ONBOARDING_SFW_HORSES' = 'ONBOARDING_SFW_HORSES',
	'ONBOARDING_SFW_RACING' = 'ONBOARDING_SFW_RACING',
	'ONBOARDING_NSFW_COSPLAY' = 'ONBOARDING_NSFW_COSPLAY',
	'ONBOARDING_NSFW_DIRTY_TALK' = 'ONBOARDING_NSFW_DIRTY_TALK',
	'ONBOARDING_NSFW_ANAL' = 'ONBOARDING_NSFW_ANAL',
	'ONBOARDING_NSFW_TOYS' = 'ONBOARDING_NSFW_TOYS',
	'ONBOARDING_NSFW_ROLE_PLAY' = 'ONBOARDING_NSFW_ROLE_PLAY',
	'ONBOARDING_NSFW_BDSM' = 'ONBOARDING_NSFW_BDSM',
	'ONBOARDING_NSFW_LATEX' = 'ONBOARDING_NSFW_LATEX',
	'ONBOARDING_NSFW_GAGGING' = 'ONBOARDING_NSFW_GAGGING',
	'ONBOARDING_NSFW_EXHIBITIONISM' = 'ONBOARDING_NSFW_EXHIBITIONISM',
	'ONBOARDING_NSFW_SOLO' = 'ONBOARDING_NSFW_SOLO',
	'ONBOARDING_NSFW_COUPLES' = 'ONBOARDING_NSFW_COUPLES',
	'ONBOARDING_NSFW_DOUBLE' = 'ONBOARDING_NSFW_DOUBLE',
	'ONBOARDING_NSFW_GROUP' = 'ONBOARDING_NSFW_GROUP',
	'ONBOARDING_NSFW_FMM' = 'ONBOARDING_NSFW_FMM',
	'ONBOARDING_NSFW_FFM' = 'ONBOARDING_NSFW_FFM',
	'ONBOARDING_NSFW_VIDEO_GAMES' = 'ONBOARDING_NSFW_VIDEO_GAMES',

	'ONBOARDING_HAIR_COLORS_BLACK' = 'ONBOARDING_HAIR_COLORS_BLACK',
	'ONBOARDING_HAIR_COLORS_BROWN' = 'ONBOARDING_HAIR_COLORS_BROWN',
	'ONBOARDING_HAIR_COLORS_BLONDE' = 'ONBOARDING_HAIR_COLORS_BLONDE',
	'ONBOARDING_HAIR_COLORS_RED' = 'ONBOARDING_HAIR_COLORS_RED',
	'ONBOARDING_HAIR_COLORS_WHITE' = 'ONBOARDING_HAIR_COLORS_WHITE',
	'ONBOARDING_HAIR_COLORS_BLUE' = 'ONBOARDING_HAIR_COLORS_BLUE',
	'ONBOARDING_HAIR_COLORS_GREEN' = 'ONBOARDING_HAIR_COLORS_GREEN',
	'ONBOARDING_HAIR_COLORS_PINK' = 'ONBOARDING_HAIR_COLORS_PINK',
	'ONBOARDING_HAIR_COLORS_PURPLE' = 'ONBOARDING_HAIR_COLORS_PURPLE',
	'ONBOARDING_HAIR_COLORS_SILVER' = 'ONBOARDING_HAIR_COLORS_SILVER',
	'ONBOARDING_HAIR_COLORS_PASTEL' = 'ONBOARDING_HAIR_COLORS_PASTEL',
	'ONBOARDING_HAIR_COLORS_OMBRE' = 'ONBOARDING_HAIR_COLORS_OMBRE',
	'ONBOARDING_HAIR_COLORS_RAINBOW' = 'ONBOARDING_HAIR_COLORS_RAINBOW',

	'ONBOARDING_EYE_COLORS_BROWN' = 'ONBOARDING_EYE_COLORS_BROWN',
	'ONBOARDING_EYE_COLORS_BLUE' = '"ONBOARDING_EYE_COLORS_BLUE"',
	'ONBOARDING_EYE_COLORS_GREEN' = 'ONBOARDING_EYE_COLORS_GREEN',
	'ONBOARDING_EYE_COLORS_HAZEL' = 'ONBOARDING_EYE_COLORS_HAZEL',
	'ONBOARDING_EYE_COLORS_AMBER' = 'ONBOARDING_EYE_COLORS_AMBER',
	'ONBOARDING_EYE_COLORS_GRAY' = 'ONBOARDING_EYE_COLORS_GRAY',
	'ONBOARDING_EYE_COLORS_COMBINE' = 'ONBOARDING_EYE_COLORS_COMBINE',

	'ONBOARDING_BODYTYPES_SLIM' = 'ONBOARDING_BODYTYPES_SLIM',
	'ONBOARDING_BODYTYPES_MUSCULAR' = 'ONBOARDING_BODYTYPES_MUSCULAR',
	'ONBOARDING_BODYTYPES_AVERAGE' = 'ONBOARDING_BODYTYPES_AVERAGE',
	'ONBOARDING_BODYTYPES_CURVY' = 'ONBOARDING_BODYTYPES_CURVY',
	'ONBOARDING_BODYTYPES_FULL_FIGURED' = 'ONBOARDING_BODYTYPES_FULL_FIGURED',
	'ONBOARDING_BODYTYPES_PETITE' = 'ONBOARDING_BODYTYPES_PETITE',

	'ONBOARDING_SKIN_TONE_FAIR' = 'ONBOARDING_SKIN_TONE_FAIR',
	'ONBOARDING_SKIN_TONE_LIGHT' = 'ONBOARDING_SKIN_TONE_LIGHT',
	'ONBOARDING_SKIN_TONE_MEDIUM' = 'ONBOARDING_SKIN_TONE_MEDIUM',
	'ONBOARDING_SKIN_TONE_OLIVE' = 'ONBOARDING_SKIN_TONE_OLIVE',
	'ONBOARDING_SKIN_TONE_TAN' = 'ONBOARDING_SKIN_TONE_TAN',
	'ONBOARDING_SKIN_TONE_BROWN' = 'ONBOARDING_SKIN_TONE_BROWN',
	'ONBOARDING_SKIN_TONE_DARK' = 'ONBOARDING_SKIN_TONE_DARK',

	'ONBOARDING_HAIR_LENGTH_BALD' = 'ONBOARDING_HAIR_LENGTH_BALD',
	'ONBOARDING_HAIR_LENGTH_BUZZ_CUT' = 'ONBOARDING_HAIR_LENGTH_BUZZ_CUT',
	'ONBOARDING_HAIR_LENGTH_SHORT' = 'ONBOARDING_HAIR_LENGTH_SHORT',
	'ONBOARDING_HAIR_LENGTH_MEDIUM' = 'ONBOARDING_HAIR_LENGTH_MEDIUM',
	'ONBOARDING_HAIR_LENGTH_LONG' = 'ONBOARDING_HAIR_LENGTH_LONG',
	'ONBOARDING_HAIR_LENGTH_EXTRA_LONG' = 'ONBOARDING_HAIR_LENGTH_EXTRA_LONG',

	'ONBOARDING_HAIR_STYLE_STRAIGHT' = 'ONBOARDING_HAIR_STYLE_STRAIGHT',
	'ONBOARDING_HAIR_STYLE_WAVY' = 'ONBOARDING_HAIR_STYLE_WAVY',
	'ONBOARDING_HAIR_STYLE_CURLY' = 'ONBOARDING_HAIR_STYLE_CURLY',
	'ONBOARDING_HAIR_STYLE_COILED_KINKY' = 'ONBOARDING_HAIR_STYLE_COILED_KINKY',
	'ONBOARDING_HAIR_STYLE_PIXIE' = 'ONBOARDING_HAIR_STYLE_PIXIE',
	'ONBOARDING_HAIR_STYLE_CUT_BOB' = 'ONBOARDING_HAIR_STYLE_CUT_BOB',
	'ONBOARDING_HAIR_STYLE_CUT_LOB' = 'ONBOARDING_HAIR_STYLE_CUT_LOB',
	'ONBOARDING_HAIR_STYLE_LAYERED' = 'ONBOARDING_HAIR_STYLE_LAYERED',
	'ONBOARDING_HAIR_STYLE_SHAG' = 'ONBOARDING_HAIR_STYLE_SHAG',
	'ONBOARDING_HAIR_STYLE_BANGS_FRINGE' = 'ONBOARDING_HAIR_STYLE_BANGS_FRINGE',
	'ONBOARDING_HAIR_STYLE_PONYTAIL' = 'ONBOARDING_HAIR_STYLE_PONYTAIL',
	'ONBOARDING_HAIR_STYLE_BUN' = 'ONBOARDING_HAIR_STYLE_BUN',
	'ONBOARDING_HAIR_STYLE_BRAIDS' = 'ONBOARDING_HAIR_STYLE_BRAIDS',
	'ONBOARDING_HAIR_STYLE_CORNROWS' = 'ONBOARDING_HAIR_STYLE_CORNROWS',
	'ONBOARDING_HAIR_STYLE_DREADLOCKS' = 'ONBOARDING_HAIR_STYLE_DREADLOCKS',
	'ONBOARDING_HAIR_STYLE_MOHAWK' = 'ONBOARDING_HAIR_STYLE_MOHAWK',
	'ONBOARDING_HAIR_STYLE_UNDERCUT' = 'ONBOARDING_HAIR_STYLE_UNDERCUT',
	'ONBOARDING_HAIR_STYLE_FADE' = 'ONBOARDING_HAIR_STYLE_FADE',
	'ONBOARDING_HAIR_STYLE_UPDO' = 'ONBOARDING_HAIR_STYLE_UPDO',

	'ONBOARDING_GENDER_MALE' = 'ONBOARDING_GENDER_MALE',
	'ONBOARDING_GENDER_FEMALE' = 'ONBOARDING_GENDER_FEMALE',

	'ONBOARDING_BUTT_SIZE_LARGE' = 'ONBOARDING_BUTT_SIZE_LARGE',
	'ONBOARDING_BUTT_SIZE_MEDIUM' = 'ONBOARDING_BUTT_SIZE_MEDIUM',
	'ONBOARDING_BUTT_SIZE_SMALL' = 'ONBOARDING_BUTT_SIZE_SMALL',

	'ONBOARDING_BREAST_SIZE_LARGE' = 'ONBOARDING_BREAST_SIZE_LARGE',
	'ONBOARDING_BREAST_SIZE_MEDIUM' = 'ONBOARDING_BREAST_SIZE_MEDIUM',
	'ONBOARDING_BREAST_SIZE_SMALL' = 'ONBOARDING_BREAST_SIZE_SMALL',

	'ONBOARDING_PENIS_SIZE_LARGE' = 'ONBOARDING_PENIS_SIZE_LARGE',
	'ONBOARDING_PENIS_SIZE_MEDIUM' = 'ONBOARDING_PENIS_SIZE_MEDIUM',
	'ONBOARDING_PENIS_SIZE_SMALL' = 'ONBOARDING_PENIS_SIZE_SMALL',

	'AGENCY_ONBOARDING_BODY_OPTIONS_H3_SOMETHING_INTERESTING' = 'AGENCY_ONBOARDING_BODY_OPTIONS_H3_SOMETHING_INTERESTING',
	'AGENCY_ONBOARDING_BODY_OPTIONS_H3_TATTOOS' = 'AGENCY_ONBOARDING_BODY_OPTIONS_H3_TATTOOS',
	'AGENCY_ONBOARDING_BODY_OPTIONS_H3_PIERCING' = 'AGENCY_ONBOARDING_BODY_OPTIONS_H3_PIERCING',

	'AGENCY_ONBOARDING_COMPLETE_SPAN_QUIZ_END_TITLE' = 'AGENCY_ONBOARDING_COMPLETE_SPAN_QUIZ_END_TITLE',
	'AGENCY_ONBOARDING_COMPLETE_SPAN_QUIZ_END_TEXT' = 'AGENCY_ONBOARDING_COMPLETE_SPAN_QUIZ_END_TEXT',
	'AGENCY_ONBOARDING_COMPLETE_BUTTON_RETURN_TO_HOME' = 'AGENCY_ONBOARDING_COMPLETE_BUTTON_RETURN_TO_HOME',

	'AGENCY_ONBOARDING_CREATOR_ANSWERS_SPAN_ANSWER_THE_QUESTIONS' = 'AGENCY_ONBOARDING_CREATOR_ANSWERS_SPAN_ANSWER_THE_QUESTIONS',

	'AGENCY_ONBOARDING_CREATOR_CONTENT_TYPE_SPAN_YOUR_CONTENT' = 'AGENCY_ONBOARDING_CREATOR_CONTENT_TYPE_SPAN_YOUR_CONTENT',

	'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_WHERE_ARE_YOU_FROM' = 'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_WHERE_ARE_YOU_FROM',
	'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_WHERE_DO_YOU_LIVE' = 'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_WHERE_DO_YOU_LIVE',
	'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_COUNTRY' = 'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_COUNTRY',
	'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_CITY' = 'AGENCY_ONBOARDING_CREATOR_LOCATION_SPAN_CITY',

	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_WHO_ARE_YOU_TITLE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_WHO_ARE_YOU_TITLE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_COLOR' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_COLOR',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_EYE_COLOR' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_EYE_COLOR',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HEIGHT' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HEIGHT',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_FEET_INCH' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_FEET_INCH',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_OPTIONAL_FIELDS' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_OPTIONAL_FIELDS',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_FILTER_HIGH_RECOMMEND' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_FILTER_HIGH_RECOMMEND',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_TATTOOS' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_TATTOOS',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_PIERCING' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_PIERCING',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_BODY_TYPE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_BODY_TYPE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_SKIN_TONE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_SKIN_TONE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_LENGTH' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_LENGTH',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_STYLE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SPAN_HAIR_STYLE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_BLOND' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_BLOND',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_PINK' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_PINK',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_DARK' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRCOLORSLIST_DARK',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_BLACK' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_BLACK',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_BLUE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_BLUE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_GREEN' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_EYECOLORSLIST_GREEN',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_SLIM' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_SLIM',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_NORMAL' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_NORMAL',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_PLUS_SIZE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_BODYTYPELIST_PLUS_SIZE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_SKINTONELIST_BLACK' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_SKINTONELIST_BLACK',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_SKINTONELIST_WHITE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_SKINTONELIST_WHITE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_SHORT' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_SHORT',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_MIDDLE' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_MIDDLE',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_LONG' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_LONG',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_NO_HAIR' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRLENGTHLIST_NO_HAIR',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_STRAIGHT' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_STRAIGHT',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_WAVY' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_WAVY',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_CURLY' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_CURLY',
	'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_COILY' = 'AGENCY_ONBOARDING_CREATOR_PERSONAL_DATA_SELECT_HAIRSTYLELIST_COILY',

	'AGENCY_ONBOARDING_CREATOR_SELECT_ANSWERS_SPAN_SELECT' = 'AGENCY_ONBOARDING_CREATOR_SELECT_ANSWERS_SPAN_SELECT',
	'AGENCY_ONBOARDING_CREATOR_SELECT_ANSWERS_SPAN_CREATOR_SELECT_TEXT' = 'AGENCY_ONBOARDING_CREATOR_SELECT_ANSWERS_SPAN_CREATOR_SELECT_TEXT',

	'AGENCY_ONBOARDING_HEIGHT_H3_YOUR_HEIGHT' = 'AGENCY_ONBOARDING_HEIGHT_H3_YOUR_HEIGHT',
	'AGENCY_ONBOARDING_HEIGHT_PLACEHOLDER_TYPE_HEIGHT' = 'AGENCY_ONBOARDING_HEIGHT_PLACEHOLDER_TYPE_HEIGHT',

	'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_THANK_YOUR_FOR_YOUR' = 'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_THANK_YOUR_FOR_YOUR',
	'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_INTEREST' = 'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_INTEREST',
	'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_FILL_INFO_ABOUT' = 'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_FILL_INFO_ABOUT',
	'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_LETS_CONTINUE' = 'AGENCY_ONBOARDING_INVITATION_SCREEN_SPAN_LETS_CONTINUE',

	'AGENCY_ONBOARDING_QUESTION_ITEM_PLACEHOLDER_TYPE_YOUR_ANSWER' = 'AGENCY_ONBOARDING_QUESTION_ITEM_PLACEHOLDER_TYPE_YOUR_ANSWER',

	'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_ABOUT_ME' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_ABOUT_ME',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_GETTING_PERSONAL' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_GETTING_PERSONAL',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_MY_TYPE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GENERAL_MY_TYPE',

	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_HOBBIES' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_HOBBIES',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_ENJOY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_ENJOY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_FAVORITE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_FAVORITE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_PETS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_PETS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_SURPRISE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_SURPRISE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_MUSIC' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_MUSIC',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_TRAVEL' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_TRAVEL',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_GOALS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_GOALS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_RELATIONSHIP' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_RELATIONSHIP',
	'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_HABITS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_ABOUT_ME_HABITS',

	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_SEXUALLY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_SEXUALLY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_INTIMACY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_INTIMACY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_CONFIDENCE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_CONFIDENCE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_HAIRSTYLE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_HAIRSTYLE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_FEATURE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_FEATURE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_TATTOOS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_TATTOOS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_LINGERIE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_LINGERIE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_DOMINANT_SUBMISSIVE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_DOMINANT_SUBMISSIVE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPERIENCE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPERIENCE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_TRAITS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_TRAITS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPERIENCED' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPERIENCED',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPRESSIVE_RESERVED' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_EXPRESSIVE_RESERVED',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_FETISHES' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_FETISHES',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ENERGY_STAMINA' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ENERGY_STAMINA',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_CONTROL' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_CONTROL',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ADVENTUROUS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ADVENTUROUS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ROLEPLAY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_ROLEPLAY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_GENTLE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_GENTLE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_DEALBREAKER' = 'AGENCY_ONBOARDING_QUESTION_ITEM_MY_TYPE_DEALBREAKER',

	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_THOUGHTS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_THOUGHTS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_FANTASY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_FANTASY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_ROLEPLAY' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_ROLEPLAY',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_ADVENTUROUS_PLACE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_ADVENTUROUS_PLACE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_FETISHES' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_FETISHES',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_LINGERIE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_LINGERIE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DOMINANT_OR_SUBMISSIVE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DOMINANT_OR_SUBMISSIVE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_BDSM' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_BDSM',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_BIGGEST_TURN_ON' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_BIGGEST_TURN_ON',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_CUSTOM_CONTENT' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_CUSTOM_CONTENT',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_NAUGHTIEST' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_NAUGHTIEST',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_TOYS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_TOYS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_PERFECT_INTIMATE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_PERFECT_INTIMATE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_TEASE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_TEASE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DIFFERENT_PERSONAS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DIFFERENT_PERSONAS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DARING_OUTFIT' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DARING_OUTFIT',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_SECRET_FANTASIE' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_SECRET_FANTASIE',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DIRTY_TALK' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_DIRTY_TALK',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_STARS' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_STARS',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_VOYEURISM' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_VOYEURISM',
	'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_INTIMATE_REQUEST' = 'AGENCY_ONBOARDING_QUESTION_ITEM_GETTING_PERSONAL_INTIMATE_REQUEST',

	'CONTENT_ADD_MEDIA_SPAN_ADD_MEDIA' = 'CONTENT_ADD_MEDIA_SPAN_ADD_MEDIA',
	'CONTENT_ADD_MEDIA_TOASTR_PROCESSING' = 'CONTENT_ADD_MEDIA_TOASTR_PROCESSING',
	'CONTENT_ADD_MEDIA_TOASTR_FILES' = 'CONTENT_ADD_MEDIA_TOASTR_FILES',
	'CONTENT_ADD_MEDIA_TOASTR_UPLOADING' = 'CONTENT_ADD_MEDIA_TOASTR_UPLOADING',
	'CONTENT_ADD_MEDIA_TOASTR_UPLOADED' = 'CONTENT_ADD_MEDIA_TOASTR_UPLOADED',

	'CONTENT_CONTENT_SPAN_CONTENT_HUB' = 'CONTENT_CONTENT_SPAN_CONTENT_HUB',
	'CONTENT_CONTENT_HEADER_CREATE_NEW_LIST' = 'CONTENT_CONTENT_HEADER_CREATE_NEW_LIST',
	'CONTENT_CONTENT_LABEL_LIST_NAME' = 'CONTENT_CONTENT_LABEL_LIST_NAME',
	'CONTENT_CONTENT_BUTTON_CREATE' = 'CONTENT_CONTENT_BUTTON_CREATE',

	'CONTENT_CONTENT_FOLDER_DIV_SELECTED' = 'CONTENT_CONTENT_FOLDER_DIV_SELECTED',

	'CONTENT_CONTENT_SELECTOR_HEADER_CONTENT_HUB' = 'CONTENT_CONTENT_SELECTOR_HEADER_CONTENT_HUB',
	'CONTENT_CONTENT_SELECTOR_HEADER_ALL_MEDIA' = 'CONTENT_CONTENT_SELECTOR_HEADER_ALL_MEDIA',
	'CONTENT_CONTENT_SELECTOR_DIV_SELECTED' = 'CONTENT_CONTENT_SELECTOR_DIV_SELECTED',
	'CONTENT_CONTENT_SELECTOR_BUTTON_SELECT' = 'CONTENT_CONTENT_SELECTOR_BUTTON_SELECT',
	'CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL' = 'CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL',
	'CONTENT_CONTENT_SELECTOR_HEADER_CREATE_NEW_LIST' = 'CONTENT_CONTENT_SELECTOR_HEADER_CREATE_NEW_LIST',
	'CONTENT_CONTENT_SELECTOR_BUTTON_CREATE' = 'CONTENT_CONTENT_SELECTOR_BUTTON_CREATE',

	'CONTENT_FILE_FILTER_BUTTON_ALL' = 'CONTENT_FILE_FILTER_BUTTON_ALL',
	'CONTENT_FILE_FILTER_BUTTON_PHOTO' = 'CONTENT_FILE_FILTER_BUTTON_PHOTO',
	'CONTENT_FILE_FILTER_BUTTON_VIDEOS' = 'CONTENT_FILE_FILTER_BUTTON_VIDEOS',
	'CONTENT_BUTTON_SELECT_ANOTHER_VIDEOS' = 'CONTENT_BUTTON_SELECT_ANOTHER_VIDEOS',

	'CONTENT_FOLDERS_LIST_BUTTON_DELETE' = 'CONTENT_FOLDERS_LIST_BUTTON_DELETE',

	'MESSAGES_CHAT_BUTTON_YOU_DISCOVER_SCHEDULED_MESSAGES_LIST' = 'MESSAGES_CHAT_BUTTON_YOU_DISCOVER_SCHEDULED_MESSAGES_LIST',
	'MESSAGES_CHAT_BUTTON_BACK_TO_CHAT' = 'MESSAGES_CHAT_BUTTON_BACK_TO_CHAT',
	'MESSAGES_CHAT_FOOTER_TYPE_A_MESSAGE' = 'MESSAGES_CHAT_FOOTER_TYPE_A_MESSAGE',
	'MESSAGES_CHAT_TEXT_AVAILABLE_NOW' = 'MESSAGES_CHAT_TEXT_AVAILABLE_NOW',
	'MESSAGES_CHAT_TEXT_WAS_ONLINE_FOR_A_LONG_TIME' = 'MESSAGES_CHAT_TEXT_WAS_ONLINE_FOR_A_LONG_TIME',
	'MESSAGES_CHAT_TEXT_RECENTLY_ONLINE' = 'MESSAGES_CHAT_TEXT_RECENTLY_ONLINE',
	'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_FEW_DAYS_AGO' = 'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_FEW_DAYS_AGO',
	'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_COUPLE_OF_HOURS_AGO' = 'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_COUPLE_OF_HOURS_AGO',
	'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_COUPLE_OF_MINUTES_AGO' = 'MESSAGES_CHAT_TEXT_WAS_ONLINE_A_COUPLE_OF_MINUTES_AGO',

	'MESSAGES_CHAT_CHAT_LIST_ITEM_TEXT_EMPTY_CHAT' = 'MESSAGES_CHAT_CHAT_LIST_ITEM_TEXT_EMPTY_CHAT',
	'MESSAGES_CHAT_CHAT_LIST_ITEM_TEXT_MEDIA_ATTACHMENT' = 'MESSAGES_CHAT_CHAT_LIST_ITEM_TEXT_MEDIA_ATTACHMENT',

	'MESSAGES_CHAT_MESSAGE_DROPDOWN_DELETE_MESSAGE' = 'MESSAGES_CHAT_MESSAGE_DROPDOWN_DELETE_MESSAGE',
	'MESSAGES_CHAT_MESSAGE_DROPDOWN_EDIT_MESSAGE' = 'MESSAGES_CHAT_MESSAGE_DROPDOWN_EDIT_MESSAGE',

	'MESSAGES_MESSAGE_LIST_ITEM_SPAN_TOTAL_DURATION' = 'MESSAGES_MESSAGE_LIST_ITEM_SPAN_TOTAL_DURATION',
	'MESSAGES_MESSAGE_LIST_ITEM_SPAN_MONTH' = 'MESSAGES_MESSAGE_LIST_ITEM_SPAN_MONTH',
	'MESSAGES_MESSAGE_LIST_ITEM_SPAN_EXPIRED' = 'MESSAGES_MESSAGE_LIST_ITEM_SPAN_EXPIRED',
	'MESSAGES_MESSAGE_LIST_ITEM_SPAN_SPENT' = 'MESSAGES_MESSAGE_LIST_ITEM_SPAN_SPENT',

	'MESSAGES_ADD_USER_H3_ADD_USERS_TO' = 'MESSAGES_ADD_USER_H3_ADD_USERS_TO',
	'MESSAGES_ADD_USER_SPAN_SELECTED' = 'MESSAGES_ADD_USER_SPAN_SELECTED',
	'MESSAGES_ADD_USER_BUTTON_ADD' = 'MESSAGES_ADD_USER_BUTTON_ADD',

	'MESSAGES_ADD_LIST_DIALOG_H3_CREATE_NEW_LIST' = 'MESSAGES_ADD_LIST_DIALOG_H3_CREATE_NEW_LIST',
	'MESSAGES_ADD_LIST_DIALOG_LABEL_LIST_NAME' = 'MESSAGES_ADD_LIST_DIALOG_LABEL_LIST_NAME',
	'MESSAGES_ADD_LIST_DIALOG_BUTTON_CREATE' = 'MESSAGES_ADD_LIST_DIALOG_BUTTON_CREATE',

	'MESSAGES_MESSAGE_LIST_TEXT_LISTS' = 'MESSAGES_MESSAGE_LIST_TEXT_LISTS',

	'MESSAGES_MESSAGE_PRICE_BUTTON_UNLOCK' = 'MESSAGES_MESSAGE_PRICE_BUTTON_UNLOCK',

	'MESSAGES_MESSAGES_H3_EMPTY_CHAT_TITLE' = 'MESSAGES_MESSAGES_H3_EMPTY_CHAT_TITLE',
	'MESSAGES_MESSAGES_BUTTON_NEW_MESSAGE' = 'MESSAGES_MESSAGES_BUTTON_NEW_MESSAGE',
	'MESSAGES_MESSAGES_H2_NO_CHATS_YET' = 'MESSAGES_MESSAGES_H2_NO_CHATS_YET',
	'MESSAGES_MESSAGES_SPAN_MESSAGES' = 'MESSAGES_MESSAGES_SPAN_MESSAGES',
	'MESSAGES_MESSAGES_BUTTON_MESSAGES_FILTER_ALL' = 'MESSAGES_MESSAGES_BUTTON_MESSAGES_FILTER_ALL',
	'MESSAGES_MESSAGES_BUTTON_MESSAGES_FILTER_UNREAD' = 'MESSAGES_MESSAGES_BUTTON_MESSAGES_FILTER_UNREAD',
	'MESSAGES_MESSAGES_TOASRT_FOLLOW_ANY_CREATOR_TO_START_CONVERSATION_WITH' = 'MESSAGES_MESSAGES_TOASRT_FOLLOW_ANY_CREATOR_TO_START_CONVERSATION_WITH',
	'MESSAGES_MESSAGES_TOASTR_NO_SUBSCRIBES' = 'MESSAGES_MESSAGES_TOASTR_NO_SUBSCRIBES',

	'MY_PROFILE_BECOME_A_CREATOR_TITLE_CREATOR_REGISTRATION' = 'MY_PROFILE_BECOME_A_CREATOR_TITLE_CREATOR_REGISTRATION',
	'MY_PROFILE_BECOME_A_CREATOR_TITLE_VERIFICATION' = 'MY_PROFILE_BECOME_A_CREATOR_TITLE_VERIFICATION',
	'MY_PROFILE_BECOME_A_CREATOR_TITLE_IMPORTANT_INFORMATION' = 'MY_PROFILE_BECOME_A_CREATOR_TITLE_IMPORTANT_INFORMATION',
	'MY_PROFILE_BECOME_A_CREATOR_TITLE_IDENTITY_VERIFICATION' = 'MY_PROFILE_BECOME_A_CREATOR_TITLE_IDENTITY_VERIFICATION',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_BOOST' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_BOOST',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_YOUR_SUCCESS' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_YOUR_SUCCESS',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_WITH_DESIREN_AGENCY' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_WITH_DESIREN_AGENCY',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_AGENCYTEXTINVITE' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_AGENCYTEXTINVITE',
	'MY_PROFILE_BECOME_A_CREATOR_BUTTON_WANTTOTRY' = 'MY_PROFILE_BECOME_A_CREATOR__BUTTON_WANTTOTRY',
	'MY_PROFILE_BECOME_A_CREATOR_BUTTON_CONTINUEWITHOUTAGENCY' = 'MY_PROFILE_BECOME_A_CREATOR_BUTTON_CONTINUEWITHOUTAGENCY',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_RECOMMENDED_BANNER_SIZE' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_RECOMMENDED_BANNER_SIZE',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_BIO' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_BIO',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_MINIMUM_50_SYMBOLS' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_MINIMUM_50_SYMBOLS',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_COUNTRY' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_COUNTRY',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_YEARS18CONFIRM' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_YEARS18CONFIRM',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_USEMOBILECAPTION' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_USEMOBILECAPTION',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_COMPLETEVERIFICATION' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_COMPLETEVERIFICATION',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_OVERVIEW' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_OVERVIEW',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_AGREEMENTTEXT' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_AGREEMENTTEXT',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_DISAGREE' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_DISAGREE',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_I_AGREE' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_I_AGREE',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_QR_TITLE' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_QR_TITLE',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_QR_TEXT' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_QR_TEXT',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_SCANQRTEXT' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_SCANQRTEXT',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_OPENLINKBTNTEXT' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_OPENLINKBTNTEXT',
	'MY_PROFILE_BECOME_A_CREATOR_SPAN_CANCEL' = 'MY_PROFILE_BECOME_A_CREATOR_SPAN_CANCEL',

	'NOTIFICATION_NOTIFICATION_SPAN_SENT_YOU_A_TIP' = 'NOTIFICATION_NOTIFICATION_SPAN_SENT_YOU_A_TIP',
	'NOTIFICATION_NOTIFICATION_SPAN_HAS_BOUGHT_YOUR_MESSAGE_CONTENT_FOR' = 'NOTIFICATION_NOTIFICATION_SPAN_HAS_BOUGHT_YOUR_MESSAGE_CONTENT_FOR',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_AUDIO_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_AUDIO_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_DOCUMENT_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_DOCUMENT_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_YOU_HAVE' = 'NOTIFICATION_NOTIFICATION_SPAN_YOU_HAVE',
	'NOTIFICATION_NOTIFICATION_SPAN_UNREAD_MESSAGES' = 'NOTIFICATION_NOTIFICATION_SPAN_UNREAD_MESSAGES',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_MULTIPART_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_MULTIPART_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_PHOTO_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_PHOTO_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_POSTED_A_NEW' = 'NOTIFICATION_NOTIFICATION_SPAN_POSTED_A_NEW',
	'NOTIFICATION_NOTIFICATION_SPAN_POST' = 'NOTIFICATION_NOTIFICATION_SPAN_POST',
	'NOTIFICATION_NOTIFICATION_SPAN_POSTED_A_NEW_COMMENT_ON' = 'NOTIFICATION_NOTIFICATION_SPAN_POSTED_A_NEW_COMMENT_ON',
	'NOTIFICATION_NOTIFICATION_SPAN_YOUR_POST' = 'NOTIFICATION_NOTIFICATION_SPAN_YOUR_POST',
	'NOTIFICATION_NOTIFICATION_SPAN_SUBSCRIBED_TO_YOUR_PROFILE' = 'NOTIFICATION_NOTIFICATION_SPAN_SUBSCRIBED_TO_YOUR_PROFILE',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_VIDEO_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_VIDEO_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_NEW_VOICE_MESSAGE' = 'NOTIFICATION_NOTIFICATION_SPAN_NEW_VOICE_MESSAGE',
	'NOTIFICATION_NOTIFICATION_SPAN_HAS_BOUGHT_ACCESS_TO_YOUR' = 'NOTIFICATION_NOTIFICATION_SPAN_HAS_BOUGHT_ACCESS_TO_YOUR',
	'NOTIFICATION_NOTIFICATION_SPAN_FOR' = 'NOTIFICATION_NOTIFICATION_SPAN_FOR',
	'NOTIFICATION_NOTIFICATION_SPAN_FROM' = 'NOTIFICATION_NOTIFICATION_SPAN_FROM',
	'NOTIFICATION_NOTIFICATION_SPAN_TO' = 'NOTIFICATION_NOTIFICATION_SPAN_TO',
	'NOTIFICATION_NOTIFICATION_SPAN_YOUR_CREATORS_PROFILE_APPROVED' = 'NOTIFICATION_NOTIFICATION_SPAN_YOUR_CREATORS_PROFILE_APPROVED',
	'NOTIFICATION_NOTIFICATION_SPAN_YOUR_CREATORS_PROFILE_REJECTED' = 'NOTIFICATION_NOTIFICATION_SPAN_YOUR_CREATORS_PROFILE_REJECTED',
	'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE' = 'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE',
	'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE_TO_FREE' = 'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE_TO_FREE',
	'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE_FROM_FREE_TO' = 'NOTIFICATION_NOTIFICATION_SPAN_CHANGED_SUBSCRIPTION_PRICE_FROM_FREE_TO',
	'NOTIFICATION_NOTIFICATION_SPAN_TODAY' = 'NOTIFICATION_NOTIFICATION_SPAN_TODAY',
	'NOTIFICATION_NOTIFICATION_SPAN_YESTERDAY' = 'NOTIFICATION_NOTIFICATION_SPAN_YESTERDAY',

	'NOTIFICATION_NOTIFICATION_LIST_TEXT_NOTIFICATIONS' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_NOTIFICATIONS',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_ALL' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_ALL',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_NO_NOTIFICATIONS' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_NO_NOTIFICATIONS',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_BACK_TO_HOME_PAGE' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_BACK_TO_HOME_PAGE',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_SYSTEM' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_SYSTEM',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_SUBSCRIPTION' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_SUBSCRIPTION',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_CONTENT' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_CONTENT',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_PURCHASES' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_PURCHASES',
	'NOTIFICATION_NOTIFICATION_LIST_TEXT_TAGS' = 'NOTIFICATION_NOTIFICATION_LIST_TEXT_TAGS',

	'PROFILE_CREATORS_PROFILE_HEADER_SUBSCRIPTION' = 'PROFILE_CREATORS_PROFILE_HEADER_SUBSCRIPTION',

	'QUEUE_QUEUE_PLAN_TEXT_QUEUE' = 'QUEUE_QUEUE_PLAN_TEXT_QUEUE',
	'QUEUE_QUEUE_NEW_SCHEDULED_POST' = 'QUEUE_QUEUE_NEW_SCHEDULED_POST',
	'QUEUE_QUEUE_EDIT_MESSAGE' = 'QUEUE_QUEUE_EDIT_MESSAGE',
	'QUEUE_QUEUE_EDIT_POST' = 'QUEUE_QUEUE_EDIT_POST',

	'QUEUE_SCHEDULED_POST_TEXT_ADD_SCHEDULED_POSTS' = 'QUEUE_SCHEDULED_POST_TEXT_ADD_SCHEDULED_POSTS',
	'QUEUE_SCHEDULED_POST_TEXT_ADD_NOW_YOU_CAN_SCHEDULE_POSTS' = 'QUEUE_SCHEDULED_POST_TEXT_ADD_NOW_YOU_CAN_SCHEDULE_POSTS',

	'QUEUE_SUBSCRIPTION_SETTINGS_CONTROL_SUBSCRIPTION_SETTING' = 'QUEUE_SUBSCRIPTION_SETTINGS_CONTROL_SUBSCRIPTION_SETTING',

	'QUEUE_TOASTR_ERROR_HIDING_DELAY_MESSAGE' = 'QUEUE_TOASTR_ERROR_HIDING_DELAY_MESSAGE',
	'QUEUE_TOASTR_THE_MESSAGE_HAS_BEEN_DELETED' = 'QUEUE_TOASTR_THE_MESSAGE_HAS_BEEN_DELETED',
	'QUEUE_TOASTR_THE_POST_HAS_BEEN_DELETED' = 'QUEUE_TOASTR_THE_POST_HAS_BEEN_DELETED',

	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_PASSWORD' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_PASSWORD',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_SAVE' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_SAVE',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_OLD_PASSWORD' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_OLD_PASSWORD',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_REQUIRED_FIELD' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_REQUIRED_FIELD',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_NEW_PASSWORD' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_NEW_PASSWORD',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_NEW_PASSWORD_HINT' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_NEW_PASSWORD_HINT',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_CONFIRM_NEW_PASSWORD' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_CONFIRM_NEW_PASSWORD',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_PASSWORDS_DO_NOT_MATCH' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_SPAN_PASSWORDS_DO_NOT_MATCH',
	'SETTINGS_ACCOUNT_NEW_PASSWORD_TOASTR_PASSWORD_CHANGED_SUCCESSFULLY' = 'SETTINGS_ACCOUNT_NEW_PASSWORD_TOASTR_PASSWORD_CHANGED_SUCCESSFULLY',

	'SETTINGS_ACCOUNT_SPAN_ACCOUNT' = 'SETTINGS_ACCOUNT_SPAN_ACCOUNT',
	'SETTINGS_ACCOUNT_SPAN_SECURITY' = 'SETTINGS_ACCOUNT_SPAN_SECURITY',
	'SETTINGS_ACCOUNT_SPAN_PASSWORD' = 'SETTINGS_ACCOUNT_SPAN_PASSWORD',

	'SETTINGS_DEFAULT_MESSAGE_SPAN_NEW_MESSAGE_FOR_NEW_SUBSCRIBERS' = 'SETTINGS_DEFAULT_MESSAGE_SPAN_NEW_MESSAGE_FOR_NEW_SUBSCRIBERS',
	'SETTINGS_DEFAULT_MESSAGE_TOASTR_FORM_IS_NOT_VALID' = 'SETTINGS_DEFAULT_MESSAGE_TOASTR_FORM_IS_NOT_VALID',
	'SETTINGS_DEFAULT_MESSAGE_TOASTR_CHANGES_WAS_SAVED' = 'SETTINGS_DEFAULT_MESSAGE_TOASTR_CHANGES_WAS_SAVED',

	'SETTINGS_FEED_SETTINGS_SPAN_FEED_SETTINGS' = 'SETTINGS_FEED_SETTINGS_SPAN_FEED_SETTINGS',
	'SETTINGS_FEED_SETTINGS_SPAN_PREFERRED_CONTENT' = 'SETTINGS_FEED_SETTINGS_SPAN_PREFERRED_CONTENT',
	'SETTINGS_FEED_SETTINGS_SPAN_PREFERRED_CONTENT_TEXT' = 'SETTINGS_FEED_SETTINGS_SPAN_PREFERRED_CONTENT_TEXT',
	'SETTINGS_FEED_SETTINGS_SPAN_MALE' = 'SETTINGS_FEED_SETTINGS_SPAN_MALE',
	'SETTINGS_FEED_SETTINGS_SPAN_FEMALE' = 'SETTINGS_FEED_SETTINGS_SPAN_FEMALE',
	'SETTINGS_FEED_SETTINGS_SPAN_ALL_CONTENT' = 'SETTINGS_FEED_SETTINGS_SPAN_ALL_CONTENT',

	'SETTINGS_NOTIFICATION_SPAN_NOTIFICATIONS' = 'SETTINGS_NOTIFICATION_SPAN_NOTIFICATIONS',
	'SETTINGS_NOTIFICATION_SPAN_SHOW_ACTIVITY_STATUS' = 'SETTINGS_NOTIFICATION_SPAN_SHOW_ACTIVITY_STATUS',
	'SETTINGS_NOTIFICATION_SPAN_PUSH_SETTINGS_TEXT' = 'SETTINGS_NOTIFICATION_SPAN_PUSH_SETTINGS_TEXT',

	'SETTINGS_PAYMENT_ADD_CARD_SPAN_ADD_CARD' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_ADD_CARD',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_CARD_DETAILS' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_CARD_DETAILS',
	'SETTINGS_PAYMENT_ADD_CARD_INPUT_CARD_NUMBER' = 'SETTINGS_PAYMENT_ADD_CARD_INPUT_CARD_NUMBER',
	'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_CARD_HOLDER_NAME' = 'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_CARD_HOLDER_NAME',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_EXPIRATION' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_EXPIRATION',
	'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_MM_YY' = 'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_MM_YY',
	'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_CVC' = 'SETTINGS_PAYMENT_ADD_CARD_PLACEHOLDER_CVC',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_BILLING_DETAILS' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_BILLING_DETAILS',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_COUNTRY' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_COUNTRY',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_STATE_PROVINCE' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_STATE_PROVINCE',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_ADDRESS' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_ADDRESS',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_CITY' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_CITY',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_ZIP_POSTAL_CODE' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_ZIP_POSTAL_CODE',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_AGE_BOX_TEXT' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_AGE_BOX_TEXT',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_SUBMIT' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_SUBMIT',
	'SETTINGS_PAYMENT_ADD_CARD_SPAN_ONE_TIME_CHARGE_TEXT' = 'SETTINGS_PAYMENT_ADD_CARD_SPAN_ONE_TIME_CHARGE_TEXT',
	'SETTINGS_PAYMENT_ADD_CARD_TOASTR_THE_PAYMENT_METHOD_HAS_BEEN_ADDED' = 'SETTINGS_PAYMENT_ADD_CARD_TOASTR_THE_PAYMENT_METHOD_HAS_BEEN_ADDED',

	'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DEFAULT' = 'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DEFAULT',
	'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DELETE_CARD' = 'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DELETE_CARD',
	'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DELETE_CARD_CONFIRM_TITLE' = 'SETTINGS_PAYMENT_CARD_ITEM_SPAN_DELETE_CARD_CONFIRM_TITLE',
	'SETTINGS_PAYMENT_CARD_ITEM_YES_DELETE' = 'SETTINGS_PAYMENT_CARD_ITEM_YES_DELETE',

	'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_PAYMENT_CARDS' = 'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_PAYMENT_CARDS',
	'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_PAYMENT_SECURITY_TEXT' = 'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_PAYMENT_SECURITY_TEXT',
	'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_ACTIVE_CARDS' = 'SETTINGS_PAYMENT_PAYMENT_CARDS_SPAN_ACTIVE_CARDS',

	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_PRIVACY_AND_SAFETY' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_PRIVACY_AND_SAFETY',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_PROFILE',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_ACTIVITY_STATUS' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_ACTIVITY_STATUS',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_ALL_VIEWS_COUNT_ON_YOUR_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_ALL_VIEWS_COUNT_ON_YOUR_PROFILE',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_FOLLOWERS_COUNT_ON_YOUR_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_FOLLOWERS_COUNT_ON_YOUR_PROFILE',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_MEDIA_COUNT_ON_YOUR_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_MEDIA_COUNT_ON_YOUR_PROFILE',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_MY_SOCIAL_MEDIA_ON_YOUR_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_MY_SOCIAL_MEDIA_ON_YOUR_PROFILE',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_POSTS' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_POSTS',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_ENABLE_COMMENTS' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_ENABLE_COMMENTS',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_LIKES' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_SHOW_LIKES',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_WATERMARKS' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_WATERMARKS',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_DISCOVERABILITY' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_DISCOVERABILITY',
	'SETTINGS_PRIVACY_AND_SAFETY_SPAN_FULLY_PRIVATE_PROFILE' = 'SETTINGS_PRIVACY_AND_SAFETY_SPAN_FULLY_PRIVATE_PROFILE',

	'SETTINGS_PROFILE_SPAN_PROFILE' = 'SETTINGS_PROFILE_SPAN_PROFILE',
	'SETTINGS_PROFILE_SPAN_RECOMMENDED_BANNER_SIZE' = 'SETTINGS_PROFILE_SPAN_RECOMMENDED_BANNER_SIZE',
	'SETTINGS_PROFILE_SPAN_RECOMMENDED_AVATAR_SIZE' = 'SETTINGS_PROFILE_SPAN_RECOMMENDED_AVATAR_SIZE',
	'SETTINGS_PROFILE_SPAN_USERNAME' = 'SETTINGS_PROFILE_SPAN_USERNAME',
	'SETTINGS_PROFILE_SPAN_DISPLAY_NAME' = 'SETTINGS_PROFILE_SPAN_DISPLAY_NAME',
	'SETTINGS_PROFILE_SPAN_BIO' = 'SETTINGS_PROFILE_SPAN_BIO',
	'SETTINGS_PROFILE_SPAN_EDIT_ONBOARDING_INFORMATION' = 'SETTINGS_PROFILE_SPAN_EDIT_ONBOARDING_INFORMATION',

	'SETTINGS_REFERRAL_LINK_REFERRAL_LINK' = 'SETTINGS_REFERRAL_LINK_REFERRAL_LINK',
	'SETTINGS_REFERRAL_LINK_COPY_LINK' = 'SETTINGS_REFERRAL_LINK_COPY_LINK',

	'SETTINGS_SETTINGS_SPAN_SETTINGS' = 'SETTINGS_SETTINGS_SPAN_SETTINGS',
	'SETTINGS_SETTINGS_SPAN_PROFILE' = 'SETTINGS_SETTINGS_SPAN_PROFILE',
	'SETTINGS_SETTINGS_SPAN_SUBSCRIPTION' = 'SETTINGS_SETTINGS_SPAN_SUBSCRIPTION',
	'SETTINGS_SETTINGS_SPAN_ACCOUNT' = 'SETTINGS_SETTINGS_SPAN_ACCOUNT',
	'SETTINGS_SETTINGS_SPAN_PAYMENT_CARDS' = 'SETTINGS_SETTINGS_SPAN_PAYMENT_CARDS',
	'SETTINGS_SETTINGS_SPAN_REFERRAL_LINK' = 'SETTINGS_SETTINGS_SPAN_REFERRAL_LINK',
	'SETTINGS_SETTINGS_SPAN_WELCOME_MESSAGE' = 'SETTINGS_SETTINGS_SPAN_WELCOME_MESSAGE',
	'SETTINGS_SETTINGS_SPAN_LOG_OUT' = 'SETTINGS_SETTINGS_SPAN_LOG_OUT',

	'SETTINGS_SUBSCRIPTION_SPAN_SUBSCRIPTION' = 'SETTINGS_SUBSCRIPTION_SPAN_SUBSCRIPTION',
	'SETTINGS_SUBSCRIPTION_SPAN_PRICE_PER_MONTH' = 'SETTINGS_SUBSCRIPTION_SPAN_PRICE_PER_MONTH',
	'SETTINGS_SUBSCRIPTION_SPAN_MINIMUM' = 'SETTINGS_SUBSCRIPTION_SPAN_MINIMUM',
	'SETTINGS_SUBSCRIPTION_SPAN_USD_OR_FREE' = 'SETTINGS_SUBSCRIPTION_SPAN_USD_OR_FREE',
	'SETTINGS_SUBSCRIPTION_SPAN_SUBSCRIPTION_DESCRIPTION' = 'SETTINGS_SUBSCRIPTION_SPAN_SUBSCRIPTION_DESCRIPTION',
	'SETTINGS_SUBSCRIPTION_TOASTR_SUBSCRIPTION_SUCCESSFULLY_CREATED' = 'SETTINGS_SUBSCRIPTION_TOASTR_SUBSCRIPTION_SUCCESSFULLY_CREATED',

	'STATEMENTS_PAYOUT_REQUEST_SPAN_PAYOUT_REQUEST' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_PAYOUT_REQUEST',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_SELECT_ACCOUNT' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_SELECT_ACCOUNT',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_ENTER_AMOUNT' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_ENTER_AMOUNT',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_AVAILABLE_BALANCE' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_AVAILABLE_BALANCE',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_PLATFORM_COMMISSION' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_PLATFORM_COMMISSION',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_PLATFORM_COMMISSION_1' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_PLATFORM_COMMISSION_1',
	'STATEMENTS_PAYOUT_REQUEST_SPAN_WITHDRAWAL_TEXT' = 'STATEMENTS_PAYOUT_REQUEST_SPAN_WITHDRAWAL_TEXT',
	'STATEMENTS_PAYOUT_REQUEST_TOASTR_REQUEST_CREATED' = 'STATEMENTS_PAYOUT_REQUEST_TOASTR_REQUEST_CREATED',

	'STATEMENTS_PAYOUT_SETTINGS_SPAN_PAYOUT_SETTINGS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_PAYOUT_SETTINGS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_ACCOUNT' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_ACCOUNT',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_CRYPTO' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_CRYPTO',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ADD_BANK_ACCOUNT_FOR_WITHDRAWALS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ADD_BANK_ACCOUNT_FOR_WITHDRAWALS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACTIVE_ACCOUNTS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACTIVE_ACCOUNTS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_DELETE_ACCOUNT' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_DELETE_ACCOUNT',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ENTER_BANK_ACCOUNT_INFO' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ENTER_BANK_ACCOUNT_INFO',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_DETAILS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_DETAILS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_NUMBER' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_NUMBER',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_TYPE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_TYPE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ROUTING_NUMBER' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ROUTING_NUMBER',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_PHONE_NUMBER' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_PHONE_NUMBER',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_NAME' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ACCOUNT_NAME',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_SWIFT_CODE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_SWIFT_CODE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_IBAN_NUMBER' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_IBAN_NUMBER',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_NAME' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_NAME',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_NOT_REQUIRED' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_NOT_REQUIRED',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_DETAILS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_BANK_DETAILS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_COUNTRY' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_COUNTRY',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_STATE_PROVINCE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_STATE_PROVINCE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ADDRESS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ADDRESS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_CITY' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_CITY',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ZIP_POSTAL_CODE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ZIP_POSTAL_CODE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_USE_ACCOUNT_IN_FUTURE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_USE_ACCOUNT_IN_FUTURE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_I_HAVE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_I_HAVE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_SIGNED_AUTHORIZATION' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_SIGNED_AUTHORIZATION',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_FROM_MY_CUSTOMER_OR_WILL_READ_THE' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_FROM_MY_CUSTOMER_OR_WILL_READ_THE',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_AUTHORIZATION_SCRIPT' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_AUTHORIZATION_SCRIPT',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_ENTER_CRYPTO_WALLET_INFO' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_ENTER_CRYPTO_WALLET_INFO',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_WALLET_DETAILS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_WALLET_DETAILS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_CRYPTO_ADDRESS' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_CRYPTO_ADDRESS',
	'STATEMENTS_PAYOUT_SETTINGS_SPAN_WALLET' = 'STATEMENTS_PAYOUT_SETTINGS_SPAN_WALLET',

	'STATEMENTS_STATEMENTS_SPAN_STATEMENTS' = 'STATEMENTS_STATEMENTS_SPAN_STATEMENTS',
	'STATEMENTS_STATEMENTS_SPAN_CURRENT_BALANCE' = 'STATEMENTS_STATEMENTS_SPAN_CURRENT_BALANCE',
	'STATEMENTS_STATEMENTS_SPAN_PAYOUT_REQUEST' = 'STATEMENTS_STATEMENTS_SPAN_PAYOUT_REQUEST',
	'STATEMENTS_STATEMENTS_SPAN_HISTORY' = 'STATEMENTS_STATEMENTS_SPAN_HISTORY',
	'STATEMENTS_STATEMENTS_SPAN_PAYOUT_SETTINGS' = 'STATEMENTS_STATEMENTS_SPAN_PAYOUT_SETTINGS',

	'STATEMENTS_STATEMENTS_HISTORY_SPAN_HISTORY' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_HISTORY',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_ALL' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_ALL',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_INCOME' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_INCOME',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_OUTCOME' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_OUTCOME',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_DATE' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_DATE',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_NAME' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_NAME',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_TYPE' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_TYPE',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_SUM' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_SUM',

	'STATEMENTS_STATEMENTS_HISTORY_SPAN_TIPS' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_TIPS',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_MESSAGES' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_MESSAGES',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_SUBSCRIPTIONS' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_SUBSCRIPTIONS',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_POSTS' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_POSTS',
	'STATEMENTS_STATEMENTS_HISTORY_SPAN_PAYOUT' = 'STATEMENTS_STATEMENTS_HISTORY_SPAN_PAYOUT',

	'BALANCE_HISTORY' = 'BALANCE_HISTORY',
	'BALANCE_HISTORY_DATE' = 'BALANCE_HISTORY_DATE',
	'BALANCE_HISTORY_TYPE' = 'BALANCE_HISTORY_TYPE',
	'BALANCE_HISTORY_SUM' = 'BALANCE_HISTORY_SUM',

	'POP_UP' = 'POP_UP',
	'USD' = 'USD',
	'COIN' = 'COIN',

	'SMILES_SEARCH' = 'SMILES_SEARCH',
	'SMILES_EMOJI_LIST' = 'SMILES_EMOJI_LIST',
	'SMILES_NOT_FOUND' = 'SMILES_NOT_FOUND',
	'SMILES_CLEAR' = 'SMILES_CLEAR',
	'SMILES_CATEGORY_SEARCH' = 'SMILES_CATEGORY_SEARCH',
	'SMILES_CATEGORY_RECENT' = 'SMILES_CATEGORY_RECENT',
	'SMILES_CATEGORY_PEOPLE' = 'SMILES_CATEGORY_PEOPLE',
	'SMILES_CATEGORY_NATURE' = 'SMILES_CATEGORY_NATURE',
	'SMILES_CATEGORY_FOODS' = 'SMILES_CATEGORY_FOODS',
	'SMILES_CATEGORY_ACTIVITY' = 'SMILES_CATEGORY_ACTIVITY',
	'SMILES_CATEGORY_PLACES' = 'SMILES_CATEGORY_PLACES',
	'SMILES_CATEGORY_OBJECTS' = 'SMILES_CATEGORY_OBJECTS',
	'SMILES_CATEGORY_SYMBOLS' = 'SMILES_CATEGORY_SYMBOLS',
	'SMILES_CATEGORY_FLAGS' = 'SMILES_CATEGORY_FLAGS',
	'SMILES_CATEGORY_CUSTOM' = 'SMILES_CATEGORY_CUSTOM',
	'SMILES_SKIN_TONE_1' = 'SMILES_SKIN_TONE_1',
	'SMILES_SKIN_TONE_2' = 'SMILES_SKIN_TONE_2',
	'SMILES_SKIN_TONE_3' = 'SMILES_SKIN_TONE_3',
	'SMILES_SKIN_TONE_4' = 'SMILES_SKIN_TONE_4',
	'SMILES_SKIN_TONE_5' = 'SMILES_SKIN_TONE_5',
	'SMILES_SKIN_TONE_6' = 'SMILES_SKIN_TONE_6',

	'LOOKS_SUCCESFULLY_UPDATED' = 'LOOKS_SUCCESFULLY_UPDATED',
	'LOOKS_ERROR_UPDATED' = 'LOOKS_ERROR_UPDATED',
	'LOOK_SETTINGS' = 'LOOK_SETTINGS',
}

export type TRANSLOCO = {
	[key in EKeysTransloco]: string;
};

export enum EAvailableLanguages {
	'ru' = 'ru',
	'en' = 'en',
	'de' = 'de',
}
