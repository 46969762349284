import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPreviewService } from '../../../service/media-preview.service';
import { UserImageModelData } from 'desiren-core-lib/lib/types/users';
import { ImageBlurhashComponent } from 'src/app/components/image-blurhash/image-blurhash.component';

export enum ProfileAvatarSize {
	profile = 'profile',
	comment = 'comment',
	chat = 'chat',
	notification = 'notification',
	navigation = 'navigation',
	new_profile = 'new-profile',
	post = 'post',
	looks = 'looks',
}

@Component({
	selector: 'app-profile-avatar',
	standalone: true,
	imports: [CommonModule, ImageBlurhashComponent],
	templateUrl: './profile-avatar.component.html',
	styleUrl: './profile-avatar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAvatarComponent implements OnChanges {
	readonly profileAvatarSize = ProfileAvatarSize;
	previewService = inject(MediaPreviewService);
	@Input({ required: true }) userImageData: UserImageModelData | null = null;
	@Input() size: ProfileAvatarSize = ProfileAvatarSize.profile;
	@Input() isCircle: boolean = false;
	@Input() online: boolean | null = null;
	@Input() preview: boolean = true;
	@Input() isCreator: boolean = true;
	public avatarUrl: string = '';
	public avatarHash: string = '';
	public sizePx: number = 80;

	private setSizePx() {
		switch (this.size) {
			case ProfileAvatarSize.chat:
				return 48;
			case ProfileAvatarSize.profile:
				return 80;
			case ProfileAvatarSize.comment:
			case ProfileAvatarSize.notification:
				return 40;
			case ProfileAvatarSize.navigation:
				return 32;
			case ProfileAvatarSize.post:
				return 64;
			case ProfileAvatarSize.looks:
				return 46;
		}
		return 80;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['size']) {
			this.sizePx = this.setSizePx();
		}
		if (changes['userImageData']) {
			this.avatarUrl = this.userImageData?.base + this.userImageData?.w?.at(4);
			this.avatarHash = this.userImageData?.hash;
		}
	}

	showImage() {
		if (this.size !== ProfileAvatarSize.chat && this.size !== ProfileAvatarSize.notification && this.preview && this.userImageData.base && this.userImageData.optimized) {
			this.previewService.showImageFileUrl(this.userImageData.base + this.userImageData.optimized);
		}
	}
}
