import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { ProfileAvatarComponent, ProfileAvatarSize } from 'src/app/uikit/profile/profile-avatar/profile-avatar.component';
import { ProfileDisplayNameComponent } from 'src/app/uikit/profile/profile-name/profile-display-name/profile-display-name.component';
import { ProfileNicknameComponent } from 'src/app/uikit/profile/profile-name/profile-nickname/profile-nickname.component';
import { ReadMoreLessComponent } from '../../read-more-less/read-more-less.component';
import { LooksService } from 'src/app/service/looks.service';
import { ActivatedRoute, Params, RouterModule } from '@angular/router';
import { take } from 'rxjs';
import { DropdownComponent } from 'src/app/uikit/dropdown/dropdown.component';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslationsService } from 'src/app/service/translations/translations.service';
import { UserRepositoryService } from 'src/app/repository/user-repository.service';
import { EUserImageType, UserImageModelData } from 'desiren-core-lib/lib';

@Component({
	selector: 'app-look-item',
	standalone: true,
	imports: [
		NgClass,
		NgStyle,
		NgIf,
		RouterModule,
		AsyncPipe,
		IconsComponent,
		ReadMoreLessComponent,
		ProfileAvatarComponent,
		ProfileDisplayNameComponent,
		ProfileNicknameComponent,
		DropdownComponent,
	],
	templateUrl: './look-item.component.html',
	styleUrl: './look-item.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookItemComponent implements OnInit, AfterViewInit, OnChanges {
	public readonly looksService: LooksService = inject(LooksService);
	public readonly toastr: ToastrService = inject(ToastrService);
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	private readonly userRepo: UserRepositoryService = inject(UserRepositoryService);
	public readonly route: ActivatedRoute = inject(ActivatedRoute);
	public readonly profileAvatarSize = ProfileAvatarSize;

	public isPlaying: boolean = false;
	public isControlsActive: boolean = false;
	public videoSrc: string = null;
	public poster: string = null;
	public video: any;
	public isHovered: boolean = false;
	public isOwnItem: boolean = false;
	public isActive = false;
	public avatarImageModelData: UserImageModelData = null;

	@Input() isNew: boolean = false;
	@Input() routeVideoId: string;
	@Input() volume: number = 1;

	@Input() set item(video: any) {
		this.videoSrc = video?.href || video.file?.url;
		this.video = video;
		this.avatarImageModelData = this.getAvatarImage();
		const userId = this.userRepo.meLazy().cache.id ?? '';
		this.isOwnItem = userId === this.video?.user?.id;
		this.poster = video?.thumbnail?.url || video?.file?.base + video?.file?.thumbnail;
	}

	@Output() onCommentEvent: EventEmitter<void> = new EventEmitter();
	@Output() onRemoveEvent: EventEmitter<string> = new EventEmitter();
	@Output() onUpdateEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild('videoPlayer', { read: ElementRef }) videoPlayer!: ElementRef<HTMLVideoElement>;
	@ViewChild('controls', { read: ElementRef }) controls!: ElementRef<HTMLElement>;
	@ViewChild('appDropdown', { read: DropdownComponent }) appDropdown!: DropdownComponent;

	public widthPercentage = 0;
	public commentsEnable = this.looksService.looksCommentsEnable$;

	ngOnInit(): void {}
	ngAfterViewInit(): void {
		setTimeout(() => {
			if (this.routeVideoId === this.video.id) {
				this.play();
				this.videoPlayer.nativeElement.volume = this.volume;
			}
		}, 300);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['volume'] && this.videoPlayer && this.routeVideoId === this.video.id) {
			setTimeout(() => {
				this.videoPlayer.nativeElement.volume = changes['volume'].currentValue;
			}, 200);
		}
	}

	public likeEvent() {
		this.looksService
			.looksLikeEvent(this.video.id)
			.pipe(take(1))
			.subscribe({
				next: (data) => {
					if (!this.video.liked) {
						++this.video.likes;
						this.isActive = true;
						setTimeout(() => {
							this.isActive = false;
						}, 1000);
					} else {
						--this.video.likes;
					}
					this.video.liked = !this.video.liked;
				},
			});
	}

	public commentEvent(e: Event) {
		e.preventDefault();
		this.looksService.looksCommentsEnable.next(!this.looksService.looksCommentsEnable.getValue());
	}

	public togglePlayPause(): void {
		const video = this.videoPlayer.nativeElement;
		video.onplaying;
		if (video.paused) {
			video.play();
			this.isPlaying = !this.isPlaying;
		} else {
			video.pause();
			this.isPlaying = !this.isPlaying;
		}
	}

	public updateProgress(event: Event): void {
		if (this.videoPlayer.nativeElement.paused) return;
		const video = event.target as HTMLVideoElement;
		this.widthPercentage = (video.currentTime / video.duration) * 100;
	}

	public seek(event: MouseEvent): void {
		const progressBar = event.currentTarget as HTMLElement;
		const rect = progressBar.getBoundingClientRect();
		const clickX = event.clientX - rect.left;
		const newTime = (clickX / progressBar.offsetWidth) * this.videoPlayer.nativeElement.duration;
		this.videoPlayer.nativeElement.currentTime = newTime;
	}

	public initializeVideo(): void {
		this.widthPercentage = 0;
	}

	public deleteLooks(): void {
		this.onRemoveEvent.next(this.video.id);
	}

	public editLooks(): void {
		this.onUpdateEvent.next(this.video);
		this.appDropdown.toggleDropdown();
	}

	public sharePost(): void {
		navigator.clipboard.writeText(`${environment.webUrl}/looks/${this.video.id}`).then(() => {
			this.toastr.success(this.translationsService.translate(this.translationsService.keys.GLOBAL_TOASTR_LINK_WAS_COPIED));
		});
	}

	public play(): void {
		this.videoPlayer.nativeElement
			.play()
			.then(() => {
				this.videoPlayer.nativeElement.play();
				this.isPlaying = !this.isPlaying;
			})
			.catch(() => {});
	}

	public pause(): void {
		this.videoPlayer.nativeElement.pause();
		this.isPlaying = !this.isPlaying;
		this.videoPlayer.nativeElement.currentTime = 0;
		if (!this.isNew) this.appDropdown?.isOpen ? this.appDropdown.toggleDropdown() : null;
	}

	private getAvatarImage() {
		return this.video.user.images.find((key) => key.imageType === EUserImageType.AVATAR) ?? null;
	}
}
