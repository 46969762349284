import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IconsComponent} from "../icons/icons.component";
import {CommonModule} from "@angular/common";

export enum DialogSize {
  narrow = '480',
  medium = '560'
}

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule, IconsComponent],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent {
  @ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;
  @Input() dismissible: boolean = true;
  @Input() size?: DialogSize;
  @Input() isOverflowVisible?: boolean = false;
  @Input() isClearPadding?: boolean = false;
  @Output() onClose = new EventEmitter<any>();

  open() {
    this.dialog.nativeElement.show();
    this.dialog.nativeElement.classList.add('app-dialog-opened');
    this.overlay.nativeElement.classList.add('opened');
  }

  close() {
    this.dialog.nativeElement.close();
    this.dialog.nativeElement.classList.remove('app-dialog-opened');
    this.overlay.nativeElement.classList.remove('opened');
    this.onClose.emit();
  }

  overlayClick() {
    return this.dismissible ? this.close() : null;
  }

  get isOpened() {
    return this.dialog.nativeElement.classList.contains('opened');
  }
}
