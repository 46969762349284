<ng-container>
  <div class="profile-name"
    [ngClass]="{
      'small': size === userNameSize.small,
      'large': size === userNameSize.large,
    }">
    <span
      [ngStyle]="{'color' : textColor}"
      [ngClass]="{
        'font-main-large': size === userNameSize.default,
        'font-content-middle text-bold': size === userNameSize.small,
        'font-main-small': size === userNameSize.large,
      }"
    >{{ displayName }}</span>
    <app-icons
      *ngIf="emailConfirmed"
      [name]="icon"
      [width]="24"
      [height]="24"
      [fillColor]="fillColor">
    </app-icons>
  </div>
</ng-container>
