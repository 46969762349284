import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AudioMessageComponent } from '../audio-message/audio-message.component';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { VoiceRecorderService } from './voice-recorder.service';
import { CommonModule } from '@angular/common';
import {
  fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {TranslationsService} from "../../service/translations/translations.service";

@Component({
  selector: 'app-voice-recorder',
  standalone: true,
  imports: [CommonModule, IconsComponent, AudioMessageComponent],
  templateUrl: './voice-recorder.component.html',
  styleUrl: './voice-recorder.component.scss',
})
export class VoiceRecorderComponent implements AfterViewInit {
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  isRecording: boolean = false;
  audioURL: string;
  public recordingDuration: string = '0:00';
  public recordingSubscription: Observable<string>;
  private startTime: number;
  private subscriptions: Subscription = new Subscription();
  @Output() voiceMessage: EventEmitter<string> = new EventEmitter();
  @ViewChild('recordButton') recordButton: ElementRef<HTMLButtonElement>;
  @ViewChild('holdButtonAlert') holdButtonAlert: ElementRef<HTMLButtonElement>;


  constructor(private audioRecorderService: VoiceRecorderService) {
    this.recordingSubscription = this.audioRecorderService.recordingDuration$;
  }

  ngAfterViewInit(): void {
    this.audioRecorderService.requestMicrophoneAccess();
    this.preventContextMenu();
    this.initRecording();
  }

  private preventContextMenu(): void {
    if (this.recordButton.nativeElement) {
      fromEvent(this.recordButton.nativeElement, 'contextmenu').subscribe((event: MouseEvent) => {
        event.preventDefault();
      });
    }
  }

  private handleEvent<T>(
    event: 'mousedown' | 'touchstart',
    endEvent: 'mouseup' | 'touchend'
  ): void {
    const start$ = fromEvent<T>(this.recordButton.nativeElement, event).pipe(
      tap(() => (this.startTime = Date.now())),
      switchMap(() => {
        this.startRecording();
        return fromEvent<T>(this.recordButton.nativeElement, endEvent).pipe(
          take(1),
          map(() => Date.now() - this.startTime),
          tap((duration) => {
            if (duration < 300) {
              this.showAlert();
              this.audioRecorderService.resetRecording();
              this.isRecording = false;
            } else {
              this.stopRecording();
            }
          })
        );
      })
    );

    this.subscriptions.add(start$.subscribe());
  }

  private initRecording(): void {
    this.handleEvent<MouseEvent>('mousedown', 'mouseup');
    this.handleEvent<TouchEvent>('touchstart', 'touchend');
  }

  public async startRecording(): Promise<void> {
    this.isRecording = true;
    await this.audioRecorderService.startRecording();
  }

  async stopRecording() {
    this.isRecording = false;
    if (this.audioRecorderService.isRecording) {
      const audioBlob = await this.audioRecorderService.stopRecording();
      this.voiceMessage.next(URL.createObjectURL(audioBlob));
    }
  }

  private showAlert(): void {
    const alertElement = this.holdButtonAlert.nativeElement;
    if (alertElement) {
      alertElement.classList.add('show');
      setTimeout(() => {
        alertElement.classList.remove('show');
      }, 3000);
    }
  }
}
