<div class="file-filter">
  <button type="button" class="file-filter__item action-text" (click)="setFilterString(null)" [ngClass]="filter == null ? 'active' : ''">
    {{translationsService.translate(translationsService.keys.CONTENT_FILE_FILTER_BUTTON_ALL)}} <span>{{count}}</span>
  </button>
  <button type="button" class="file-filter__item action-text" (click)="setFilterString('PHOTO')" [ngClass]="filter == 'PHOTO' ? 'active' : ''">
    {{translationsService.translate(translationsService.keys.CONTENT_FILE_FILTER_BUTTON_PHOTO)}} <span>{{countPhoto}}</span>
  </button>
  <button type="button" class="file-filter__item action-text" (click)="setFilterString('VIDEO')" [ngClass]="filter == 'VIDEO' ? 'active' : ''">
    {{translationsService.translate(translationsService.keys.CONTENT_FILE_FILTER_BUTTON_VIDEOS)}} <span>{{countVideo}}</span>
  </button>
</div>
