import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IHubFileResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { IHubPhotoUploadResponse } from 'desiren-core-lib/lib/types/hub/upload/photo.upload.response.hub.interface';
import { IHubVideoUploadResponse } from 'desiren-core-lib/lib/types/hub/upload/video.upload.response.hub.interface';
import { MediaPreviewService } from '../../../../service/media-preview.service';
import { IconsComponent } from '../../../../uikit/icons/icons.component';
import { TranslationsService } from '../../../../service/translations/translations.service';
import { BlurHashDirective } from '../../../../directives/blur-hash.directive';

@Component({
	selector: 'app-media-file',
	standalone: true,
	imports: [CommonModule, NgOptimizedImage, IconsComponent, BlurHashDirective],
	templateUrl: './media-file.component.html',
	styleUrl: './media-file.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFileComponent implements OnChanges {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	previewService = inject(MediaPreviewService);
	public previewUrl: string = '';
	public isVideo = false;
	public mediaFile: IHubFileResponse;
	private pressTimer;
	public isFileAvailable: boolean = true;
	@Input('file') set file(file: IHubFileResponse) {
		this.mediaFile = file;
		if (this.isLooksMode) {
			const looks = file.file as any;
			this.isFileAvailable = looks?.looks ? Object.values(looks.looks).every((value) => value === true) : false;
		}

		if (file.file.type === EHubFileType.PHOTO) {
			let photo = file.file as IHubPhotoUploadResponse;
			if (photo.w?.at(8)) {
				this.previewUrl = encodeURI(photo.base + photo.w?.at(8));
				return;
			}
			if (photo.w?.at(7)) {
				this.previewUrl = encodeURI(photo.base + photo.w?.at(7)!);
				return;
			}
			if (photo.w?.at(6)) {
				this.previewUrl = encodeURI(photo.base + photo.w?.at(6)!);
				return;
			}
			if (photo.w?.at(5)) {
				this.previewUrl = encodeURI(photo.base + photo.w?.at(5)!);
				return;
			}
			if (photo.w?.at(4)) {
				this.previewUrl = encodeURI(photo.base + photo.w?.at(4)!);
				return;
			}
			this.previewUrl = encodeURI(photo.base + photo.w?.at(3)!);
			return;
		}
		if (file.file.type === EHubFileType.VIDEO) {
			this.isVideo = true;
			let video = file.file as IHubVideoUploadResponse;
			if (video.w?.at(8)) {
				this.previewUrl = encodeURI(video.base + video.w?.at(8));
				return;
			}
			if (video.w?.at(7)) {
				this.previewUrl = encodeURI(video.base + video.w?.at(7)!);
				return;
			}
			if (video.w?.at(6)) {
				this.previewUrl = encodeURI(video.base + video.w?.at(6)!);
				return;
			}
			if (video.w?.at(5)) {
				this.previewUrl = encodeURI(video.base + video.w?.at(5)!);
				return;
			}
			if (video.w?.at(4)) {
				this.previewUrl = encodeURI(video.base + video.w?.at(4)!);
				return;
			}
			this.previewUrl = encodeURI(video.base + video.w?.at(3)!);
			return;
		} else {
			this.previewUrl = '';
		}
	}
	selectedFile: boolean = false;
	@Input() isSelected: boolean = false;
	@Input() isLooksMode: boolean = false;
	@Input() isSelectionAvailable?: boolean = true;
	@Output() onSelect = new EventEmitter<string>();

	check() {
		this.onSelect.emit(this.mediaFile.id);
	}

	show() {
		this.previewService.showFile(this.mediaFile);
	}

	showTouch(event: any) {
		this.pressTimer = setTimeout(() => {
			this.show();
		}, 500);
	}

	clearTouch() {
		clearTimeout(this.pressTimer);
	}

	loaded($event: Event): void {
		console.log($event);
	}

	ngOnChanges(changes: SimpleChanges): void {}
}
