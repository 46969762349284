import { LastPostsImagesUserShortModelData, UserImageModelData, UserModelData } from 'desiren-core-lib/lib/types/users';
import { CreatorDetailsModelData } from 'desiren-core-lib/lib/types/creator-details';
import { OnboardingCreatorModelData } from 'desiren-core-lib/lib/types/onboarding';
import { UUID } from 'crypto';
import { EUserImageType } from 'desiren-core-lib/lib/enums/user-image-type.enum';
import { EAuthProvider } from 'desiren-core-lib/lib/enums/auth-provider.enum';
import { ELanguages } from 'desiren-core-lib/lib/enums/languages.enum';

export class User implements UserModelData {
	constructor(private data: UserModelData) {
		if (data == null) {
			return;
		}
		this.aNetProfileId = data.aNetProfileId;
		this.countFollowers = data.countFollowers;
		this.countFollows = data.countFollows;
		this.countPosts = data.countPosts;
		this.creatorDetails = data.creatorDetails;
		this.deletedAt = data.deletedAt;
		this.bannedAt = data.bannedAt;
		this.email = data.email;
		this.emailConfirmed = data.emailConfirmed;
		this.id = data.id;
		this.images = data.images;
		this.isCreator = data.isCreator;
		this.kycVerified = data.kycVerified;
		this.nickname = data.nickname;
		this.onboardingData = data.onboardingData;
		this.referralCode = data.referralCode;
		this.hasPassword = data.hasPassword;
		this.provider = data.provider;
		this.isOnline = data.isOnline;
		this.lastOnlineAt = data.lastOnlineAt;
		this.language = data.language;
		this.bio = data.bio;
		this.lastPostsImages = data.lastPostsImages;
	}

	get social() {
		return {
			facebook: this.creatorDetails?.otherSocial,
			twitter: this.creatorDetails?.twitter,
			instagram: this.creatorDetails?.instagram,
		};
	}

	get getAvatarModelData() {
		return this.images.find((key) => key.imageType === EUserImageType.AVATAR) ?? null;
	}

	get getBackgroundModelData() {
		return this.images.find((key) => key.imageType === EUserImageType.BACKGROUND) ?? null;
	}

	get avatarUrl(): string | null {
		return this.images.find((data) => data.imageType == EUserImageType.AVATAR)?.base
			? this.images.find((data) => data.imageType == EUserImageType.AVATAR)?.base + this.images.find((data) => data.imageType == EUserImageType.AVATAR)?.w?.at(4)
			: null;
	}

	get backgroundUrl(): string | null {
		return this.images.find((data) => data.imageType == EUserImageType.BACKGROUND)?.base
			? this.images.find((data) => data.imageType == EUserImageType.BACKGROUND)?.base + this.images.find((data) => data.imageType == EUserImageType.BACKGROUND)?.w?.at(4)
			: null;
	}

	get backgroundHash(): string | null {
		return this.images.find((data) => data.imageType == EUserImageType.BACKGROUND)?.hash ?? null;
	}

	toJson() {
		return {
			aNetProfileId: this.aNetProfileId,
			countFollowers: this.countFollowers,
			countFollows: this.countFollows,
			countPosts: this.countPosts,
			creatorDetails: this.creatorDetails,
			deletedAt: this.deletedAt,
			bannedAt: this.bannedAt,
			email: this.email,
			emailConfirmed: this.emailConfirmed,
			id: this.id.toString(),
			images: this.images,
			isCreator: this.isCreator,
			kycVerified: this.kycVerified,
			nickname: this.nickname,
			onboardingData: this.onboardingData,
			referralCode: this.referralCode,
			hasPassword: this.hasPassword,
			isOnline: this.isOnline,
			lastOnlineAt: this.lastOnlineAt,
			language: this.language,
		};
	}

	aNetProfileId: string | null;
	countFollowers: number;
	countFollows: number;
	countPosts: number;
	creatorDetails: CreatorDetailsModelData | null;
	deletedAt: Date | null;
	bannedAt: Date | null;
	email: string;
	emailConfirmed: boolean;
	id: UUID;
	images: UserImageModelData[];
	isCreator: boolean;
	kycVerified: boolean;
	nickname: string;
	onboardingData: OnboardingCreatorModelData | null;
	referralCode: string;
	hasPassword: boolean;
	provider: EAuthProvider;
	isOnline: boolean;
	lastOnlineAt: Date | null;
	language: ELanguages | null;
	lastPostsImages: LastPostsImagesUserShortModelData[];
	bio: string;
}
