import { PriceModelData } from 'desiren-core-lib/lib/types/prices';
import { IHubFileResponse } from 'desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface';
import { EHubFileType } from 'desiren-core-lib/lib/enums/hub/file-type.hub.enum';
import { Moment } from 'moment/esm';
import { EUserImageType, UserImageModelData } from 'desiren-core-lib/lib';

export enum PostAccess {
	free = 'free',
	subscribers = 'subscribers',
	paid = 'paid',
}

export interface IPostOwner {
	id: string;
	nickname: string;
	displayName: string;
	images: UserImageModelData[];
	deletedAt: Date | null;
}

export interface PostInterface {
	access: PostAccess;
	attachments: PostMedia[];
	commentsEnabled: boolean;
	countComments: number;
	createdAt: string;
	id: string;
	isPaid: boolean;
	price: PriceModelData;
	likeUsers: any[];
	liked: boolean;
	likes: number;
	owner: IPostOwner;
	ownerId: string;
	state: PostStateType;
	text: string;
	updateDate: string;
}

export enum PostStateType {
	published = 'published',
}

export enum PostMediaType {
	image = 'IMAGE',
	video = 'VIDEO',
}
export enum OwnerType {
	creator = 'CREATOR',
}

export enum FileType {
	video = 'VIDEO',
	photo = 'PHOTO',
}

export enum FileStatus {
	uploaded = 'UPLOADED',
}

export interface PostMedia {
	file: {
		createdAt: string;
		deletedAt: string;
		file: AttachmentFile;
		folders: any[];
		id: string;
		owner: OwnerType;
		updatedAt: string;
		user: any;
	};
	hubId: string;
	id: string;
	position: number;
}

export interface AttachmentFile {
	avatar?: string;
	base?: string;
	blur?: string;
	createdAt: string;
	deletedAt: any;
	name: string;
	status: FileStatus;
	thumbnail?: string;
	type: EHubFileType;
	updatedAt: string;
	uploadId: string;
	url: string;
}
export class Post implements PostInterface {
	access: PostAccess;
	attachments: PostMedia[];
	commentsEnabled: boolean;
	countComments: number;
	createdAt: string;
	id: string;
	isPaid: boolean;
	price: PriceModelData;
	likeUsers: any[];
	liked: boolean;
	likes: number;
	owner: IPostOwner;
	ownerId: string;
	state: PostStateType;
	text: string;
	updateDate: string;
	tags: string[];
	constructor(data: PostInterface) {
		this.access = data.access;
		this.attachments = data.attachments;
		this.commentsEnabled = data.commentsEnabled;
		this.isPaid = data.isPaid;
		this.price = data.price;
		this.likeUsers = data.likeUsers;
		this.liked = data.commentsEnabled;
		this.createdAt = data.createdAt;
		this.owner = data.owner;
		this.ownerId = data.ownerId;
		this.id = data.id;
		this.state = data.state;
		this.likes = data.likes;
		this.text = data.text;
		this.updateDate = data.updateDate;
		this.countComments = data.countComments;
	}

	get mediaType(): EHubFileType {
		return this.attachments
			.map((e) => {
				return e.file.file.type;
			})
			.includes(EHubFileType.VIDEO)
			? EHubFileType.VIDEO
			: EHubFileType.PHOTO;
	}

	get getAvatarModelData() {
		console.log('call getAvatarModelData', this.owner.images.find((key) => key.imageType === EUserImageType.AVATAR) ?? null);

		return this.owner.images.find((key) => key.imageType === EUserImageType.AVATAR) ?? null;
	}
}

export interface CreatorUserPost {
	message: string;
	price: string;
	files: IHubFileResponse[];
}

export interface AttachmentsNewPost {
	hubId: string;
	position: number;
	type?: EHubFileType;
}

export interface NewUserPost {
	text?: string;
	price?: {
		amount: number;
		currency: string;
	};
	state: PostStateType;
	access: PostAccess;
	commentsEnabled: boolean;
	attachments?: AttachmentsNewPost[];
	delayDate?: Moment;
	tags?: String[];
}
