import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PostRequest} from "./methods";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {FolderCreatorResponse} from "../model/content/folder";
import moment, {Moment} from "moment/esm";
import {UploadFileRequest} from "../modules/content/components/add-media/add-media.component";
import {EHubFileType} from "desiren-core-lib/lib/enums/hub/file-type.hub.enum";
import {
  IHubBasicUploadPhotoResponse,
  IHubBasicUploadResponse,
  IHubBasicUploadVideoResponse,
  IHubMultipartUploadResponse,
  IHubMultipartUploadVideoRequest,
} from "desiren-core-lib/lib/types/hub/upload.hub.interface";
import {
  IHubFolderCreatorResponse,
  IListFolderCreatorResponse
} from "desiren-core-lib/lib/types/hub/creator/folder.creator.hub.interface";
import {
  IHubFileCreatorResponse,
  IHubListFileCreatorResponse
} from "desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface";
import {UploadVoiceFileRequest} from '../components/voice-recorder/voice-recorder.service';

export class PreloadResponse {
  checksum: string = '';
  createdAt: Moment;
  deletedAt: Moment | null;
  fileName: string = '';
  id: string = '';
  link: string = '';
  type: EHubFileType;
  updatedAt: Moment;
  constructor(data: IHubBasicUploadPhotoResponse | IHubBasicUploadVideoResponse) {
    this.checksum = data.checksum;
    this.createdAt = moment(data.createdAt);
    this.deletedAt = moment(data.deletedAt);
    this.fileName = data.fileName;
    this.id = data.id;
    this.link = data.link;
    this.type = data.type;
    this.updatedAt = moment(data.updatedAt);
  }
}

export interface FileUploadingBody {
  fileId: string;
  folderId: string;
}

@Injectable({
  providedIn: 'root'
})
export class HubService {
  apiUrl: string;
  folderUpdate = new Subject<any>();
  unassigned = new BehaviorSubject<FolderCreatorResponse | null>(null);

  constructor(private http: HttpClient) {
    this.apiUrl = environment.hubUrl;
  }

  async getPreBasic(files: UploadFileRequest[] | UploadVoiceFileRequest[] ): Promise<PreloadResponse[]> {
    const request = new PostRequest(this.http, `${this.apiUrl}/urls/get`, {
      data: files
    },);
    const response = await request.getResponse<Array<IHubBasicUploadResponse>>();
    return response.map(item => {
      switch (item.type) {
        case EHubFileType.PHOTO:
          return new PreloadResponse(item as IHubBasicUploadPhotoResponse);
        case EHubFileType.VIDEO:
          return new PreloadResponse(item as IHubBasicUploadVideoResponse);
        default:
          return new PreloadResponse(item as IHubBasicUploadPhotoResponse);
      }
    });
  }

  async getPreMultipartBasic(files: IHubMultipartUploadVideoRequest[] ): Promise<IHubMultipartUploadResponse[]> {
    const request = new PostRequest(this.http, `${this.apiUrl}/urls/get-multipart`, {
      data: files
    },);
    return await request.getResponse<Array<IHubMultipartUploadResponse>>();
  }

  async finalizeMultipart(data: any, folderId: string, socketId: string) {
    return new PostRequest(this.http, `${this.apiUrl}/urls/finalize`, data).getResponse();
  }

  uploadToAws(url: string, file: Blob) {
    return new Observable(observer => {
      fetch(
        url,
        {
          method: 'PUT',
          body: file,
          headers: {
            timeout: `${10 * 60 * 60}`,
          }
        }
      ).then(data => {
        observer.next(data);
        observer.complete();
      }).catch(err => observer.error(err));
    })
    // return this.http.put(url, file, {
    //   headers: {
    //     timeout: `${10 * 60 * 60}`,
    //   },
    //   reportProgress: true,
    // });
  }

  ///FOLDERS CREATOR
  async getFolders(dto: any, isContentCreator?: boolean): Promise<IListFolderCreatorResponse> {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(
      this.http,
      `${this.apiUrl}${path}/folders/list`,
      {
        page: dto.page,
        limit: dto.limit,
        withUnAssigned: true,
      }
      );
    return await request.getResponse<IListFolderCreatorResponse>();
  }

  async addFolder(name: string, isContentCreator?: boolean): Promise<IHubFolderCreatorResponse> {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(
      this.http,
      `${this.apiUrl}${path}/folders/create`,
      {
        name: name,
        fileIds: [],
      }
    );
    return await request.getResponse<IHubFolderCreatorResponse>();
  }

  async getFolder(folderId: string, isContentCreator?: boolean): Promise<IHubFolderCreatorResponse> {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(this.http, `${this.apiUrl}${path}/folders/get`, {
      id: folderId
    });
    return await request.getResponse<IHubFolderCreatorResponse>();
  }

  async removeFolder(folderId: string): Promise<any> {
    const request = new PostRequest(this.http, `${this.apiUrl}/creator/folders/remove`, {
      'id': folderId,
      'deleteWithFiles': true,
    });
    return await request.getResponse();
  }
  ///FILES CREATOR
  async getAllFiles(page: number, limit: number, folderId: string | null, isContentCreator?: boolean, type?: string[]): Promise<IHubListFileCreatorResponse> {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(this.http, `${this.apiUrl}${path}/files/list`, {
      page: page,
      limit: limit,
      folderId: folderId,
      type: type
    });

    return await request.getResponse<IHubListFileCreatorResponse>();
  }

  async finalizeUploading(data: FileUploadingBody[], socketId: string, isContentCreator?: boolean): Promise<IHubListFileCreatorResponse> {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(this.http, `${this.apiUrl}${path}/files/create`, {
      data: data,
      socketId: socketId,
    });
    return await request.getResponse<IHubListFileCreatorResponse>();
  }

  async getFileData(id: string): Promise<IHubFileCreatorResponse> {
    const request = new PostRequest(this.http, `${this.apiUrl}/creator/files/get`, {
      id: id,
    });
    return await request.getResponse<IHubFileCreatorResponse>();
  }

  async updateFileData(id: string): Promise<IHubFileCreatorResponse> {
    const request = new PostRequest(this.http, `${this.apiUrl}/creator/files/update`, {
      id: id,
    });
    return await request.getResponse<IHubFileCreatorResponse>();
  }

  async removeFile(ids: string[], isContentCreator?: boolean) {
    const path = isContentCreator ? '/creator' : '/user';
    const request = new PostRequest(this.http, `${this.apiUrl}${path}/files/remove`, {
      ids: ids,
      deleteWithFiles: true,
    });
    return await request.getResponse();
  }
}
