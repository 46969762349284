<ul class="folders-list">
  <li class="folders-list-item" *ngFor="let folder of folders"
    [ngClass]="{'active': selectedFolder == folder.id || (!folder.id && selectedFolder == 'all')}"
    (click)="onClick.emit(folder.id ?? '')" [contextMenu]="folder.id ? contextMenu : null" [contextMenuValue]="folder">
    <div class="content-list-data">
      <span class="action-text">{{folder.name}}</span>
      <div class="content-list-meta">
        <div class="content-list-meta-item">
          <app-icons name="image_1" [width]="17" [height]="17" fillColor="#929298"></app-icons>
          <span class="attention-text">{{folder.totalPhotos}}</span>
        </div>
        <div class="content-list-meta-item">
          <app-icons name="video_1" [width]="17" [height]="17" fillColor="#929298"></app-icons>
          <span class="attention-text">{{folder.totalVideos}}</span>
        </div>
      </div>
    </div>
    <div class="content-list-preview">
      <ul>
        <li *ngFor="let preview of folder.last | slice:0:3" [style.background-image]="decodeUrl(preview)"></li>
      </ul>
    </div>
  </li>
</ul>
<context-menu #contextMenu [menuClass]="'menuClass'">
  <ng-template contextMenuItem
    (execute)="execute($event)"><span>{{translationsService.translate(translationsService.keys.CONTENT_FOLDERS_LIST_BUTTON_DELETE)}}</span></ng-template>
</context-menu>