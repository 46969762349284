<ng-container>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4409_21136)">
      <path d="M20 3.87097C21.0689 3.87097 21.9355 3.00442 21.9355 1.93548C21.9355 0.866546 21.0689 0 20 0C18.9311 0 18.0645 0.866546 18.0645 1.93548C18.0645 3.00442 18.9311 3.87097 20 3.87097Z" fill="white"/>
      <path opacity="0.6" d="M20 39.9999C21.0689 39.9999 21.9355 39.1333 21.9355 38.0644C21.9355 36.9955 21.0689 36.1289 20 36.1289C18.9311 36.1289 18.0645 36.9955 18.0645 38.0644C18.0645 39.1333 18.9311 39.9999 20 39.9999Z" fill="white"/>
      <path opacity="0.1" d="M10.9677 6.25769C12.0367 6.25769 12.9032 5.39114 12.9032 4.3222C12.9032 3.25326 12.0367 2.38672 10.9677 2.38672C9.8988 2.38672 9.03226 3.25326 9.03226 4.3222C9.03226 5.39114 9.8988 6.25769 10.9677 6.25769Z" fill="white"/>
      <path opacity="0.7" d="M30.7097 34.7099C31.2258 35.6131 30.9032 36.7744 30 37.3551C29.0968 37.8712 27.9355 37.5486 27.3548 36.6454C26.8387 35.7422 27.1613 34.5809 28.0645 34.0002C28.9677 33.4841 30.1935 33.8067 30.7097 34.7099Z" fill="white"/>
      <path opacity="0.2" d="M5.29032 9.2898C6.19355 9.80593 6.51613 10.9672 6 11.935C5.48387 12.8382 4.32258 13.1608 3.35484 12.6446C2.45162 12.1285 2.12903 10.9672 2.64516 9.99948C3.16129 9.03174 4.3871 8.77367 5.29032 9.2898Z" fill="white"/>
      <path opacity="0.8" d="M36.6452 27.3557C37.5484 27.8718 37.871 29.0331 37.3548 30.0009C36.8387 30.9041 35.6774 31.2267 34.7097 30.7105C33.8065 30.1944 33.4839 29.0331 34 28.0654C34.5161 27.1621 35.6774 26.8396 36.6452 27.3557Z" fill="white"/>
      <path opacity="0.3" d="M1.93548 21.9354C3.00442 21.9354 3.87097 21.0689 3.87097 19.9999C3.87097 18.931 3.00442 18.0645 1.93548 18.0645C0.866546 18.0645 0 18.931 0 19.9999C0 21.0689 0.866546 21.9354 1.93548 21.9354Z" fill="white"/>
      <path opacity="0.9" d="M38.0645 21.9354C39.1335 21.9354 40 21.0689 40 19.9999C40 18.931 39.1335 18.0645 38.0645 18.0645C36.9956 18.0645 36.129 18.931 36.129 19.9999C36.129 21.0689 36.9956 21.9354 38.0645 21.9354Z" fill="white"/>
      <path opacity="0.4" d="M3.35483 27.3557C4.25806 26.8396 5.41935 27.1621 6 28.0654C6.51613 28.9686 6.19354 30.1299 5.29032 30.7105C4.38709 31.2267 3.2258 30.9041 2.64516 30.0009C2.06451 29.0976 2.45161 27.9363 3.35483 27.3557Z" fill="white"/>
      <path d="M34.7097 9.29124C35.6129 8.77511 36.7742 9.0977 37.3548 10.0009C37.871 10.9041 37.5484 12.0654 36.6452 12.6461C35.7419 13.1622 34.5806 12.8396 34 11.9364C33.4839 11.0332 33.8065 9.80737 34.7097 9.29124Z" fill="white"/>
      <path opacity="0.5" d="M9.29032 34.7099C9.80645 33.8067 10.9677 33.4841 11.9355 34.0002C12.8387 34.5164 13.1613 35.6776 12.6452 36.6454C12.129 37.5486 10.9677 37.8712 10 37.3551C9.09677 36.7744 8.77419 35.6131 9.29032 34.7099Z" fill="white"/>
      <path d="M27.3548 3.35444C27.871 2.45121 29.0323 2.12863 30 2.64476C30.9032 3.16089 31.2258 4.32218 30.7097 5.28992C30.1935 6.19315 29.0323 6.51573 28.0645 5.9996C27.1613 5.48347 26.8387 4.32218 27.3548 3.35444Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4409_21136">
        <rect width="40" height="40" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</ng-container>
