<section id="create-post" id="create-post">
  <form class="form" [formGroup]="postForm" (ngSubmit)="submitForm()">
    <ng-container *ngIf="dateTimePickerInput">
      <ng-container *ngTemplateOutlet="delayDateTime"></ng-container>
    </ng-container>
    <ng-container *ngIf="selectedFiles.length">
      <ng-container *ngTemplateOutlet="uploadedPhotoRef"></ng-container>
    </ng-container>
    <ng-container *ngIf="activePriceInput && this.selectedFiles.length">
      <ng-container *ngTemplateOutlet="priceSectionRef"></ng-container>
      <ng-container *ngIf="!forMessage">
        <ng-container *ngTemplateOutlet="privacySectionRef"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="activeTagInput">
      <ng-container *ngTemplateOutlet="tagSectionRef"></ng-container>
    </ng-container>

    <textarea id="textAreaElement" #textAreaElement autoGrow [maxHeight]="350" formControlName="text" maxlength="1000"
      [attr.placeholder]="translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_TEXT_AREA_COMPOSE_NEW_POST)"
      type="text" class="post-description"></textarea>
    <div *ngIf="forComment || forLooks" class="comments-content-item">
      <span
        class="text">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_TURN_OFF_COMMENTS)}}</span>
      <input type="checkbox" formControlName="commentsEnabled">
    </div>
    <app-audio-message *ngIf="audioFile" [audioFileUrl]="audioFile" [showDeleteButton]="true"
      (onRemoveMessageEvent)="removeVoiceMessage()"></app-audio-message>

    <div class="form-upload-submit" *ngIf="!hideControls">
      <ng-container *ngIf="!post || hasDelayDate">
        <app-smiles [isBottom]="!post && !forMessage || forTemplate"
          (emojiSelected)="onEmojiSelected($event)"></app-smiles>
        <button #button type="button" class="btn btn-s-icon" (click)="uploadFile()">
          <app-icons name="content_hub" [width]="20" [height]="20" fillColor="#929298"></app-icons>
        </button>
        <button #button type="button" class="btn btn-s-icon" (click)="uploadPhoto($event)">
          <app-icons name="image_1" [width]="20" [height]="20" fillColor="#929298"></app-icons>
        </button>
        <button #button type="button" class="btn btn-s-icon" (click)="uploadVideo()">
          <app-icons name="video_1" [width]="20" [height]="20" fillColor="#929298"></app-icons>
        </button>
        <button #button *ngIf="hasPrice" [disabled]="!this.selectedFiles.length" type="button" class="btn btn-s-icon"
          (click)="addPrice()">
          <app-icons name="price" [width]="20" [height]="20" fillColor="#929298"></app-icons>
        </button>
        <button #button type="button" class="btn btn-s-icon" *ngIf="!forMessage" (click)="addTagsRef()">
          <app-icons name="tag_1" [width]="20" [height]="20" fillColor="#929298"></app-icons>
        </button>
        <button #button *ngIf="delayDateRequired || hasDelayDate" (click)="addDateInputForm()" type="button"
          class="btn btn-s-icon" [owlDateTimeTrigger]="datetimepicker">
          <app-icons name="schedule_message" [width]="20" [height]="20"
            [fillColor]="selectedDateTime != null ? '#6A49FA' : '#929298'"></app-icons>
          <input style="display: none" [owlDateTime]="datetimepicker" [min]="dateTimeNow"
            (dateTimeChange)="addDelayDate($event)" [(ngModel)]="selectedDateTime"
            [ngModelOptions]="{standalone: true}">
          <owl-date-time #datetimepicker [pickerMode]="'dialog'" [hour12Timer]="is12HoursUserFormat()"
            [startAt]="time"></owl-date-time>
        </button>
        <app-voice-recorder *ngIf="!forMessage" (voiceMessage)="addVoiceMessage($event)"></app-voice-recorder>

      </ng-container>

      <div class="form-submit">
        <button #sendBtnRef class="btn btn-send capitalize" *ngIf="!forTemplate" type="button" (click)="submitForm()"
          [disabled]="!postForm.get('text')?.value &&
                (!this.selectedFiles.length && !audioFile) ||
                (isLoading | async)">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_BUTTON_SEND)}}
        </button>
      </div>
    </div>

    <!-- photo preview -->
    <ng-template #uploadedPhotoRef>
      <div class="uploaded uploaded-container">
        <ng-container *ngFor="let file of selectedFiles; let i = index">
          <div *ngIf="file.file.type !== 'VOICE'" class="uploaded-photo">
            <img [ngSrc]="decodeUrl(file)" fill alt="photo" class="cover-fit">
            <button class="btn btn-primary btn-round btn-m-icon" (click)="removeSelectedFile(i)">
              <app-icons name="close_2" [width]="14" [height]="14" fillColor="#F5F5F5"></app-icons>
            </button>
          </div>
        </ng-container>
        <button type="button" class="btn btn-large-upload" (click)="uploadFile()">
          <app-icons name="plus" [width]="40" [height]="40" fillColor="#6A49FA"></app-icons>
        </button>
      </div>
    </ng-template>
    <!-- set price section -->
    <ng-template #priceSectionRef>
      <div class="price">
        <div class="price-header">
          <span>{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_MESSAGE_PRICE)}}</span>
          <button *ngIf="!post && !forMessage || !forMessage" type="button" class="btn btn-xs-icon"
            (click)="hidePriceSection()">
            <app-icons name="close_2" [width]="14" [height]="14" fillColor="#6A49FA"></app-icons>
          </button>
        </div>
        <div class="input-container">
          <div class="prefix-container">
            <app-icons name="donate_1" [width]="20" [height]="20" fillColor="#929298"
              class="prefix prefix-icon"></app-icons>
            <input formControlName="price" mask="separator.2" maxlength="9" thousandSeparator=" " allowNegative="false"
              [placeholder]="translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_INPUT_FREE)"
              type="text" class="price-input contrast">
          </div>
        </div>
        <span class="hint text-color-secondary">{{translationsService.translate(getPriceHintMessage)}}</span>
      </div>
    </ng-template>

    <!-- tags section -->
    <ng-template #tagSectionRef>
      <div class="price">
        <div class="price-header">
          <span>{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_TAGS)}} <span
              class="text-color-secondary">{{tagsList.length}}/{{maxPostTags}}</span></span>
          <button *ngIf="!post && !forMessage && !forLooks || !forMessage && !forLooks" type="button"
            class="btn btn-xs-icon" (click)="hideTagSection()">
            <app-icons name="close_2" [width]="14" [height]="14" fillColor="#6A49FA"></app-icons>
          </button>
        </div>
        <div class="selected-tags">
          <app-tag *ngFor="let tag of tagsList; let i = index" [tagName]="tag" [isEditable]="true"
            (removeTag)="removeTag(i)"></app-tag>
        </div>
        <div class="input-container">
          <div class="suffix-container">
            <span class="suffix">
              <button (click)="addTag($event)"
                [disabled]="!postForm.get('tag')?.value || tagsList.includes(this.postForm.get('tag')?.value)">
                <app-icons name="plus" [width]="20" [height]="20"
                  [fillColor]="postForm.get('tag')?.value ?  '#6A49FA' : '#929298'"
                  class="prefix prefix-icon"></app-icons>
              </button>
            </span>
            <input formControlName="tag" noSpaces (keydown.enter)="addTag($event)" maxlength="30"
              [placeholder]="translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_INPUT_TYPE_SOMETHING)"
              type="text" class="price-input contrast">
          </div>
        </div>
        <span class="hint text-color-secondary" *ngIf="recommendationTagsList.length">Tag recommendation</span>
        <div class="recommended-tags">
          <app-tag *ngFor="let tag of recommendationTagsList" [tagName]="tag" [isRecommendation]="true"
            (selectTag)="addSelectedTag($event)"></app-tag>
        </div>
      </div>
    </ng-template>

    <!-- Privacy settings section -->
    <ng-template #privacySectionRef>
      <div class="price">
        <div class="price-header">
          <span
            class="attention-text">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_PRIVACY_SETTINGS)}}</span>
        </div>
        <div class="input-container">
          <div class="checkbox-content-item">
            <div class="label-wrapper">
              <span
                class="text-color-purple attention-text text-color-secondary capitalize">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_PUBLIC)}}</span>
              <span
                class="text text-color-secondary">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_THIS_POST_WILL_BE_VISIBLE_TO)}}
                <span
                  class="highlighted">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_ALL_USERS)}}</span>
                {{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_AND)}} <span
                  class="highlighted">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_YOUR_SUBSCRIBERS)}}</span></span>
            </div>
            <input type="checkbox" [checked]="postForm.get('access')?.value === 'free'"
              (change)="selectCheckbox(postAccess.free)">
          </div>
        </div>
        <div class="divider"></div>
        <div class="input-container">
          <div class="checkbox-content-item">
            <div class="label-wrapper">
              <span
                class="text-color-purple attention-text text-color-secondary">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_SUBSCRIBERS_ONLY)}}</span>
              <span
                class="text text-color-secondary">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_THIS_POST_WILL_BE_VISIBLE_TO)}}
                <span
                  class="highlighted">{{translationsService.translate(translationsService.keys.CREATORS_CREATORS_POST_SPAN_YOUR_SUBSCRIBERS)}}</span></span>
            </div>
            <input type="checkbox" [checked]="postForm.get('access')?.value === 'subscribers'"
              (change)="selectCheckbox(postAccess.subscribers)">
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Selected delay date-->
    <ng-template #delayDateTime>
      <div class="delayDateContainer">
        <app-icons name="schedule_message" [width]="20" [height]="20" [fillColor]="'#6A49FA'"></app-icons>
        <div class="delayDateContainer-input">
          <app-icons name="calendar" [width]="20" [height]="20" [fillColor]="'#929298'"></app-icons>
          <input [owlDateTimeTrigger]="datetimepicker" type="text" [disabled]="true" [value]="selectedDateText">
        </div>
        <div class="delayDateContainer-input">
          <app-icons name="clock_1" [width]="20" [height]="20" [fillColor]="'#929298'"></app-icons>
          <input [owlDateTimeTrigger]="datetimepicker" type="text" [disabled]="true" [value]="selectedTimeText">
        </div>

        <button *ngIf="!delayDateRequired" type="button" class="btn btn-xs-icon" (click)="hideDatePickerSection()">
          <app-icons name="close_2" [width]="14" [height]="14" fillColor="#6A49FA"></app-icons>
        </button>
      </div>

    </ng-template>
  </form>
  <app-content-selector #selectFile (onSelect)="onFileSelect($event)"></app-content-selector>
</section>