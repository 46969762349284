import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformService } from './platform.service';

interface Icon {
	path: string;
	name: string;
}

@Injectable({
	providedIn: 'root',
})
export class SvgIconService {
	private icons: { [key: string]: any } = {};
	private iconList = [
		{
			path: '/assets/svg/optimize-icons/01_hair_color.svg',
			name: '01_hair_color',
		},
		{
			path: '/assets/svg/optimize-icons/02_eye_color.svg',
			name: '02_eye_color',
		},
		{
			path: '/assets/svg/optimize-icons/03_body_type.svg',
			name: '03_body_type',
		},
		{
			path: '/assets/svg/optimize-icons/04_skin_tone.svg',
			name: '04_skin_tone',
		},
		{
			path: '/assets/svg/optimize-icons/05_hair_length.svg',
			name: '05_hair_length',
		},
		{
			path: '/assets/svg/optimize-icons/06_hair_style.svg',
			name: '06_hair_style',
		},
		{ path: '/assets/svg/optimize-icons/07_height.svg', name: '07_height' },
		{ path: '/assets/svg/optimize-icons/08_tattoo.svg', name: '08_tattoo' },
		{ path: '/assets/svg/optimize-icons/09_place.svg', name: '09_place' },
		{ path: '/assets/svg/optimize-icons/10_gender.svg', name: '10_gender' },
		{ path: '/assets/svg/optimize-icons/11_ass.svg', name: '11_ass' },
		{ path: '/assets/svg/optimize-icons/12_breast.svg', name: '12_breast' },
		{ path: '/assets/svg/optimize-icons/13_dick.svg', name: '13_penis' },
		{ path: '/assets/svg/optimize-icons/dirty_talk.svg', name: 'dirty_talk' },
		{ path: '/assets/svg/optimize-icons/dirty_talk.svg', name: 'sex' },
		{ path: '/assets/svg/optimize-icons/address.svg', name: 'address' },
		{ path: '/assets/svg/optimize-icons/all_type.svg', name: 'all_type' },
		{
			path: '/assets/svg/optimize-icons/american_express.svg',
			name: 'AmericanExpress',
		},
		{ path: '/assets/svg/optimize-icons/anal.svg', name: 'anal' },
		{ path: '/assets/svg/optimize-icons/apple_pay.svg', name: 'ApplePay' },
		{ path: '/assets/svg/optimize-icons/arrow.svg', name: 'arrow' },
		{ path: '/assets/svg/optimize-icons/arrow_2.svg', name: 'arrow_2' },
		{ path: '/assets/svg/optimize-icons/arrow_small.svg', name: 'arrow_small' },
		{ path: '/assets/svg/optimize-icons/asmr.svg', name: 'asmr' },
		{ path: '/assets/svg/optimize-icons/booking.svg', name: 'booking' },
		{ path: '/assets/svg/optimize-icons/ass.svg', name: 'butt' },
		{ path: '/assets/svg/optimize-icons/attachment.svg', name: 'attachment' },
		{ path: '/assets/svg/optimize-icons/attention.svg', name: 'attention' },
		{ path: '/assets/svg/optimize-icons/bdsm.svg', name: 'bdsm' },
		{ path: '/assets/svg/optimize-icons/body_type.svg', name: 'body_type' },
		{ path: '/assets/svg/optimize-icons/boost.svg', name: 'boost' },
		{ path: '/assets/svg/optimize-icons/breast.svg', name: 'breasts' },
		{
			path: '/assets/svg/optimize-icons/broken_heart.svg',
			name: 'broken_heart',
		},
		{ path: '/assets/svg/optimize-icons/heart_1.svg', name: 'heart_1' },
		{ path: '/assets/svg/optimize-icons/calendar.svg', name: 'calendar' },
		{ path: '/assets/svg/optimize-icons/call_end.svg', name: 'call_end' },
		{ path: '/assets/svg/optimize-icons/call_income.svg', name: 'call_income' },
		{ path: '/assets/svg/optimize-icons/chat.svg', name: 'chat' },
		{ path: '/assets/svg/optimize-icons/clock.svg', name: 'clock' },
		{ path: '/assets/svg/optimize-icons/close.svg', name: 'close' },
		{ path: '/assets/svg/optimize-icons/close_1.svg', name: 'close_1' },
		{ path: '/assets/svg/optimize-icons/close_2.svg', name: 'close_2' },
		{ path: '/assets/svg/optimize-icons/comment.svg', name: 'comment' },
		{ path: '/assets/svg/optimize-icons/content.svg', name: 'content' },
		{ path: '/assets/svg/optimize-icons/content_hub.svg', name: 'content_hub' },
		{ path: '/assets/svg/optimize-icons/cookie.svg', name: 'cookie' },
		{ path: '/assets/svg/optimize-icons/cooking.svg', name: 'cooking' },
		{ path: '/assets/svg/optimize-icons/coin.svg', name: 'coin' },
		{ path: '/assets/svg/optimize-icons/cosplay.svg', name: 'cosplay' },
		{ path: '/assets/svg/optimize-icons/cosplay.svg', name: 'solo' },
		{ path: '/assets/svg/optimize-icons/couples.svg', name: 'couples' },
		{ path: '/assets/svg/optimize-icons/couples.svg', name: 'group' },
		{ path: '/assets/svg/optimize-icons/dancing.svg', name: 'dancing' },
		{ path: '/assets/svg/optimize-icons/deep_throat.svg', name: 'deep_throat' },
		{
			path: '/assets/svg/optimize-icons/desiren_icon.svg',
			name: 'desiren_icon',
		},
		{ path: '/assets/svg/optimize-icons/dick.svg', name: 'penis' },
		{ path: '/assets/svg/optimize-icons/discover.svg', name: 'Discover' },
		{ path: '/assets/svg/optimize-icons/donate.svg', name: 'donate' },
		{ path: '/assets/svg/optimize-icons/donate_1.svg', name: 'donate_1' },
		{ path: '/assets/svg/optimize-icons/dots_menu.svg', name: 'dots_menu' },
		{ path: '/assets/svg/optimize-icons/double.svg', name: 'double' },
		{
			path: '/assets/svg/optimize-icons/drive_licence.svg',
			name: 'drive_licence',
		},
		{ path: '/assets/svg/optimize-icons/edit.svg', name: 'edit' },
		{ path: '/assets/svg/optimize-icons/education.svg', name: 'education' },
		{
			path: '/assets/svg/optimize-icons/exhibitionism.svg',
			name: 'exhibitionism',
		},
		{ path: '/assets/svg/optimize-icons/eye.svg', name: 'eye' },
		{ path: '/assets/svg/optimize-icons/eye_1.svg', name: 'eye_1' },
		{ path: '/assets/svg/optimize-icons/eye_closed.svg', name: 'eye_closed' },
		{ path: '/assets/svg/optimize-icons/eye_color.svg', name: 'eye_color' },
		{ path: '/assets/svg/optimize-icons/facebook.svg', name: 'facebook' },
		{ path: '/assets/svg/optimize-icons/facetime.svg', name: 'facetime' },
		{ path: '/assets/svg/optimize-icons/ffm.svg', name: 'ffm' },
		{ path: '/assets/svg/optimize-icons/ffm.svg', name: 'fmm' },
		{ path: '/assets/svg/optimize-icons/favorite.svg', name: 'favorite' },
		{
			path: '/assets/svg/optimize-icons/favorite_fill.svg',
			name: 'favorite_fill',
		},
		{ path: '/assets/svg/optimize-icons/female.svg', name: 'female' },
		{ path: '/assets/svg/optimize-icons/filter.svg', name: 'filter' },
		{ path: '/assets/svg/optimize-icons/fire.svg', name: 'fire' },
		{ path: '/assets/svg/optimize-icons/foot_fetish.svg', name: 'foot_fetish' },
		{ path: '/assets/svg/optimize-icons/gender.svg', name: 'gender' },
		{ path: '/assets/svg/optimize-icons/geo.svg', name: 'geo' },
		{ path: '/assets/svg/optimize-icons/gg_content.svg', name: 'gg_content' },
		{ path: '/assets/svg/optimize-icons/gagging.svg', name: 'gagging' },
		{ path: '/assets/svg/optimize-icons/google.svg', name: 'google' },
		{ path: '/assets/svg/optimize-icons/hair_color.svg', name: 'hair_color' },
		{ path: '/assets/svg/optimize-icons/hair_length.svg', name: 'hair_length' },
		{ path: '/assets/svg/optimize-icons/hair_style.svg', name: 'hair_style' },
		{ path: '/assets/svg/optimize-icons/heart.svg', name: 'heart' },
		{ path: '/assets/svg/optimize-icons/heart_fill.svg', name: 'heart_fill' },
		{ path: '/assets/svg/optimize-icons/heart_lock.svg', name: 'heart_lock' },
		{ path: '/assets/svg/optimize-icons/height.svg', name: 'height' },
		{ path: '/assets/svg/optimize-icons/home.svg', name: 'home' },
		{ path: '/assets/svg/optimize-icons/home_1.svg', name: 'home_1' },
		{ path: '/assets/svg/optimize-icons/horses.svg', name: 'horses' },
		{ path: '/assets/svg/optimize-icons/history.svg', name: 'history' },
		{ path: '/assets/svg/optimize-icons/id_card.svg', name: 'id_card' },
		{ path: '/assets/svg/optimize-icons/image.svg', name: 'image' },
		{ path: '/assets/svg/optimize-icons/image_1.svg', name: 'image_1' },
		{ path: '/assets/svg/optimize-icons/instagram.svg', name: 'instagram' },
		{ path: '/assets/svg/optimize-icons/jcb.svg', name: 'jcb' },
		{ path: '/assets/svg/optimize-icons/latex.svg', name: 'latex' },
		{ path: '/assets/svg/optimize-icons/layout_grid.svg', name: 'layout_grid' },
		{ path: '/assets/svg/optimize-icons/layout_line.svg', name: 'layout_line' },
		{ path: '/assets/svg/optimize-icons/list.svg', name: 'list' },
		{ path: '/assets/svg/optimize-icons/lists.svg', name: 'lists' },
		{ path: '/assets/svg/optimize-icons/lock.svg', name: 'lock' },
		{ path: '/assets/svg/optimize-icons/logout.svg', name: 'logout' },
		{ path: '/assets/svg/optimize-icons/looks.svg', name: 'looks' },
		{ path: '/assets/svg/optimize-icons/looks-like.svg', name: 'looks-like' },
		{ path: '/assets/svg/optimize-icons/logo_static.svg', name: 'logo_static' },
		{ path: '/assets/svg/optimize-icons/male.svg', name: 'male' },
		{ path: '/assets/svg/optimize-icons/mastercard.svg', name: 'MasterCard' },
		{
			path: '/assets/svg/optimize-icons/mastercard_card.svg',
			name: 'MasterCard_card',
		},
		{ path: '/assets/svg/optimize-icons/message.svg', name: 'message' },
		{ path: '/assets/svg/optimize-icons/messages.svg', name: 'messages' },
		{ path: '/assets/svg/optimize-icons/microphone.svg', name: 'microphone' },
		{
			path: '/assets/svg/optimize-icons/microphone_1.svg',
			name: 'microphone_1',
		},
		{ path: '/assets/svg/optimize-icons/more.svg', name: 'more' },
		{ path: '/assets/svg/optimize-icons/mute.svg', name: 'mute' },
		{ path: '/assets/svg/optimize-icons/non_read.svg', name: 'non_read' },
		{
			path: '/assets/svg/optimize-icons/notifications.svg',
			name: 'notifications',
		},
		{ path: '/assets/svg/optimize-icons/passport.svg', name: 'passport' },
		{ path: '/assets/svg/optimize-icons/paypal.svg', name: 'paypal' },
		{ path: '/assets/svg/optimize-icons/pause.svg', name: 'pause' },
		{ path: '/assets/svg/optimize-icons/place.svg', name: 'place' },
		{ path: '/assets/svg/optimize-icons/play.svg', name: 'play' },
		{ path: '/assets/svg/optimize-icons/plus.svg', name: 'plus' },
		{ path: '/assets/svg/optimize-icons/post.svg', name: 'post' },
		{ path: '/assets/svg/optimize-icons/price.svg', name: 'price' },
		{ path: '/assets/svg/optimize-icons/profile.svg', name: 'profile' },
		{ path: '/assets/svg/optimize-icons/racing.svg', name: 'racing' },
		{ path: '/assets/svg/optimize-icons/read.svg', name: 'read' },
		{ path: '/assets/svg/optimize-icons/referral.svg', name: 'referral' },
		{ path: '/assets/svg/optimize-icons/roleplay.svg', name: 'role_play' },
		{ path: '/assets/svg/optimize-icons/scale.svg', name: 'scale' },
		{
			path: '/assets/svg/optimize-icons/schedule_message.svg',
			name: 'schedule_message',
		},
		{ path: '/assets/svg/optimize-icons/search.svg', name: 'search' },
		{ path: '/assets/svg/optimize-icons/settings.svg', name: 'settings' },
		{
			path: '/assets/svg/optimize-icons/settings_slider.svg',
			name: 'settings_slider',
		},
		{ path: '/assets/svg/optimize-icons/share.svg', name: 'share' },
		{ path: '/assets/svg/optimize-icons/skin_tone.svg', name: 'skin_tone' },
		{ path: '/assets/svg/optimize-icons/smile.svg', name: 'smile' },
		{ path: '/assets/svg/optimize-icons/sound_wave.svg', name: 'sound_wave' },
		{ path: '/assets/svg/optimize-icons/sports.svg', name: 'sports' },
		{ path: '/assets/svg/optimize-icons/statements.svg', name: 'statements' },
		{ path: '/assets/svg/optimize-icons/statistic.svg', name: 'statistic' },
		{ path: '/assets/svg/optimize-icons/stripe.svg', name: 'stripe' },
		{ path: '/assets/svg/optimize-icons/striptease.svg', name: 'striptease' },
		{
			path: '/assets/svg/optimize-icons/subscriptions.svg',
			name: 'subscriptions',
		},
		{ path: '/assets/svg/optimize-icons/tag.svg', name: 'tag' },
		{ path: '/assets/svg/optimize-icons/tag_1.svg', name: 'tag_1' },
		{ path: '/assets/svg/optimize-icons/tattoo.svg', name: 'tattoo' },
		{ path: '/assets/svg/optimize-icons/text_post.svg', name: 'text_post' },
		{ path: '/assets/svg/optimize-icons/tiktok.svg', name: 'tiktok' },
		{ path: '/assets/svg/optimize-icons/toys.svg', name: 'toys' },
		{ path: '/assets/svg/optimize-icons/trash.svg', name: 'trash' },
		{ path: '/assets/svg/optimize-icons/twitter.svg', name: 'twitter' },
		{ path: '/assets/svg/optimize-icons/upload.svg', name: 'upload' },
		{ path: '/assets/svg/optimize-icons/upload_2.svg', name: 'upload_2' },
		{ path: '/assets/svg/optimize-icons/vector.svg', name: 'vector' },
		{ path: '/assets/svg/optimize-icons/verify.svg', name: 'verify' },
		{ path: '/assets/svg/optimize-icons/video.svg', name: 'video' },
		{ path: '/assets/svg/optimize-icons/video_1.svg', name: 'video_1' },
		{ path: '/assets/svg/optimize-icons/video_games.svg', name: 'video_games' },
		{ path: '/assets/svg/optimize-icons/video_off.svg', name: 'video_off' },
		{ path: '/assets/svg/optimize-icons/visa.svg', name: 'Visa' },
		{ path: '/assets/svg/optimize-icons/visa_card.svg', name: 'Visa_card' },
		{ path: '/assets/svg/optimize-icons/vlogs.svg', name: 'vlogs' },
		{ path: '/assets/svg/optimize-icons/volume.svg', name: 'volume' },
		{ path: '/assets/svg/optimize-icons/wallet.svg', name: 'wallet' },
		{ path: '/assets/svg/optimize-icons/dick.svg', name: 'dildo' },
		//
		{ path: '/assets/svg/optimize-icons/looks/chat-selected.svg', name: 'chat-selected' },
		{ path: '/assets/svg/optimize-icons/looks/chat-hover.svg', name: 'chat-hover' },
		{ path: '/assets/svg/optimize-icons/looks/looks-hovered-like.svg', name: 'looks-hovered-like' },
		{ path: '/assets/svg/optimize-icons/looks/looks-liked.svg', name: 'looks-liked' },
		// match
		{
			path: '/assets/svg/optimize-icons/perfectMatch.svg',
			name: 'perfectMatch',
		},
		{
			path: '/assets/svg/optimize-icons/greatConnection.svg',
			name: 'greatConnection',
		},
		{
			path: '/assets/svg/optimize-icons/potentialSpark.svg',
			name: 'potentialSpark',
		},
	];

	isBrowser = false;
	private iconPromises: { [key: string]: Promise<any> } = {};

	constructor(private domSanitizer: DomSanitizer, private platform: PlatformService) {
		this.isBrowser = platform.isBrowser;
	}

	private loadIcon(name: string, path: string): Promise<any> {
		if (!this.iconPromises[name]) {
			this.iconPromises[name] = fetch(path)
				.then((response) => response.text())
				.then((svgContent) => {
					const sanitizedIcon = this.domSanitizer.bypassSecurityTrustHtml(svgContent);
					this.icons[name] = sanitizedIcon;
					return sanitizedIcon;
				});
		}
		return this.iconPromises[name];
	}

	public async getIcon(name: string): Promise<string> {
		if (this.icons[name]) {
			return Promise.resolve(this.icons[name]);
		}

		const icon = this.iconList.find((icon) => icon.name === name);
		if (icon && this.isBrowser) {
			return this.loadIcon(name, icon.path);
		}

		return Promise.resolve(this.icons['logo_static'] || null);
	}
}
