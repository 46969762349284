import { Directive, ElementRef, inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { decode } from 'blurhash';
import { PlatformService } from '../service/platform.service';

@Directive({
	selector: '[appBlurhash]',
	standalone: true,
})
export class BlurHashDirective implements OnChanges {
	private readonly platform: PlatformService = inject(PlatformService);

	@Input() appBlurhash!: string;
	@Input() src!: string;
	@Input() ngSrc!: string;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.platform.isBrowser) return;

		if (changes['appBlurhash'] && this.appBlurhash) {
			this.applyBlurhash();
		}

		if (changes['src'] && this.src) {
			this.loadImage(this.src);
		} else if (changes['ngSrc'] && this.ngSrc) {
			this.loadImage(this.ngSrc);
		}
	}

	private applyBlurhash(): void {
		const imgElement = this.el.nativeElement as HTMLImageElement;
		const { width, height } = imgElement.getBoundingClientRect();
		const canvasWidth = 64;
		const canvasHeight = 64;

		const pixels = decode(this.appBlurhash, 64, 64);
		const canvas = document.createElement('canvas');
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		const ctx = canvas.getContext('2d');
		if (ctx) {
			const imageData = ctx.createImageData(64, 64);
			imageData.data.set(Uint8ClampedArray.from(pixels));
			ctx.putImageData(imageData, 0, 0);

			const blurhashBase64 = canvas.toDataURL();
			this.renderer.setAttribute(imgElement, 'src', blurhashBase64);
		}
	}

	private loadImage(imageUrl: string): void {
		const imgElement = this.el.nativeElement as HTMLImageElement;
		const preloader = new Image();
		preloader.src = imageUrl;

		preloader.onload = () => {
			this.renderer.setAttribute(imgElement, 'src', imageUrl);
		};
	}
}
