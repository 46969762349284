import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationsService } from '../../service/translations/translations.service';

@Component({
	selector: 'app-read-more-less',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './read-more-less.component.html',
	styleUrl: './read-more-less.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreLessComponent implements AfterViewInit {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	private sntzr = inject(DomSanitizer);
	private crd = inject(ChangeDetectorRef);
	public content: string = '';
	public contentHtml: SafeHtml = '';
	public isExpanded: boolean = false;
	public expanded: boolean = true;
	@Input() class: string[] = [''];
	@Input() alignContentCenter: boolean = false;
	@Input() contentLimit: number = 100;
	@Input() lines: number = 6;
	@Input() set description(value: string) {
		this.isExpanded = false;
		this.expanded = true;
		this.content = value;
		this.contentHtml = this.sntzr.bypassSecurityTrustHtml(this.content);
	}
	@ViewChild('textContainer') textContainer: ElementRef<HTMLDivElement>;

	ngAfterViewInit() {
		if (this.textContainer?.nativeElement.scrollHeight > 120) {
			this.isExpanded = true;
			this.expanded = false;
			this.crd.detectChanges();
		}
	}
}
