"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESTRICTIONS = void 0;
exports.RESTRICTIONS = {
  PASSWORD: {
    MIN_LENGTH: 8,
    MAX_LENGTH: 32
  }
};