import {CommonModule, isPlatformBrowser} from '@angular/common';
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {IconsComponent} from 'src/app/uikit/icons/icons.component';
import WaveSurfer from 'wavesurfer.js';
import {VoiceRecorderService} from '../voice-recorder/voice-recorder.service';
import {ActivatedRoute} from '@angular/router';
import {filter, take} from 'rxjs';

@Component({
  selector: 'app-audio-message',
  standalone: true,
  imports: [CommonModule, IconsComponent],
  templateUrl: './audio-message.component.html',
  styleUrl: './audio-message.component.scss',
})
export class AudioMessageComponent implements OnInit, AfterViewInit, OnDestroy {
  public wave: WaveSurfer;
  public isPlaying: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Input() isDisabled: boolean = true;
  @ViewChild('waveform', {static: false}) waveformRef: ElementRef;
  @ViewChild('message', {static: true}) message: ElementRef;
  @Output() onRemoveMessageEvent: EventEmitter<Event> = new EventEmitter();
  private audioFile: string;
  private isBrowser: boolean;
  private postId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private voiceRecordService: VoiceRecorderService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    afterNextRender(() => {
      if (!this.isDisabled) {
        this.initIntersection();
      }
    });
  }

  @Input() set audioFileUrl(value: string) {
    this.isDisabled = !value;
    if (!value && this.isBrowser) {
      this.generateFakeWaveform();
      return;
    }
    this.audioFile = value;
    if (this.isBrowser) {
      this.initWave();
    }
  }

  ngOnInit(): void {
    this.voiceRecordService.voiceMessagePauseEvent$.subscribe((data) => {
      if (this.wave.isPlaying()) {
        this.wave.pause();
        this.isPlaying = false;
        this.cdr.detectChanges();
      }
    });
    this.route.paramMap
      .pipe(
        filter((i) => !!i),
        take(1)
      )
      .subscribe((params) => {
        this.postId = params.get('postId');
      });
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) this.initWave();
  }

  ngOnDestroy() {
    if (this.wave) {
      this.wave.destroy();
    }
  }

  public audioControl(event: Event) {
    event.preventDefault();
    if (this.isDisabled) return;
    if (this.wave.isPlaying()) {
      this.wave.pause();
    } else {
      this.voiceRecordService.voiceMessagePauseEvent.next();
      this.wave.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  private initWave() {
    if (!this.waveformRef) return;
    if (this.wave) this.wave.destroy();
    this.wave = WaveSurfer?.create({
      container: this.waveformRef.nativeElement,
      waveColor: '#DEDEEB',
      progressColor: '#6A49FA',
      cursorColor: 'transparent',
      cursorWidth: 3,
      barWidth: 5,
      barHeight: 1,
      height: 50,
      barGap: 2,
      barRadius: 50,
    });
    this.wave.load(this.audioFile);
    this.wave.on('seeking', () => {
      this.voiceRecordService.voiceMessagePauseEvent.next();
      this.wave.play();
      this.isPlaying = true;
      this.cdr.detectChanges();
    });

    this.wave.on('finish', () => {
      this.isPlaying = false;
      this.cdr.detectChanges();
    });
  }

  private initIntersection(): void {
    if (!!this.postId) return;
    if (this.message) {
      const yContainer = document.querySelector('.body');
      this.intersectionObserver(yContainer, this.message);
    }
  }

  private intersectionObserver(root: any, observedItemRef: ElementRef) {
    const options = {
      root: root,
      rootMargin: '0px',
      threshold: [0.5],
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (this.message && !entry.isIntersecting) {
          this.wave.pause();
          this.isPlaying = false;
          this.cdr.detectChanges();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    return observer.observe(observedItemRef.nativeElement);
  }

  private generateFakeWaveform(): void {
    const fakeData = this.generateFakeData(1000);
    const audioBlob = this.audioBufferToWaveBlob(fakeData);
    const audioUrl = URL.createObjectURL(audioBlob);
    this.audioFile = audioUrl;
    this.initWave();
  }

  private generateFakeData(length: number): AudioBuffer {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const sampleRate = audioContext.sampleRate;
    const buffer = audioContext.createBuffer(1, length, sampleRate);
    const channelData = buffer.getChannelData(0);

    for (let i = 0; i < length; i++) {
      channelData[i] = Math.random() * 2 - 1;
    }

    return buffer;
  }

  private audioBufferToWaveBlob(buffer: AudioBuffer): Blob {
    const numOfChan = buffer.numberOfChannels,
      length = buffer.length * numOfChan * 2 + 44,
      bufferData = new ArrayBuffer(length),
      view = new DataView(bufferData),
      channels = [],
      sampleRate = buffer.sampleRate;

    let offset = 0;
    let pos = 0;

    const setUint16 = (data) => {
      view.setUint16(pos, data, true);
      pos += 2;
    };
    const setUint32 = (data) => {
      view.setUint32(pos, data, true);
      pos += 4;
    };

    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(sampleRate);
    setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (hardcoded in this demo)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    for (let i = 0; i < buffer.numberOfChannels; i++)
      channels.push(buffer.getChannelData(i));

    while (pos < length) {
      for (let i = 0; i < numOfChan; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][offset]));
        view.setInt16(
          pos,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true
        );
        pos += 2;
      }
      offset++;
    }

    return new Blob([bufferData], {type: 'audio/wav'});
  }
}
