import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {EHubFileType} from "desiren-core-lib/lib/enums/hub/file-type.hub.enum";
import {TranslationsService} from "../../../../service/translations/translations.service";

@Component({
  selector: 'app-file-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-filter.component.html',
  styleUrl: './file-filter.component.scss'
})
export class FileFilterComponent {
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  @Input() count: number = 0;
  @Input() countPhoto: number = 0;
  @Input() countVideo: number = 0;
  @Input() filter: EHubFileType | null = null;
  @Output() setFilter = new EventEmitter<EHubFileType | null>();
  setFilterString(type: 'VIDEO' |
  'PHOTO' |
  'AUDIO' |
  'VOICE' | null) {
    if (type == null) {
      return this.setFilter.emit(null);
    }
    switch (type) {
      case 'VIDEO':
        this.setFilter.emit(EHubFileType.VIDEO);
        break;
      case 'PHOTO':
        this.setFilter.emit(EHubFileType.PHOTO);
        break;
      case 'AUDIO':
        this.setFilter.emit(EHubFileType.AUDIO);
        break;
      case 'VOICE':
        this.setFilter.emit(EHubFileType.VOICE);
        break;
      default:
        this.setFilter.emit(null);
        break;
    }
  }
}
