import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IconsComponent } from '../icons/icons.component';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-dropdown',
	standalone: true,
	imports: [CommonModule, IconsComponent],
	templateUrl: './dropdown.component.html',
	styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
	@Input() icon?: string = 'dots_menu';
	@Input() rotate: number = 90;
	@Input() size?: number = 24;
	@Input() fillColor: string = '#131315';
	@Input() className?: string = '';
	@Input() isOpen: boolean = false;
	@Input() left: boolean = false;
	@Input() externalToggle: (() => void) | undefined;

	constructor(private _crd: ChangeDetectorRef) {}

	toggleDropdown(): void {
		this.isOpen = !this.isOpen;
		this._crd.detectChanges();
	}
}
