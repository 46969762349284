<ng-container>
  <section>
    <div class="body">
      <div class="looks-container">
        <app-look-item [isNew]="true" [item]="file"></app-look-item>
      </div>
      <div class="looks-description">
        <app-creators-post #post [hideControls]="true" [forLooks]="true" [looks]="file"
          [isUpdate]="isUpdate"></app-creators-post>
      </div>
    </div>
    <footer class="footer">
      <ng-container>
        <div class="post-name">
          {{translationsService.translate(translationsService.keys.LOOK_SETTINGS)}}
        </div>
        <div class="actions">
          <ng-container *ngIf="!isUpdate">
            <button class="btn btn-regular btn-color-grey" (click)="cancel()">
              <app-icons name="close_1" fillColor="#6A49FA" width="30" height="30"></app-icons>
              {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL)}}
            </button>

            <button class="btn btn-regular btn-color-grey" (click)="changeLooks()">
              <app-icons name="video_1" fillColor="#6A49FA" width="30" height="30"></app-icons>
              {{translationsService.translate(translationsService.keys.CONTENT_BUTTON_SELECT_ANOTHER_VIDEOS)}}
            </button>

            <button class="btn btn-regular btn-color-purple" (click)="handlePostData()">
              <app-icons name="non_read" fillColor="#ffffff" width="30" height="30"></app-icons>
              {{translationsService.translate(translationsService.keys.GLOBAL_PUBLISH)}}
            </button>
          </ng-container>
          <ng-container *ngIf="isUpdate">

            <button class="btn btn-regular btn-color-grey" (click)="cancel()">
              <app-icons name="close_1" fillColor="#6A49FA" width="30" height="30"></app-icons>
              {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL)}}
            </button>

            <button class="btn btn-regular btn-color-purple" (click)="handlePostData()">
              <app-icons name="non_read" fillColor="#ffffff" width="30" height="30"></app-icons>
              {{translationsService.translate(translationsService.keys.GLOBAL_UPDATE)}}
            </button>
          </ng-container>


        </div>
      </ng-container>
    </footer>
  </section>
</ng-container>