import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IconsComponent} from "../../../../uikit/icons/icons.component";
import {FolderCreatorResponse} from "../../../../model/content/folder";
import {ContextMenuModule} from "@perfectmemory/ngx-contextmenu";
import {HubService} from "../../../../api/hub.service";
import {EHubFileType} from "desiren-core-lib/lib/enums/hub/file-type.hub.enum";
import {IHubPhotoUploadResponse} from "desiren-core-lib/lib/types/hub/upload/photo.upload.response.hub.interface";
import {IHubVideoUploadResponse} from "desiren-core-lib/lib/types/hub/upload/video.upload.response.hub.interface";
import {THubUploadResponse} from "desiren-core-lib/lib/types/hub/upload.hub.interface";
import {IHubFolderCreatorResponse} from "desiren-core-lib/lib/types/hub/creator/folder.creator.hub.interface";
import {IHubFileResponse} from "desiren-core-lib/lib/types/hub/creator/file.creator.hub.interface";
import {TranslationsService} from "../../../../service/translations/translations.service";

@Component({
  selector: 'app-folders-list',
  standalone: true,
  imports: [
    CommonModule,
    IconsComponent,
    ContextMenuModule,
  ],
  templateUrl: './folders-list.component.html',
  styleUrl: './folders-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersListComponent {
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  @Input() folders: FolderCreatorResponse[] = [];
  @Input({required: true}) selectedFolder: string | null = null;
  @Output() onClick = new EventEmitter<string>();
  hub = inject(HubService);

  decodeUrl(data: IHubFolderCreatorResponse | IHubFileResponse): string {
    const file: THubUploadResponse = (data as IHubFileResponse).file;
    switch (file.type) {
      case EHubFileType.PHOTO:
        let photo = file as IHubPhotoUploadResponse;
        if (photo.w?.at(7)) {
          return `url(${encodeURI(photo.base + photo.w?.at(7)!)})`;
        }
        if (photo.w?.at(6)) {
          return `url(${encodeURI(photo.base + photo.w?.at(6)!)})`;
        }
        if (photo.w?.at(5)) {
          return `url(${encodeURI(photo.base + photo.w?.at(5)!)})`;
        }
      case EHubFileType.VIDEO:
        let video = file as IHubVideoUploadResponse;
        if (video.w?.at(7)) {
          return `url(${encodeURI(video.base + video.w?.at(7)!)})`;
        }
        if (video.w?.at(6)) {
          return `url(${encodeURI(video.base + video.w?.at(6)!)})`;
        }
        if (video.w?.at(5)) {
          return `url(${encodeURI(video.base + video.w?.at(5)!)})`;
        }
        if (video.w?.at(4)) {
          return `url(${encodeURI(video.base + video.w?.at(5)!)})`;
        }
        if (video.w?.at(3)) {
          return `url(${encodeURI(video.base + video.w?.at(5)!)})`;
        }
      default:
        return '';
    }
  }

  execute(event: {event: MouseEvent | KeyboardEvent, value?: FolderCreatorResponse | undefined }) {
    if (event.value) {
      this.hub.removeFolder(event.value.id).then(result => {
        this.hub.folderUpdate.next('update');
      });
    }
  }
}
