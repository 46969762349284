import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'textarea[autoGrow]',
  standalone: true
})
export class AutoGrowDirective {
  private _maxHeight: number = 150;

  @Input() set maxHeight(value: number) {
    this._maxHeight = value;
  }

  @HostListener('input', ['$event.target'])
  onInput(textarea: HTMLTextAreaElement): void {
    this.adjustTextareaHeight(textarea);
  }

  private adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = '40px';
    textarea.style.height = Math.min(textarea.scrollHeight, this._maxHeight) + 'px';
  }
}
