<ng-container *ngIf="video">

  <div class="wrapper" [ngClass]="{'new-looks': isNew, 'comments-enable' : (commentsEnable | async)}">
    <div class="looks">
      <div class="video-container">
        <video #videoPlayer muted [loop]="true" preload="none" [poster]="poster" [src]="videoSrc"
          (click)="togglePlayPause()" (timeupdate)="updateProgress($event)"
          (loadedmetadata)="initializeVideo()"></video>
        <div class="progress-bar" (click)="seek($event)">
          <div class="line" [ngStyle]="{'width.%': widthPercentage}"></div>
        </div>
        <div class="large-like" [class.active]="isActive">
          <app-icons [name]="'looks-liked'" [width]="150" [height]="150"></app-icons>
        </div>
      </div>
      <div class="looks-controls" *ngIf="!isNew">
        <app-profile-avatar [routerLink]="['/profile/' + video.user.id]" [preview]="false"
          [userImageData]="avatarImageModelData" [size]="profileAvatarSize.looks"
          [isCircle]="true"></app-profile-avatar>
        <div class="like-control text-color-light">
          <div class="like-wrapper" [ngClass]="{ 'liked': video?.liked}">
            <button class="like-button" (click)="likeEvent()">
              <div class="default-layer">
                <app-icons [name]="'heart_1'" [width]="46" [height]="46" [fillColor]="'#FFFFFF'"></app-icons>
              </div>
              <div class="hover-layer">
                <app-icons [name]="'looks-hovered-like'" [width]="46" [height]="46" [fillColor]="'#433434'"></app-icons>
              </div>
              <div class="liked-layer">
                <app-icons [name]="'looks-liked'" [width]="46" [height]="46"></app-icons>
              </div>
            </button>
          </div>
          <span class="font-static-extra-small text-bold">{{video?.likes}}</span>
        </div>
        <div class="comment-control">
          <button class="comments-button" (click)="commentEvent($event)">
            <div class="default-layer">
              <app-icons name="chat" [width]="40" [height]="40" [fillColor]="'#FFFFFF'"></app-icons>
              <app-icons class="icon-more" [rotate]="90" name="more" [width]="28" [height]="28"
                [fillColor]="'#000000'"></app-icons>
            </div>
            <div class="hover-layer">
              <app-icons name="chat-hover" [width]="40" [height]="40"></app-icons>
              <app-icons class="icon-more" [rotate]="90" name="more" [width]="28" [height]="28"
                [fillColor]="'#ffffff'"></app-icons>
            </div>
            <div class="comments-layer">
              <app-icons name="chat-selected" [width]="40" [height]="40"></app-icons>
              <app-icons class="icon-more" [rotate]="90" name="more" [width]="28" [height]="28"
                [fillColor]="'#ffffff'"></app-icons>
            </div>
          </button>
          <span *ngIf="video.commentsEnabled"
            class="font-static-extra-small text-bold text-color-light">{{video?.comments}}</span>
        </div>
        <button class="round-bg" (click)="sharePost()">
          <app-icons name="share" [width]="20" [height]="20"></app-icons>
        </button>
        <button class="round-bg" *ngIf="isOwnItem">
          <app-dropdown #appDropdown [externalToggle]="toggleAction" [rotate]="0" [size]="20" fillColor="#000000"
            [className]="'top-left-position'">
            <span class="dropdown-item action-text" (click)="deleteLooks()">
              {{translationsService.translate(translationsService.keys.GLOBAL_DELETE)}}
            </span>
            <span class="dropdown-item action-text" (click)="editLooks()">
              {{translationsService.translate(translationsService.keys.GLOBAL_EDIT)}}
            </span>
          </app-dropdown>
        </button>

      </div>


      <div class="looks-additional mobile-tablet-view-only" *ngIf="!isNew">
        <div class="owner-info">
          <app-profile-avatar [routerLink]="['/profile/' + video.user.id]" [preview]="false"
            [userImageData]="avatarImageModelData" [size]="profileAvatarSize.navigation"></app-profile-avatar>
          <div class="col">
            <app-profile-display-name [displayName]="video.user.displayName" [emailConfirmed]="true" [size]="'small'"
              [textColor]="'#FFFFFF'">
            </app-profile-display-name>
            <app-profile-nickname [nickname]="video.user.nickname"></app-profile-nickname>
          </div>
        </div>
        <app-read-more-less *ngIf="video.description" [lines]="2" [class]="['text-color-light']"
          [description]="video.description"></app-read-more-less>
      </div>
    </div>
  </div>
</ng-container>