<ng-container>
  <div class="dropdown-overlay" #overlay>
    <div class="dropdown-overlay-background" [ngClass]="{'opened': isOpen}" (click)="toggleDropdown()"></div>
    <div class="dropdown-container" (click)="toggleDropdown()">
      <div class="dropdown-control flex-center">
        <app-icons [name]="icon" [rotate]="rotate" [width]="size" [height]="size" [fillColor]="fillColor"></app-icons>
      </div>
      <div class="dropdown-menu {{className}}" (click)="toggleDropdown()" [ngClass]="{'opened': isOpen, 'left': left}">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>