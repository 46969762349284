import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LookItemComponent } from '../look-item/look-item.component';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { TranslationsService } from 'src/app/service/translations/translations.service';
import { CreatorsPostComponent } from '../../creators/creators-post/creators-post.component';
import { LooksService } from 'src/app/service/looks.service';

@Component({
	selector: 'app-create-looks',
	standalone: true,
	imports: [NgIf, LookItemComponent, IconsComponent, CreatorsPostComponent],
	templateUrl: './create-looks.component.html',
	styleUrl: './create-looks.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateLooksComponent implements OnInit, OnChanges {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	private readonly looksService: LooksService = inject(LooksService);

	close!: (result?: any) => void;
	@ViewChild('dialog') dialog!: ElementRef<HTMLDialogElement>;
	@ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;
	@ViewChild('post', { static: false }) post: CreatorsPostComponent | null = null;

	@Input() file: any;
	@Input() isUpdate?: boolean = false;

	ngOnInit(): void {}
	ngOnChanges(changes: SimpleChanges): void {}

	cancel() {
		this.close(false);
	}

	changeLooks() {
		this.close(true);
	}

	handlePostData() {
		this.looksService.looksLoadingStatus.next(false);
		let looksBody = {
			access: 'PUBLIC',
			hubId: !this.file || this.isUpdate ? undefined : this.file.id,
			description: this.post.postForm.value.text,
			delayDate: undefined,
			commentsEnabled: !this.post.postForm.value.commentsEnabled,
			tags: [...this.post.tagsList],
		};
		this.close(looksBody);
	}
}
