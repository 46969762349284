<ng-container>
  <button type="button" #recordButton class="btn btn-s-icon" (mousedown)="startRecording()"
    (touchstart)="startRecording()" [ngClass]="{ recording: isRecording }">
    <ng-container *ngTemplateOutlet="recorderTimer"></ng-container>
    <ng-container *ngTemplateOutlet="recorderAnimation"></ng-container>
    <app-icons name="microphone" [width]="20" [height]="20"
      [fillColor]="isRecording ? '#6A49FA' : '#929298'"></app-icons>
  </button>
  <ng-container>
    <div #holdButtonAlert class="alert" (click)="$event.preventDefault(); $event.stopPropagation()">
      <span>{{translationsService.translate(translationsService.keys.VOICE_RECORDER_SPAN_VOICE_ALERT)}}</span>
    </div>
  </ng-container>
</ng-container>

<ng-template #recorderTimer>
  <div class="recorder-timer">
    <div class="recorder-status"></div>
    <span>{{ recordingSubscription | async }}</span>
  </div>
</ng-template>

<ng-template #recorderAnimation>
  <div class="circle one"></div>
  <div class="circle two"></div>
  <div class="circle three"></div>
  <div class="circle four"></div>
  <div class="block"></div>
</ng-template>
