<ng-container>
  <div class="dialog-overlay" #overlay>
    <div class="dialog-overlay-background"></div>
    <dialog type="modal" class="app-dialog" [ngClass]="{'app-dialog-large': isContentCreator}" #dialog>
      <section class="content-selector" [ngClass]="{'folder-selected': isFolderSelected}">
        <aside class="content-selector-list" *ngIf="isContentCreator">
          <header class="screen-aside-list-header">
            <span>{{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_HEADER_CONTENT_HUB)}}</span>
            <div class="screen-aside-list-actions">
              <button class="btn btn-icon btn-icon-square">
                <app-icons name="plus" [width]="30" [height]="30" fillColor="#6A49FA"
                  (click)="showAddFolderDialog()"></app-icons>
              </button>
            </div>
          </header>
          <app-folders-list [folders]="folders" (onClick)="selectFolder($event)"
            [selectedFolder]="selectedFolder?.id ?? null"></app-folders-list>
        </aside>
        <section class="content-selector-body">
          <header class="content-selector-header">
            <ng-container *ngIf="isContentCreator">
              <app-icons [rotate]="180" name="arrow" [width]="40" [height]="40" fillColor="#000000"
                (click)="isFolderSelected = !isFolderSelected"></app-icons>
              <span class="font-main-small">{{selectedFolder?.name ??
                translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_HEADER_ALL_MEDIA)}}</span>
            </ng-container>
            <app-add-media #addMedia [folderId]="selectedFolder?.id ?? null" (onLoaded)="onFileLoaded($event)"
              (onLoadStart)="onLoadStart($event)"></app-add-media>
          </header>
          <app-file-filter *ngIf="isContentCreator" (setFilter)="setFilter($event)" [filter]="filter"
            [count]="folderTotal(selectedFolder)" [countPhoto]="folderTotalPhotos(selectedFolder)"
            [countVideo]="folderTotalVideos(selectedFolder)"></app-file-filter>
          <section class="content-selector-files-list">
            <ul class="content-selector-files" *ngIf="files.length" #scrollBox infiniteScroll
              [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
              (scrolled)="onScrollUp()" (scrolledUp)="onScrollUp()">
              <li *ngFor="let newFile of uploadCount">
                <app-media-placeholder></app-media-placeholder>
              </li>
              <li class="content-selector-file" *ngFor="let file of files">
                <app-media-file [isLooksMode]="isLooksMode" [class]="this.selectedFiles[0]"
                  [isSelectionAvailable]="isSelectionAvailable || selectedFiles.includes(file.id)" [file]="file"
                  (onSelect)="selectFile($event)" [isSelected]="selectedFiles.includes(file.id)"></app-media-file>
              </li>
            </ul>
          </section>
        </section>
      </section>
      <footer class="content-selector-footer">
        <ng-container *ngIf="isLooksMode; else defaultFooter">
          <div class="content-selector-selected post-name">
            Video Selected
          </div>
          <div class="content-selector-actions">
            <button [disabled]="selectedFiles.length > 1 || selectedFiles.length == 0"
              class="btn btn-regular btn-color-purple" (click)="finishSelection()">
              <app-icons name="non_read" fillColor="#ffffff" width="30" height="30"></app-icons>
              <div class="spacer-w4"></div>
              <!-- TRanslate -->
              Create look
            </button>
            <div class="spacer-w16"></div>
            <button class="btn btn-regular btn-color-grey" (click)="close()">
              <app-icons name="close_1" fillColor="#6A49FA" width="30" height="30"></app-icons>
              <div class="spacer-w4"></div>
              {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL)}}
            </button>
          </div>
        </ng-container>
        <ng-template #defaultFooter>
          <div class="content-selector-selected post-name">
            {{selectedFiles.length}}
            {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_DIV_SELECTED)}}
          </div>
          <div class="content-selector-actions">
            <button *ngIf="selectedFiles.length" class="btn btn-regular btn-color-purple" (click)="finishSelection()">
              <app-icons name="non_read" fillColor="#ffffff" width="30" height="30"></app-icons>
              <div class="spacer-w4"></div>
              {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_SELECT)}}
            </button>
            <div class="spacer-w16"></div>
            <button class="btn btn-regular btn-color-grey" (click)="close()">
              <app-icons name="close_1" fillColor="#6A49FA" width="30" height="30"></app-icons>
              <div class="spacer-w4"></div>
              {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_CANCEL)}}
            </button>
          </div>
        </ng-template>

      </footer>
    </dialog>
  </div>
  <app-dialog #add_folder>
    <section class="add_folder-dialog">
      <header class="add_folder-dialog__header dialog-title">
        {{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_HEADER_CREATE_NEW_LIST)}}
      </header>
      <section class="add_folder-dialog__form" [formGroup]="form">
        <label class="post-name" for="folderName">List name</label>
        <input type="text" name="folderName" id="folderName" [formControlName]="'folderName'">
        <div class="form-bottom">
          <span class="form-bottom-counter"
            [ngClass]="{'error': form.dirty && form.controls.folderName.value?.length > 64}">{{form.controls.folderName.value?.length
            ?? 0}}/64</span>
        </div>
      </section>
      <footer class="add_folder-dialog__footer">
        <button class="btn btn-primary"
          (click)="addFolder()">{{translationsService.translate(translationsService.keys.CONTENT_CONTENT_SELECTOR_BUTTON_CREATE)}}</button>
      </footer>
    </section>
  </app-dialog>
</ng-container>