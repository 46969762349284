import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { TranslationsService } from 'src/app/service/translations/translations.service';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
@Component({
	selector: 'app-smiles',
	standalone: true,
	imports: [IconsComponent, PickerComponent],
	templateUrl: './smiles.component.html',
	styleUrl: './smiles.component.scss',
})
export class SmilesComponent {
	public readonly translationsService: TranslationsService = inject(TranslationsService);
	public isEmojiPickerVisible: boolean = false;
	public darkMode: boolean = false;
	public enableSearch: boolean = false;

	public i18n = {
		search: this.translationsService.translate(this.translationsService.keys.SMILES_SEARCH),
		emojilist: this.translationsService.translate(this.translationsService.keys.SMILES_EMOJI_LIST),
		notfound: this.translationsService.translate(this.translationsService.keys.SMILES_NOT_FOUND),
		clear: this.translationsService.translate(this.translationsService.keys.SMILES_CLEAR),
		categories: {
			search: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_SEARCH),
			recent: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_RECENT),
			people: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_PEOPLE),
			nature: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_NATURE),
			foods: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_FOODS),
			activity: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_ACTIVITY),
			places: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_PLACES),
			objects: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_OBJECTS),
			symbols: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_SYMBOLS),
			flags: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_FLAGS),
			custom: this.translationsService.translate(this.translationsService.keys.SMILES_CATEGORY_CUSTOM),
		},
		skintones: {
			1: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_1),
			2: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_2),
			3: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_3),
			4: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_4),
			5: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_5),
			6: this.translationsService.translate(this.translationsService.keys.SMILES_SKIN_TONE_6),
		},
	};
	@Input() isBottom: boolean = false;
	@Output() emojiSelected: EventEmitter<string> = new EventEmitter<string>();

	onEmojiSelect(event: any) {
		this.emojiSelected.emit(event.emoji.native);
		this.toggleEmojiPicker();
	}

	toggleEmojiPicker() {
		this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
	}
}
