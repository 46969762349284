import {OwlDateTimeIntl} from "@danielmoncada/angular-datetime-picker";
import {inject, Injectable} from "@angular/core";
import {TranslationsService} from "../../service/translations/translations.service";

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  public readonly translationsService: TranslationsService = inject(TranslationsService);

  override upSecondLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_ADD_A_SECOND);
  override downSecondLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_MINUS_A_SECOND);
  override upMinuteLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_ADD_A_MINUTE);
  override downMinuteLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_MINUS_A_MINUTE);
  override upHourLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_ADD_A_HOUR);
  override downHourLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_MINUS_A_HOUR);
  override prevMonthLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_PREVIOUS_MONTH);
  override nextMonthLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_NEXT_MONTH);
  override prevYearLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_PREVIOUS_YEAR);
  override nextYearLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_NEXT_YEAR);
  override prevMultiYearLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_PREVIOUS_21_YEARS);
  override nextMultiYearLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_NEXT_21_YEARS);
  override switchToMonthViewLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_CHANGE_TO_MONTH_VIEW);
  override switchToMultiYearViewLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_CHOOSE_MONTH_AND_YEAR);
  override cancelBtnLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_CANCEL);
  override setBtnLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_SET);
  override rangeFromLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_FROM);
  override rangeToLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_TO);
  override hour12AMLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_AM);
  override hour12PMLabel= this.translationsService.translate(this.translationsService.keys.OWL_TEXT_PM);
}
