import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommentInterface, IComment } from '../model/comment';
import { DialogService } from './dialog.service';
import { LooksLoadingStatusComponent } from '../components/looks-loading-status/looks-loading-status.component';
import { CreateLooksComponent } from '../components/looks/create-looks/create-looks.component';

@Injectable({
	providedIn: 'root',
})
export class LooksService {
	private readonly dialogService: DialogService = inject(DialogService);

	private gateUrl: string;
	public totalCreatorsLooks: number;
	public totalLooks: number;

	public looksSettings = new BehaviorSubject<any>({ limit: 10, page: 1 });
	public looksSettings$ = this.looksSettings.asObservable();

	public looksLoadingStatus = new BehaviorSubject<boolean>(false);
	public looksLoadingStatus$ = this.looksLoadingStatus.asObservable();

	public looksCommentsEnable = new BehaviorSubject<boolean>(false);
	public looksCommentsEnable$ = this.looksCommentsEnable.asObservable();

	public currentLook = new BehaviorSubject<any>(null);
	public currentLook$ = this.currentLook.asObservable();

	public currentLookComments = new BehaviorSubject<any>(null);
	public currentLookComments$ = this.currentLookComments.asObservable();

	public isProgressBarActive = new Subject<boolean>();
	public isProgressBarActive$ = this.isProgressBarActive.asObservable();

	public progressBarStatus = new BehaviorSubject<Array<any>>([]);
	public progressBarStatus$ = this.progressBarStatus.asObservable();

	public progressPhotoItems = new BehaviorSubject<Array<any>>([]);
	public progressPhotoItems$ = this.progressPhotoItems.asObservable();

	public videoVolume = new BehaviorSubject<number>(0.8);
	public videoVolume$ = this.videoVolume.asObservable();

	constructor(private http: HttpClient, private router: Router) {
		this.gateUrl = environment.looksUrl;
	}

	public getLooksById(looksId: string): Observable<any> {
		return this.http.get<any>(`${this.gateUrl}/looks/${looksId}`, { withCredentials: true }).pipe(
			take(1),
			tap((i) => (this.totalLooks = i.total))
		);
	}

	public getPublicLooks(body: { limit: number; page: number }): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/looks/list`, body, { withCredentials: true });
	}

	public getUsersLooks(body: { limit: number; page: number }, creatorId): Observable<any> {
		const params = new HttpParams().set('limit', body.limit.toString()).set('page', body.page.toString());
		return this.http.post<any>(`${this.gateUrl}/looks/list/${creatorId}`, body, { withCredentials: true });
	}

	public getCreatorsLooks(body: { limit: number; page: number }): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/creator/list`, body, { withCredentials: true }).pipe(
			take(1),
			tap((i) => (this.totalCreatorsLooks = i.total))
		);
	}

	public createLooks(body: any): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/creator/upload`, body, { withCredentials: true });
	}

	public patchLooks(looksId: string, updatedData: any): Observable<any> {
		updatedData.delayDate = undefined;
		return this.http.patch<any>(`${this.gateUrl}/creator/update/${looksId}`, updatedData, { withCredentials: true });
	}

	public deleteLooks(looksId: string): Observable<any> {
		return this.http.delete<any>(`${this.gateUrl}/creator/delete/${looksId}`, { withCredentials: true }).pipe(take(1));
	}

	public getLooksPopularTags(limit: number, page: number): Observable<any> {
		const params = new HttpParams().set('limit', limit.toString()).set('page', page.toString());
		return this.http.get<any>(`${this.gateUrl}/creator/tags/popular`, {
			params,
			withCredentials: true,
		});
	}

	public looksRecommendationTags(name: string): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/creator/tags/${name}`, { withCredentials: true });
	}

	public resetSettings(): void {
		this.looksSettings.next({ limit: 10, page: 1 });
	}

	// comments
	public getComments(body: { limit: number; page: number }, looksId: string): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/comment/list/${looksId}`, body, { withCredentials: true });
	}

	public createComment(comment: IComment): Observable<CommentInterface> {
		// console.log('createComment', this.currentLook.getValue().id);

		return this.http.post<CommentInterface>(`${this.gateUrl}/comment/add/${this.currentLook.getValue().id}`, comment, { withCredentials: true });
	}

	public deleteLooksComment(looksId: string): Observable<any> {
		return this.http.delete<any>(`${this.gateUrl}/comment/delete/${looksId}`, { withCredentials: true }).pipe(take(1));
	}

	public commentLikeEvent(looksId: string): Observable<any> {
		return this.http.put<any>(`${this.gateUrl}/comment/like/${looksId}`, {}, { withCredentials: true }).pipe(take(1));
	}

	// view metric
	public looksViewMetricEvent(looksId): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/looks/view/${looksId}`, {}, { withCredentials: true });
	}

	//likes
	public looksLikeEvent(looksId): Observable<any> {
		return this.http.post<any>(`${this.gateUrl}/looks/like/${looksId}`, {}, { withCredentials: true });
	}

	public setActiveLook(look: any): void {
		this.currentLook.next(look);
		this.router.navigate(['/looks/' + look.id]);
	}

	public changeUpLimitValue() {
		this.looksSettings.next({ limit: 10, page: this.looksSettings.getValue().page + 1 });
	}
	public resetLimitValue() {
		this.looksSettings.next({ limit: 10, page: 1 });
	}

	//

	public openLoadingProgressComponent(): Observable<LooksLoadingStatusComponent> {
		return this.dialogService.openAtBody(LooksLoadingStatusComponent);
	}

	public editLooks(looks: any): Observable<CreateLooksComponent> {
		return this.dialogService.open(CreateLooksComponent, { file: looks, isUpdate: true });
	}

	public switchVideoVolume(): void {
		this.videoVolume.next(this.videoVolume.getValue() > 0 ? 0 : 1);
	}
}
