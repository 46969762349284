<ng-container>
  <div class="status-wrapper" [ngClass]="{'compact': !looksLoadingStatus$ | async}">
    <div class="loading-item" *ngFor="let item of combinedItems; let i = index; trackBy: trackById">
      <div class="content">
        <div class="controls">
          <button class="close-btn" *ngIf="i === 0" (click)="cancel()">
            <app-icons name="close_1" [width]="24" [height]="24" fillColor="#FFFFFF"></app-icons>
          </button>
        </div>
        <img class="" [src]="item.file?.file?.base + item.file?.file?.thumbnail" alt="looks photo">
        <span class="status text-color-light" *ngIf="item.status; else loadingStatusRef">{{item.status}}</span>
        <ng-template #loadingStatusRef>
          <span class="status text-color-light">Loading...</span>
        </ng-template>

        <ng-container *ngIf="item?.percent; else loadingTemplateRef">
          <span class="percent text-color-light">{{item.percent}}%</span>

          <div class="progress" [ngStyle]="{'width.%': 100 - item.percent}"></div>
        </ng-container>

        <ng-template #loadingTemplateRef>
          <span *ngIf="item?.status === 'PENDING' || item?.status === 'CONVERTING' || item?.status === 'DOWNLOADING' || item?.status === 'VALIDATION'" class="percent text-color-light">0%</span>

          <div *ngIf="item?.status === 'PENDING' || item?.status === 'CONVERTING' || item?.status === 'DOWNLOADING' || item?.status === 'VALIDATION' ; else fullTemplateRef" class="progress" [ngStyle]="{'width.%': 100}"></div>
          <ng-template #fullTemplateRef>
            <div class="progress" [ngStyle]="{'width.%': 0}"></div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
