import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DeleteRequest, GetRequest, PatchRequest, PostRequest, PutRequest } from './methods';
import { environment } from '../../environments/environment';
import { OnboardingSendData } from '../model/onboarding';
import { Observable, take } from 'rxjs';
import { TAddRecipientBody, TAddSwiftRecipientBody, TCryptoRecipientBody } from '../model/statements';
import { SubscriptionPurchaseModelData } from 'desiren-core-lib/lib/types/purchases';
import { ISuggestions } from 'desiren-core-lib/lib/types/suggestions';
import { UserAccountStatementModelData, UserPaymentStatementModelData } from 'desiren-core-lib/lib/types/account-statement';
import { UserModelData } from 'desiren-core-lib/lib/types/users';
import { CountryModelData } from 'desiren-core-lib/lib/types/countries';
import { MercuryAchRecipientResponse } from 'desiren-core-lib/lib/types/payouts/mercury';
import { ELanguages } from 'desiren-core-lib/lib/enums/languages.enum';

export interface JwtLoggedInModelData {
	jwtToken: string;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
	apiUrl: string;
	private refreshPromise: Promise<JwtLoggedInModelData> | null = null;

	constructor(private http: HttpClient) {
		this.apiUrl = environment.apiUrl;
	}

	//AUTH LOCAL https://dev.prestinly.com/api/docs#/Auth%20(Local)
	async login(data: { email: string; password: string; referrerCode?: string | undefined; fcmToken: string | null; browserId: string }): Promise<JwtLoggedInModelData> {
		let body: { [k: string]: any } = {
			email: data.email,
			password: data.password,
			fcmToken: data.fcmToken,
			browserId: data.browserId,
		};
		if (data.referrerCode != undefined && data.referrerCode?.length > 0) {
			body['referrerCode'] = data.referrerCode!;
		}
		const request = new PostRequest(this.http, `${this.apiUrl}/auth/local/login`, body, {}, false);
		return request.getResponse<JwtLoggedInModelData>();
	}

	async register(data: { email: string; password: string; referrerCode?: string | undefined; fcmToken: string | null; browserId: string; language: ELanguages }): Promise<any> {
		let body: { [k: string]: any } = {
			email: data.email,
			password: data.password,
			fcmToken: data.fcmToken,
			browserId: data.browserId,
			language: data.language,
		};
		if (data.referrerCode != undefined && data.referrerCode?.length > 0) {
			body['referrerCode'] = data.referrerCode!;
		}
		const request = new PostRequest(this.http, `${this.apiUrl}/auth/local/register`, body, {}, false);
		return request.getResponse();
	}

	public getRefreshPasswordCode(body: { email: string }): Observable<any> {
		return this.http.post(`${this.apiUrl}/auth/local/manage/forgot-password`, body).pipe(take(1));
	}

	public isCorrectVerifyCode(body: { code: string; email: string }): Observable<any> {
		return this.http.post(`${this.apiUrl}/auth/local/manage/verify-forgot-password-code`, body).pipe(take(1));
	}

	public createNewPassword(body: { email: string; code: string; newPassword: string }): Observable<any> {
		return this.http.put<any>(`${this.apiUrl}/auth/local/manage/forgot-password`, body).pipe(take(1));
	}

	async refreshToken(data: { jwtToken: string; userId: string; fcmToken: string | null; browserId: string }): Promise<JwtLoggedInModelData> {
		if (this.refreshPromise) {
			console.warn('refreshToken is already in progress, returning existing promise');
			return this.refreshPromise;
		}

		this.refreshPromise = new Promise(async (resolve, reject) => {
			try {
				const request = new PostRequest(
					this.http,
					`${this.apiUrl}/auth/local/refresh`,
					{
						jwtToken: data.jwtToken,
						userId: data.userId,
						fcmToken: data.fcmToken,
						browserId: data.browserId,
					},
					{},
					false
				);
				const response = await request.getResponse<JwtLoggedInModelData>();
				resolve(response);
			} catch (error) {
				reject(error);
			} finally {
				this.refreshPromise = null;
			}
		});

		return this.refreshPromise;
	}

	//AUTH Google https://dev.prestinly.com/api/docs#/Auth%20(Google)
	async loginGoogle(data: { token: string; referrerCode?: string | undefined; fcmToken: string | null; browserId: string; language: ELanguages }): Promise<{ [k: string]: any }> {
		const body: { [k: string]: any } = {
			fcmToken: data.fcmToken,
			browserId: data.browserId,
			language: data.language,
		};
		if (data.referrerCode != undefined && data.referrerCode?.length > 0) {
			body['referrerCode'] = data.referrerCode!;
		}
		const request = new PostRequest(this.http, `${this.apiUrl}/auth/google/login`, body, { 'x-google-token': data.token }, false);
		return request.getResponse();
	}

	//USERS https://dev.prestinly.com/api/docs#/Users
	async getCurrentUser(): Promise<any> {
		return new GetRequest(this.http, `${this.apiUrl}/users/me`).getResponse();
	}

	async getUserById(id: string): Promise<UserModelData> {
		return new GetRequest(this.http, `${this.apiUrl}/users/${id}`).getResponse();
	}

	//Purchases https://dev.desiren.me/api/docs#/Purchases%20(SUBSCRIPTIONS)
	async buyFreeSubscription(data: { ownerId: string; subscriptionId: string }): Promise<void> {
		const request = new PostRequest(this.http, `${this.apiUrl}/purchases/subscriptions/free`, data);
		return await request.getResponse();
	}

	async buySubscription(data: { targetId: string; cardId: string }): Promise<void> {
		const request = new PostRequest(this.http, `${this.apiUrl}/purchases/subscriptions/buy`, data);
		return await request.getResponse();
	}

	async cancelSubscription(toUserId: string): Promise<void> {
		const request = new PutRequest(this.http, `${this.apiUrl}/purchases/subscriptions/cancel`, { toUserId: toUserId });
		return await request.getResponse();
	}

	async getSubscriptions(toUserId: string): Promise<SubscriptionPurchaseModelData | null> {
		const request = new GetRequest(this.http, `${this.apiUrl}/purchases/subscriptions/${toUserId}`);
		return await request.getResponse<SubscriptionPurchaseModelData | null>();
	}

	async getMySubscribes(userId: string): Promise<SubscriptionPurchaseModelData[]> {
		const request = new GetRequest(this.http, `${this.apiUrl}/purchases/subscriptions/user/${userId}/subscribes`);
		return await request.getResponse<SubscriptionPurchaseModelData[]>();
	}

	async getMySubscribers(userId: string): Promise<SubscriptionPurchaseModelData[]> {
		const request = new GetRequest(this.http, `${this.apiUrl}/purchases/subscriptions/user/${userId}/subscribers`);
		return await request.getResponse<SubscriptionPurchaseModelData[]>();
	}

	//KYC https://dev.prestinly.com/api/docs#/KYC
	async getKycUrl(): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/kyc/start`, {}).getResponseText();
	}

	//Onboarding creator https://dev.prestinly.com/api/docs#/Onboarding%20(CREATOR)
	async sendOnboardingForm(data: OnboardingSendData): Promise<any> {
		const formData = new FormData();
		formData.append('residenceCountryId', data.countryId);
		formData.append('bio', data.bio);
		formData.append('avatar', data.avatar, 'avatar.webp');
		formData.append('background', data.background, 'background.webp');
		return new PostRequest(this.http, `${this.apiUrl}/onboarding/creator`, formData).getFormDataResponse();
	}

	async completeOnboarding(): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/onboarding/creator/complete`, {}).getResponse();
	}

	//META https://dev.prestinly.com/api/docs#/Meta
	async getCountries(): Promise<CountryModelData[]> {
		return new GetRequest(this.http, `${this.apiUrl}/meta/countries`, {}, true).getResponse();
	}

	async changeUserNickname(nickname: string): Promise<any> {
		return new PutRequest(this.http, `${this.apiUrl}/users/profile/nickname`, { nickname: nickname }).getResponse();
	}

	async changeUserLanguage(language: ELanguages): Promise<any> {
		return new PutRequest(this.http, `${this.apiUrl}/users/profile/language`, { language: language }).getResponse();
	}

	async changeUserPassword(body: { oldPassword?: string; password: string; confirmPassword: string }): Promise<any> {
		return new PutRequest(this.http, `${this.apiUrl}/users/profile/password`, body).getResponse();
	}

	async changeCreatorName(id: string, body: { displayName: 'string' }): Promise<any> {
		return new PatchRequest(this.http, `${this.apiUrl}/users/creators/${id}`, body).getResponse();
	}

	async changeUserBio(bio: string): Promise<any> {
		return new PutRequest(this.http, `${this.apiUrl}/onboarding/creator/bio`, { bio: bio }).getResponse();
	}

	async changeAvatar(body: any): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/user-images/upload`, body).getResponseText();
	}

	async getSuggestions(): Promise<ISuggestions[]> {
		return new GetRequest(this.http, `${this.apiUrl}/suggestions/list`).getResponse<ISuggestions[]>();
	}

	changeUserPhoto(body): Observable<any> {
		return this.http.post(`${this.apiUrl}/user-images/upload`, body, { withCredentials: true }).pipe(take(1));
	}

	///https://dev.desiren.me/api/docs#/Statements
	///https://dev.desiren.me/api/docs#/Payouts
	async getStatementsBalance(): Promise<UserAccountStatementModelData> {
		const request = new GetRequest(this.http, `${this.apiUrl}/statements/balance`);
		return await request.getResponse<UserAccountStatementModelData>();
	}

	async getStatementsHistory(): Promise<UserPaymentStatementModelData[]> {
		const request = new GetRequest(this.http, `${this.apiUrl}/statements/history`);
		return await request.getResponse<UserPaymentStatementModelData[]>();
	}

	async payoutRequest(body: { recipientId: string; amount: number }): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/ach/request`, body).getResponse();
	}

	async getRecipients(): Promise<MercuryAchRecipientResponse[]> {
		return new GetRequest(this.http, `${this.apiUrl}/payouts/ach/recipients`).getResponse();
	}

	async addRecipients(body: TAddRecipientBody): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/ach/recipients`, body).getResponse();
	}

	async removeRecipients(id: string): Promise<any> {
		return new DeleteRequest(this.http, `${this.apiUrl}/payouts/ach/recipients/${id}`).getResponse();
	}

	// crypto

	async payoutCryptoRequest(body: { recipientId: string; amount: number }): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/crypto/request`, body).getResponse();
	}

	async addCryptoRecipients(body: TCryptoRecipientBody): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/crypto/recipients`, body).getResponse();
	}

	async getCryptoRecipients(): Promise<any[]> {
		return new GetRequest(this.http, `${this.apiUrl}/payouts/crypto/recipients`).getResponse();
	}

	async removeCryptoRecipients(id: string): Promise<any> {
		return new DeleteRequest(this.http, `${this.apiUrl}/payouts/crypto/recipients/${id}`).getResponse();
	}

	// swift
	async payoutSwiftRequest(body: { recipientId: string; amount: number }): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/swift/request`, body).getResponse();
	}

	async addSwiftRecipients(body: TAddSwiftRecipientBody): Promise<any> {
		return new PostRequest(this.http, `${this.apiUrl}/payouts/swift/recipients`, body).getResponse();
	}

	async getSwiftRecipients(): Promise<any[]> {
		return new GetRequest(this.http, `${this.apiUrl}/payouts/swift/recipients`).getResponse();
	}

	async removeSwiftRecipients(id: string): Promise<any> {
		return new DeleteRequest(this.http, `${this.apiUrl}/payouts/swift/recipients/${id}`).getResponse();
	}

	getIpAddress(): Observable<any> {
		return this.http.get('https://api.ipify.org?format=json');
	}

	getGeo(id?: string): Observable<any[]> {
		const headers = new HttpHeaders().set('X-Fake-Ip', id);
		return this.http.get<any[]>(`${this.apiUrl}/payouts/geo/payout-methods`, { headers, withCredentials: true });
	}

	unsubscribe(userId: string, token: string): Observable<any> {
		return this.http.post(
			`${this.apiUrl}/users/mails/unsubscribe`,
			{},
			{
				params: {
					userId,
					token,
				},
			}
		);
	}
}
