import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  Output
} from "@angular/core";
import {NotificationsRepositoryService} from "../../../../repository/notifications-repository.service";
import moment from "moment/esm";
import {StorageDataKey, StorageService} from "../../../../service/storage.service";
import {Router} from "@angular/router";
import {TNotification} from "desiren-core-lib/lib/types/notifications/notification.type";
import {
  INotificationsNewPostNotification
} from "desiren-core-lib/lib/types/notifications/content/new-post.notification.interface";
import {
  INotificationsNewPostCommentNotification
} from "desiren-core-lib/lib/types/notifications/content/new-post-comment.notification.interface";
import {
  INotificationsDonatedNotification
} from "desiren-core-lib/lib/types/notifications/purchases/donated.notification.interface";
import {
  INotificationsMessagePurchasedNotification
} from "desiren-core-lib/lib/types/notifications/purchases/message-purchased.notification.interface";
import {
  INotificationsPostPurchasedNotification
} from "desiren-core-lib/lib/types/notifications/purchases/post-purchased.notification.interface";
import {
  INotificationsChangedSubscriptionFromFreeToPaidNotification
} from "desiren-core-lib/lib/types/notifications/subscription/changed-subscription-from-free-to-paid.notification.interface";
import {
  INotificationsChangedSubscriptionFromPaidToFreeNotification
} from "desiren-core-lib/lib/types/notifications/subscription/changed-subscription-from-paid-to-free.notification.interface";
import {
  INotificationsNewSubscriberNotification
} from "desiren-core-lib/lib/types/notifications/subscription/new-subscriber.notification.interface";
import {
  INotificationsInPostCommentTagNotification
} from "desiren-core-lib/lib/types/notifications/tags/in-post-comment-tag.notification.interface";
import {
  INotificationsInPostTagNotification
} from "desiren-core-lib/lib/types/notifications/tags/in-post-tag.notification.interface";
import {INotificationUser} from "desiren-core-lib/lib/types/notifications/user.interface";
import {EUserImageType} from "desiren-core-lib/lib/enums/user-image-type.enum";
import {INotificationsUserImages} from "desiren-core-lib/lib/types/notifications/user-images.interface";
import {ChatService} from "../../../../service/chat.service";
import {DatePipe} from "@angular/common";
import {
  INewAudioMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-audio-message.notification.interface";
import {
  INewDocumentMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-document-message.notification.interface";
import {
  INewMultipartMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-multipart-message.notification.interface";
import {
  INewPhotoMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-photo-message.notification.interface";
import {
  INewTextMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-text-message.notification.interface";
import {
  INewVideoMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-video-message.notification.interface";
import {
  INewVoiceMessageNotification
} from "desiren-core-lib/lib/types/notifications/message/new-voice-message.notification.interface";
import {IMessagesUserResponse} from "desiren-core-lib/lib/types/messages/user.messages.interface";
import { MessagesApiService } from "src/app/api/ws.service";
import { EMessagesEventsChat } from "desiren-core-lib/lib/enums/messages/events.chat.messages.enum";
import {TranslationsService} from "../../../../service/translations/translations.service";
import {UUID} from "crypto";

@Component({
  selector: 'app-notification',
  template: ``
})
export class AppNotification implements AfterContentChecked, OnDestroy {
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  observer!: IntersectionObserver;
  declare notification: ElementRef;
  @Input() outlet: 'toast' | 'list' = 'list';
  @Output() onDelete = new EventEmitter<string>();
  @Output() onRead = new EventEmitter<string>();
  private repository = inject(NotificationsRepositoryService);
  private ngZone = inject(NgZone);
  declare data: TNotification;

  get updateAt() {
    const mDate = moment(this.data.created).endOf('day').valueOf();
    const today = moment().endOf('day').valueOf();
    const yesterday = moment().add(-1, 'day').endOf('day').valueOf();
    if (mDate == today) {
      return this.translationsService.translate(this.translationsService.keys.NOTIFICATION_NOTIFICATION_SPAN_TODAY);
    }
    if (mDate == yesterday) {
      return this.translationsService.translate(this.translationsService.keys.NOTIFICATION_NOTIFICATION_SPAN_YESTERDAY);
    }
    return new DatePipe(this.translationsService.getActiveLang()).transform(this.data.created,'dd MMM HH:mm' )
  }

  readMe() {
    this.onRead.emit(this.data.id);
  }
  deleteMe() {
    this.repository.delete(this.data.id).then(_ => {
      this.onDelete.emit(this.data.id);
    });
  }

  ngAfterContentChecked(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
         if (!this.data.isReadByUser && e.isIntersecting) {
           this.readMe();
         }
        });
      });
      if (this.notification) {
        this.observer.observe(this.notification.nativeElement);
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
@Component({
  selector: 'app-notification-from',
  template: ``
})
export class AppNotificationFrom extends AppNotification {
  declare data: INotificationsNewPostNotification
    | INotificationsNewPostCommentNotification
    | INotificationsDonatedNotification
    | INotificationsMessagePurchasedNotification
    | INotificationsPostPurchasedNotification
    | INotificationsChangedSubscriptionFromFreeToPaidNotification
    | INotificationsChangedSubscriptionFromPaidToFreeNotification
    | INotificationsNewSubscriberNotification
    | INotificationsInPostCommentTagNotification
    | INotificationsInPostTagNotification
    | INewAudioMessageNotification
    | INewDocumentMessageNotification
    | INewMultipartMessageNotification
    | INewPhotoMessageNotification
    | INewTextMessageNotification
    | INewVideoMessageNotification
    | INewVoiceMessageNotification

  storage = inject(StorageService);
  zone = inject(NgZone);
  router = inject(Router);
  chat = inject(ChatService);
  socket = inject(MessagesApiService);
  get myId() {
    return this.storage.getLocalJsonData(StorageDataKey.apiUser).id;
  }

  get hasUser(): boolean {
    if ('fromUser' in this.data) {
      return true;
    }
    return 'message' in this.data;
  }

  get from(): INotificationUser | IMessagesUserResponse | null {
    if ('fromUser' in this.data) {
      return this.data.fromUser;
    }
    if ('message' in this.data) {
      return this.data.message.user;
    }
    return null;
  }

  get fromAvatar(): INotificationsUserImages | null {
    const images = this.from?.images.filter(data => {
      return data.imageType == EUserImageType.AVATAR
    });
    if (images?.length) {
      // TODO: FIX
      return images?.at(0) as any;
    }
    return null;
  }

  get displayName(): string {
    if (!this.hasUser) {
      return 'Deleted user';
    }
    if ((this.from as any)?.deletedAt) {
      return this.translationsService.translate(this.translationsService.keys.GLOBAL_DELETED_USER_NICKNAME);
    }
    return this.from!.displayName;
  }

  navigateToProfile() {
    if (!this.hasUser || !this.from.isCreator) return;
    this.zone.run(() => {
      this.router.navigate([`profile/${this.from.id}`]);
    });
  }

  sendMessage(userId: string) {
    this.socket.emit(EMessagesEventsChat.IS_CHAT_EXISTS, {
      withUserId: userId,
    }).subscribe({
      next: res => {this.router.navigate([`/messages/chat/${res.id}`])},
      error: error => {this.router.navigate([`/messages/new/${userId}`]) },
    });
  }
}
