import {Component} from '@angular/core';

@Component({
  selector: 'app-media-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './media-placeholder.component.html',
  styleUrl: './media-placeholder.component.scss'
})
export class MediaPlaceholderComponent {

}
